import { createThemedUseStylesWithParams } from "theme";
import { Header4Props } from "./Header4Types";
import { getMarginBottom } from "components/common/inputScales";

export default createThemedUseStylesWithParams<Header4Props>((params, theme) => ({
  h4: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: getMarginBottom(params.stackSpacing, theme)
  }
}));
