import React, { useEffect, useState } from "react";
import { resources } from "api/resources";
import { methods } from "api/methods";
import { PageLoadStateProvider } from "components/common";
import { User } from "api/models";
import { useApi } from "api/useApi";
import UserDetailForm from "./UserDetailForm";
import UserDetailHeader from "./UserDetailHeader";
import { useParams } from "react-router";

export type UserDetailPageProps = {
  creatingNew: boolean;
};

const UserDetailPage: React.FC<UserDetailPageProps> = props => {
  const params = useParams();
  const execute = useApi();
  const [userData, setUserData] = useState<User>();

  useEffect(() => {
    if (!props.creatingNew && params.userKey) {
      execute(resources.user(params.userKey), methods.GET)
        .then((response: User) => {
          setUserData(response);
        })
        .catch(() => {});
    } else {
      execute(resources.userInvite, methods.GET)
        .then((response: User) => {
          setUserData(response);
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.userKey]);

  return (
    <PageLoadStateProvider isLoaded={!!userData?.translations}>
      <UserDetailHeader user={userData} />
      <UserDetailForm creatingNew={props.creatingNew} user={userData} />
    </PageLoadStateProvider>
  );
};

export default UserDetailPage;
