import isEqual from "react-fast-compare";
import * as React from "react";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams,
  GridSortItem
} from "@mui/x-data-grid-pro";
import {
  BorderBox,
  Header2,
  Layout,
  LinkButton,
  PageLoadStateProvider,
  SecondaryButton,
  AppTypography
} from "components/common";
import { useQuery } from "api/useQuery";
import { SuppliersList } from "api/models";
import { resources } from "api/resources";
import { routes } from "routes";
import { useTranslations } from "components/useTranslations";
import useSuppliersPageStyles from "./useSuppliersPageStyles";
import { useNavigate } from "react-router-dom";

const SuppliersPage: React.FC = () => {
  const { data: response } = useQuery<SuppliersList>(resources.suppliers);
  const styles = useSuppliersPageStyles();
  const suppliers = response?.suppliers ?? [];
  const t = useTranslations(response?.translations);

  const navigateTo = useNavigate();

  const renderEditButton = (params: GridCellParams) => {
    return (
      <LinkButton
        label={t`__SUPPLIERS_INITIAL_VIEW_VIEW_BUTTON`}
        onClick={() => navigateTo(`${routes.suppliersEdit}${params.id}`)}
      />
    );
  };
  // setup data grid
  const columns: GridColDef[] = [
    {
      field: "supplierName",
      headerName: t`__SUPPLIERS_INITIAL_VIEW_NAME_COLUMN`,
      flex: 2.0,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__SUPPLIERS_INITIAL_VIEW_NAME_COLUMN`}</strong>
        </AppTypography>
      ),
      renderCell: (params: GridCellParams) => <strong>{params.value as string}</strong>
    },
    {
      field: "code",
      headerName: t`__SUPPLIERS_INITIAL_VIEW_CODE_COLUMN`,
      flex: 2,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__SUPPLIERS_INITIAL_VIEW_CODE_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "city",
      headerName: t`__SUPPLIERS_INITIAL_VIEW_CITY_COLUMN`,
      flex: 2,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__SUPPLIERS_INITIAL_VIEW_CITY_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "stateProvince",
      headerName: t`__SUPPLIERS_INITIAL_VIEW_STATE_COLUMN`,
      flex: 1.0,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__SUPPLIERS_INITIAL_VIEW_STATE_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "country",
      headerName: t`__SUPPLIERS_INITIAL_VIEW_COUNTRY_COLUMN`,
      flex: 1.0,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__SUPPLIERS_INITIAL_VIEW_COUNTRY_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "edit",
      headerName: " ",
      renderCell: (params: GridCellParams) => renderEditButton(params),
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      width: 75,
      disableColumnMenu: true,
      display: "flex"
    }
  ];

  const rows: GridRowsProp = suppliers.map<GridRowModel>(supplier => ({
    id: supplier.key,
    supplierName: supplier.supplierName,
    code: supplier.code,
    city: supplier.city,
    stateProvince: supplier.stateProvinceKey,
    country: supplier.countryKey
  }));

  // default sorting model
  const [sortModel, setSortModel] = React.useState([
    {
      field: "supplierName",
      sort: "asc"
    }
  ] as GridSortItem[]);

  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <BorderBox width="full" height="full">
        <Layout layoutStyle="page">
          <div className={styles.headerStyling}>
            <Header2>{t`__SUPPLIERS_INITIAL_VIEW_TITLE`}</Header2>
            <SecondaryButton
              name="createSupplier"
              onClick={() => navigateTo(routes.suppliersCreate)}
            >
              {t`__SUPPLIERS_INITIAL_VIEW_CREATE_BUTTON`}
            </SecondaryButton>
          </div>
          <div style={{ height: "75vh", width: "100%", marginTop: 10 }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGridPro
                  rows={rows}
                  columns={columns}
                  checkboxSelection={false}
                  pagination
                  sortingOrder={["asc", "desc"]}
                  sortModel={sortModel}
                  onSortModelChange={model => {
                    if (!isEqual(sortModel, model)) {
                      setSortModel(model);
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Layout>
      </BorderBox>
    </PageLoadStateProvider>
  );
};

export default SuppliersPage;
