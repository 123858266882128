import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

type FolderPlusIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const FolderPlusIcon: React.FC<FolderPlusIconProps> = props => {
  const { decorative, label, color, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = iconSize ?? 22;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        role="img"
        width={svgSize}
        height={svgSize}
        version="1.1"
        viewBox="0 0 22 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1054.000000, -2764.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(1055.000000, 95.000000)">
                <g>
                  <path d="M20,16 C20,17.1045695 19.1045695,18 18,18 L2,18 C0.8954305,18 0,17.1045695 0,16 L0,2 C0,0.8954305 0.8954305,0 2,0 L7,0 L9,3 L18,3 C19.1045695,3 20,3.8954305 20,5 L20,16 Z" />
                  <line x1="10" y1="8" x2="10" y2="14" id="Path" />
                  <line x1="7" y1="11" x2="13" y2="11" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default FolderPlusIcon;
