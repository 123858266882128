import { useReducer } from "react";
import { hashCodeOfString } from "api/tools";
import { DropdownObjectType, UserRoleKind } from "api/models";

// Reducer Types
export enum UserDetailsFormActionType {
  ON_PAGE_LOAD = "on-page-load",
  CHANGE_FORM_STATE = "change-form-state"
}

type FormDetails = {
  firstName?: string;
  lastName?: string;
  currentEmail?: string;
  updatedEmail?: string;
  confirmEmail?: string;
  userRoles?: UserRoleKind[] | undefined;
};

export type ValidationDetails = {
  firstNameValidationMessage?: string;
  lastNameValidationMessage?: string;
  currentEmailValidationMessage?: string;
  updatedEmailValidationMessage?: string;
  confirmEmailValidationMessage?: string;
  selectedUserRolesValidationMessage?: string;
};

export type UserDetailsFormState = {
  isFormDirty?: boolean;
  formDetails?: FormDetails;
  validationDetails?: ValidationDetails;
  key?: string;
  userRolesOptions?: DropdownObjectType[] | undefined;
  stateHash: string;
};

export type UserDetailsFormAction = {
  type: UserDetailsFormActionType;
  payload?: any;
};

const initState: UserDetailsFormState = {
  isFormDirty: false,
  formDetails: {
    firstName: "",
    lastName: "",
    currentEmail: "",
    updatedEmail: "",
    confirmEmail: "",
    userRoles: []
  },
  validationDetails: {
    firstNameValidationMessage: "",
    lastNameValidationMessage: "",
    currentEmailValidationMessage: "",
    updatedEmailValidationMessage: "",
    confirmEmailValidationMessage: "",
    selectedUserRolesValidationMessage: ""
  },
  key: "",
  userRolesOptions: [],
  stateHash: ""
};

// Reducer Function Logic
function reducer(state: UserDetailsFormState, action: UserDetailsFormAction) {
  switch (action.type) {
    case UserDetailsFormActionType.ON_PAGE_LOAD: {
      const initialLoadState = { ...state, ...action.payload };
      return {
        ...initialLoadState,
        isFormDirty: false,
        stateHash: hashCodeOfString(
          JSON.stringify(initialLoadState.formDetails)
        )
      };
    }
    case UserDetailsFormActionType.CHANGE_FORM_STATE: {
      const updatedDetails = {
        ...state,
        [action.payload.key]: action.payload.value
      };

      const hashCodeAfterChange = hashCodeOfString(
        JSON.stringify(updatedDetails.formDetails)
      );

      // This means that our current page render is no different than initial state, return isFormDirty: false
      if (hashCodeAfterChange === state.stateHash) {
        return {
          ...updatedDetails,
          isFormDirty: false
        };
      }

      return { ...updatedDetails, isFormDirty: true };
    }
    default:
      return state;
  }
}

// Component
export default function useUserDetailsFormReducer(
  init?: UserDetailsFormState
): [UserDetailsFormState, React.Dispatch<UserDetailsFormAction>] {
  const [newState, dispatch] = useReducer(reducer, init ?? initState);
  return [newState, dispatch];
}
