import React, { useContext } from "react";
import usePrimaryButtonStyles from "./usePrimaryButtonStyles";
import { withAction } from "../actions";
import InfoText from "../Text/InfoText/index";
import { PageLoadStateContext } from "../trackPageLoadState";
import { PrimaryButtonProps } from "./PrimaryButtonTypes";

const PrimaryButton: React.FC<PrimaryButtonProps> = props => {
  const styles = usePrimaryButtonStyles(props);
  const { onClick, title, name, reference } = props;
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };

  const buttonContent = (
    <>
      {title && <InfoText textStyle="info-normal-emphasis">{title}</InfoText>}
      {!title && props.children}
    </>
  );

  return (
    <button
      className={styles.button}
      onClick={onClick}
      disabled={!pageLoadState.loaded ? true : props.disabled}
      name={name}
      ref={reference}
      type="button"
    >
      {!pageLoadState.loaded ? undefined : buttonContent}
    </button>
  );
};

export default withAction(PrimaryButton, "onClick");
