import React from "react";
import Text from "..";
import { TextStyle } from "../TextTypes";
import useLabelTextStyles from "./useLabelTextStyles";

export type LabelTextProps = {
  children?: React.ReactNode;
  id?: string;
  textStyle?: TextStyle;
  color?: string;
  className?: string;
  qaAutomationLabel?: string;
};

const LabelText: React.FC<LabelTextProps> = props => {
  const styles = useLabelTextStyles();
  const defaultTextStyle = "label";

  const children = React.Children.map(props.children, child => {
    if (React.isValidElement(child)) {
      return child;
    }
    return (
      <Text
        textStyle={
          props.textStyle ??
          LabelText.defaultProps?.textStyle ??
          defaultTextStyle
        }
        color={props?.color}
      >
        {child}
      </Text>
    );
  });

  const allStyles =
    styles.paragraph + (props.className ? ` ${props.className}` : "");
  return (
    <p
      id={props.id}
      className={allStyles}
      data-automation={props.qaAutomationLabel}
    >
      {children}
    </p>
  );
};

LabelText.defaultProps = {
  textStyle: "label"
};

export default LabelText;
