import React, { PropsWithChildren, useMemo } from "react";

type ValueOrFunc<T> = T | ((...args: any[]) => T);
type Func<T> = (...args: any[]) => T;

type ActionProps<T> = PropsWithChildren<T> & {
  // eslint-disable-next-line react/no-unused-prop-types
  event?: string;
  // eslint-disable-next-line react/no-unused-prop-types
  onAction?: () => void;
};

export function DispatchAction<A>(
  props: ActionProps<{
    dispatch: React.Dispatch<A>;
    action: ValueOrFunc<A>;
  }>
): React.ReactElement {
  const { dispatch, action, onAction, event, children } = props;
  return useMemo(
    () =>
      React.cloneElement(children as any, {
        [event ?? "onAction"]: (...args: any[]) => {
          const act = action as any;

          dispatch(typeof act === "function" ? act(...args) : act);
          onAction?.();
        }
      }),
    [children, action, onAction, event, dispatch]
  );
}

export function FunctionAction<A>(
  props: ActionProps<{
    func: Func<A>;
  }>
): React.ReactElement {
  const { func, onAction, event, children } = props;
  return useMemo(
    () =>
      React.cloneElement(children as any, {
        [event ?? "onAction"]: (...args: any[]) => {
          func(...args);
          onAction?.();
        }
      }),
    [children, func, onAction, event]
  );
}

type FunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends (...args: any[]) => any ? K : never;
}[keyof T];

export function withAction<P extends object>(
  Component: React.ComponentType<P>,
  event: FunctionPropertyNames<Required<P>>
) {
  const WithAction: React.FC<P & { onAction?: () => void }> = props => {
    const { onAction, ...rest } = props;
    const compProps: P = {
      ...(rest as P),
      [event]: (...args: any[]) => {
        onAction?.();
        (props[event] as any)?.(args);
      }
    };
    return <Component {...compProps} />;
  };
  return WithAction;
}
