import { CSSObject } from "@emotion/react";
import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => {
  const sideNavButtonBase: CSSObject = {
    backgroundColor: theme.palette.primary.fsDarkBlue,
    border: "none",
    color: theme.palette.white,
    cursor: "pointer",
    flex: 1,
    "&:hover": {
      backgroundColor: "rgba(255,255,255,0.1)",
    },
    padding: 0,
    transition: `background-color ${theme.transitionTime}`
  };

  return {
    sideNavButton: {
      ...sideNavButtonBase
    },

    sideNavButtonSelected: {
      ...sideNavButtonBase,
      backgroundColor: theme.palette.primary.fsBlue,
      color: theme.palette.primary.fsDarkBlue,
      "&:hover": {
        backgroundColor: theme.palette.primary.fsBlue, // prevent hover from effecting the active element
      },
    },

    sideNavButtonSubmenuOpen: {
      ...sideNavButtonBase,
      backgroundColor: theme.palette.primary.fsDarkBlueSub,
    },

    sideNavButtonIcon: {
      margin: "auto",
    },

    buttonGroup: {
      display: "flex",
      flexDirection: "column",
      padding: "20px 20px",
    },
  };
});
