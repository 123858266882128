import React, { createContext, useContext } from "react";
import pseudoLocalization from "pseudo-localization";
import { ConfigContext } from "config";

export type PseudoTranslationStrategy = "bidi" | "accented";

export type LocalizationContextValue = {
  enablePseudoTranslations: boolean | undefined;
  translations: Record<string, string>;
  strategy: PseudoTranslationStrategy;
};

const LocalizationContext = createContext<LocalizationContextValue>({
  enablePseudoTranslations: false,
  translations: {},
  strategy: "bidi"
});

type Translator = (phrase: TemplateStringsArray) => string;

export function applyTranslation({
  translations,
  pseudoLocalizationEnabled,
  strategy,
  phrase
}: {
  translations: Record<string, string>;
  pseudoLocalizationEnabled: boolean | undefined;
  strategy: PseudoTranslationStrategy;
  phrase: TemplateStringsArray;
}): string {
  if (phrase.length === 1) {
    // use pseudo localization to identify missing strings and during design to ensure property layouts
    return (
      translations[phrase[0]] ??
      (pseudoLocalizationEnabled
        ? pseudoLocalization.localize(phrase[0], { strategy })
        : phrase[0])
    );
  }

  // eslint-disable-next-line no-console
  console.error("String replacement in translations not implemented.");
  return phrase[0];
}

export function useTranslations(translations?: Record<string, string>) {
  const context = useContext(LocalizationContext);
  const config = useContext(ConfigContext);

  return function translate(phrase: TemplateStringsArray) {
    return applyTranslation({
      translations: translations?? context.translations,
      pseudoLocalizationEnabled: config?.reactAppEnablePseudoTranslations,
      strategy: context.strategy,
      phrase
    });
  };
}

export type TranslationProviderProps = {
  translations?: Record<string, string> | undefined;
  enablePseudoTranslations?: boolean | undefined;
  strategy?: PseudoTranslationStrategy;
  children: (translator: Translator) => React.ReactNode;
};

export const TranslationProvider: React.FC<TranslationProviderProps> = ({
  children,
  translations,
  enablePseudoTranslations,
  strategy
}) => {
  const config = useContext(ConfigContext);
  const pseudoTranslations =
    config?.reactAppEnablePseudoTranslations ?? enablePseudoTranslations;
  return (
    <LocalizationContext.Consumer>
      {c => {
        const newValues: LocalizationContextValue =
          translations || pseudoTranslations
            ? {
                translations: { ...c.translations, ...translations },
                enablePseudoTranslations:
                  pseudoTranslations !== undefined
                    ? pseudoTranslations
                    : c.enablePseudoTranslations,
                strategy: strategy ?? c.strategy
              }
            : c;
        function translate(phrase: TemplateStringsArray) {
          return applyTranslation({
            translations: newValues.translations,
            pseudoLocalizationEnabled:
              newValues.enablePseudoTranslations ?? false,
            strategy: newValues.strategy,
            phrase
          });
        }
        return (
          <LocalizationContext.Provider value={newValues}>
            {children(translate)}
          </LocalizationContext.Provider>
        );
      }}
    </LocalizationContext.Consumer>
  );
};
