import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type StatusIndicatorIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const StatusIndicatorIcon: React.FC<StatusIndicatorIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = props.iconSize ?? "12px";

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 12 12"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g
            transform="translate(-1018.000000, -2839.000000)"
            fill={color ?? "currentColor"}
          >
            <g id="Icons" transform="translate(0.000000, 2670.000000)">
              <circle cx="1024" cy="175" r="6" />
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default StatusIndicatorIcon;
