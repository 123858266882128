import React, { useContext } from "react";
import { PageLoadStateContext } from "components/common";
import { SideNavActions } from "../useSideNavReducer";
import { useNavigate } from "react-router";

export type SideNavSubMenuLinkProps = {
  link: string;
  content: string;
  dispatch: React.Dispatch<SideNavActions>;
};

const SideNavSubMenuLink: React.FC<SideNavSubMenuLinkProps> = props => {
  const navigateTo = useNavigate();
  const { link, content, dispatch } = props;
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
  return (
    <div
      role="menuitem"
      tabIndex={0}
      onClick={() => {
        navigateTo(link);
        dispatch({ type: "SUBMENU_TOGGLE_DATA" });
      }}
      onKeyPress={event => {
        switch (event.key) {
          case " ":
          case "Enter": {
            navigateTo(link);
            dispatch({ type: "SUBMENU_TOGGLE_DATA" });
            break;
          }
          default:
        }
      }}
    >
      {pageLoadState.loaded ? content : ""}
    </div>
  );
};

export default SideNavSubMenuLink;
