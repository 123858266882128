import React from "react";
import useBorderBoxStyle from "./useBorderBoxStyle";
import { BorderBoxProps } from "./BorderBoxTypes";

const BorderBox: React.FC<BorderBoxProps> = props => {
  const styles = useBorderBoxStyle(props);
  const children = props.labelledById
    ? React.Children.map(props.children, c =>
        React.cloneElement(c as React.ReactElement, {
          labelledById: props.labelledById
        })
      )
    : props.children;
  return (
    <div
      ref={e => 
        props.ref && (props.ref.current = e)
      }
      className={styles.borderBox}
    >
      {props.highlightLeftEdge && <div className={styles.highlight} />}
      {children}
    </div>
  );
};

BorderBox.defaultProps = {
  height: "full",
  width: "full",
  treatment: "float"
};

export default BorderBox;
