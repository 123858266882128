import React from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createThemedUseStyles } from "theme";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Header2 } from "components/common";
import { useTranslations } from "components/useTranslations";

export type TenantDetailsHeaderProps = {
  tenantKey?: string;
  tenantName?: string;
  creatingNew: boolean;
};

const useStyles = createThemedUseStyles(() => ({
  headerStyling: {
    padding: "16px",
    margin: "0 auto"
  }
}));

const TenantDetailsHeader: React.FC<TenantDetailsHeaderProps> = props => {
  const styles = useStyles();
  const t = useTranslations();

  return (
    <div className={styles.headerStyling}>
      {props.creatingNew ? (
        <Header2>{t`__TENANT_DETAILS_TITLE_NEW`}</Header2>
      ) : (
        <Breadcrumbs separator={<ArrowForwardIcon />}>
          <Link color="inherit" href="/tenants">
            <Header2>{t`__TENANT_DETAILS_TENANT_BREADCRUMBS`}</Header2>
          </Link>
          <Header2>{props.tenantName}</Header2>
        </Breadcrumbs>
      )}
    </div>
  );
};

export default TenantDetailsHeader;
