import { createThemedUseStyles } from "theme";

const useStyles = createThemedUseStyles(() => ({
  headerStyling: {
    width: "75vw",
    padding: "16px",
    margin: "0 auto"
  }
}));

export default useStyles;
