import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  sideNavRoot: {
    display: "flex",
    flex: "1",
  },

  sideNavMainMenu: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    backgroundColor: theme.palette.primary.fsDarkBlue,
  },

  sideNavButtonContainer: {
    display: "flex",
  },

  sideNavSubMenu: {
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
    display: "flex",
    flexDirection: "column",
    transition: `width ${theme.transitionTime}`,
    width: 240,
  },

  sideNavSubMenuClosed: {
    transition: `width ${theme.transitionTime}`, // this doesn't quite work
    width: 0,
  },

  subNavSubMenuHeader: {
    boxSizing: "border-box",
    color: theme.palette.white,
    display: "flex",
    justifyContent: "space-between",
    padding: `16px ${theme.scales.spacing.medium}px`,
  },

  sideNavSubMenuLinkHeader: {
    fontSize: "12px",
    paddingLeft: theme.scales.spacing.medium,
  },

  sideNavSubMenuLinks: {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.scales.spacing.medium,
  },

  sideNavSubMenuTextWrap: {
    padding: `${theme.scales.spacing.small}px ${theme.scales.spacing.medium}px`,
    margin: `${theme.scales.spacing.xsmall}px 0`,
    width: "100%",
    textAlign: "left",
  },

  sideNavSubMenuLinkWrap: {
    fontSize: "13px",
    padding: `${theme.scales.spacing.small}px ${theme.scales.spacing.medium}px`,
    margin: `${theme.scales.spacing.xsmall}px 0`,
    width: "100%",
    textAlign: "left",
    boxSizing: "border-box",
    cursor: "pointer",
    "&:hover:not(:disabled)": {
      backgroundColor: "rgba(216,216,216, 0.1)", // Create separate color in theme? Not sure if used elsewhere
    },
    display: "flex",
    justifyContent: "space-between",
    transition: `background-color ${theme.transitionTime}`,
  },

  sublinkContainer: {
    borderLeftColor: theme.palette.white,
    borderLeftStyle: "solid",
    borderLeftWidth: "1px",
    marginLeft: theme.scales.spacing.medium,
    marginTop: `${theme.scales.spacing.small}px`,
  },
}));
