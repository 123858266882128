import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type PlusCircleIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   *  Width and Height of the Icon  icon = 18px and image = 40px. Having one property ensures width and height are always equal - necessary for circle styling
   */
  dimension?: "18px" | "40px";
};

const PlusCircleIcon: React.FC<PlusCircleIconProps> = props => {
  const { decorative, label, color, dimension } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width={dimension}
        height={dimension}
        viewBox="0 0 18 18"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-603.000000, -2765.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.6"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(604.000000, 96.000000)">
                <g>
                  <circle id="Oval" cx="8" cy="8" r="8" />
                  <line x1="8" y1="4.8" x2="8" y2="11.2" id="Path" />
                  <line x1="4.8" y1="8" x2="11.2" y2="8" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

PlusCircleIcon.defaultProps = {
  dimension: "18px"
};

export default PlusCircleIcon;
