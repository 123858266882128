export const routes = {
  data: "/:data/*",
  features: "/features",
  segments: "/segments",
  tenants: "/tenants",
  tenantsCreate: "/tenants/create",
  tenantsEdit: "/tenants/edit/",
  suppliers: "/suppliers",
  suppliersCreate: "/suppliers/create",
  suppliersEdit: "/suppliers/edit/",
  home: "/",
  ingredients: "/ingredients",
  ingredientsCreate: "/ingredients/create",
  ingredientsEdit: "/ingredients/edit/:ingredientKey",
  nutrients: "/nutrients",
  nutrientsCreate: "/nutrients/create",
  nutrientsEdit: "/nutrients/edit/",
  users: "/users",
  userInvite: "/users/invite",
  usersEdit: "/users/edit/:userKey",
  userReactivate: "/users/reactivate/:userKey",
  userDeactivate: "/users/deactivate/:userKey",
  CNSVersionsCreate: "/CNSVersions/create",
  CNSVersions: "/CNSVersions",
  releaseNotes: "/release-notes",
  releaseNotesCreate: "/release-notes/create",
  releaseNotesEdit: "/release-notes/edit/:releaseNotesKey",
  unathorizedPage: "/unathorized"
};
