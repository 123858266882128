import React from "react";
import { Header2 } from "components/common";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { User } from "api/models";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import { TranslationProvider } from "components/useTranslations";
import useUserDetailHeaderStyles from "./useUserDetailHeaderStyles";

export type UserDetailHeaderProps = {
  user?: User;
};

const UserDetailHeader: React.FC<UserDetailHeaderProps> = props => {
  const styles = useUserDetailHeaderStyles();
  const { user } = props;
  return (
    <TranslationProvider translations={user?.translations}>
      {t => (
        <div className={styles.headerStyling}>
          <Breadcrumbs separator={<ArrowForwardIcon />}>
            <Link color="inherit" href="/users">
              <Header2> {t`__USER_DETAILS_TITLE`} </Header2>
            </Link>
            <Typography color="textPrimary" component="div">
              {user?.key ? (
                <Header2> {t`__USER_DETAILS_TITLE_EXISTING`} </Header2>
              ) : (
                <Header2>{t`__USER_DETAILS_TITLE_NEW`}</Header2>
              )}
            </Typography>
          </Breadcrumbs>
        </div>
      )}
    </TranslationProvider>
  );
};

export default UserDetailHeader;
