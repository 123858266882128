import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type SettingsIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
};

const SettingsIcon: React.FC<SettingsIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1188.000000, -3048.000000)"
            stroke={color ?? "#85929C"}
            strokeWidth="1.2"
          >
            <g transform="translate(0.000000, 2880.000000)">
              <g transform="translate(1189.000000, 169.000000)">
                <path d="M4.76613606,6.56013606 C5.28750935,7.25715181 6.08591244,7.69335632 6.95412598,7.75553607 C7.82233952,7.81771582 8.67475554,7.49973974 9.29013606,6.88413606 L11.0901361,5.08413606 C12.2270527,3.9069994 12.2107932,2.03588173 11.0535918,0.878680336 C9.89639039,-0.278521059 8.02527272,-0.294780566 6.84813606,0.842136062 L5.81613606,1.86813606" />
                <path d="M7.16613606,5.36013606 C6.64476277,4.66312031 5.84635968,4.22691581 4.97814614,4.16473606 C4.1099326,4.10255631 3.25751658,4.42053239 2.64213606,5.03613606 L0.842136062,6.83613606 C-0.294780566,8.01327272 -0.278521059,9.88439039 0.878680336,11.0415918 C2.03588173,12.1987932 3.9069994,12.2150527 5.08413606,11.0781361 L6.11013606,10.0521361" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default SettingsIcon;
