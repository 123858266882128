import React, { useContext } from "react";
import { Icon, LabelText, Text, PageLoadStateContext } from "components/common";
import { withAction } from "components/common/actions";
import useUniqueId from "components/useUniqueId";
import useSideNavButtonStyles from "./useSideNavButtonStyles";
import { SideNavActions } from "../useSideNavReducer";
import { useNavigate } from "react-router";

type SideNavButtonProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  icon: "home" | "list" | "search" | "tool" | "settings";
  name: string;
  selected?: boolean;
  subMenuOpen?: boolean;
  link?: string;
  action?: SideNavActions;
  dispatch?: React.Dispatch<SideNavActions>;
};

const SideNavButton: React.FC<SideNavButtonProps> = props => {
  const { icon, link, selected, subMenuOpen, name, dispatch, action } = props;

  const {
    buttonGroup,
    sideNavButton,
    sideNavButtonIcon,
    sideNavButtonSelected,
    sideNavButtonSubmenuOpen
  } = useSideNavButtonStyles();

  const buttonStyles = selected
    ? sideNavButtonSelected
    : subMenuOpen
    ? sideNavButtonSubmenuOpen
    : sideNavButton;

  const textId = useUniqueId();
  const navigateTo = useNavigate();
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
  const button = (
    <>
      <button
        className={buttonStyles}
        onClick={() => {
          if (link) {
            navigateTo(link);
          }
          if (dispatch !== undefined && action !== undefined) {
            dispatch(action);
          }
        }}
        name={name}
        type="button"
      >
        <div className={buttonGroup}>
          <div className={sideNavButtonIcon}>
            <Icon icon={icon} decorative />
          </div>
          {pageLoadState.loaded && (
            <LabelText id={textId}>
              <Text textStyle="label-sidenav">{props.children}</Text>
            </LabelText>
          )}
        </div>
      </button>
    </>
  );

  return button;
};

export default withAction(SideNavButton, "onClick");
