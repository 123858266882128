import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type DollarIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  customWidth?: number;
  customHeight?: number;
};

const DollarSignIcon: React.FC<DollarIconProps> = props => {
  const { color, decorative, label, customWidth, customHeight } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox customHeight={customHeight} customWidth={customWidth}>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        role="img"
        width="12px"
        height="21px"
        viewBox="0 0 12 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1059.000000, -2801.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.66666667"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(1060.000000, 132.000000)">
                <g>
                  <line x1="5" y1="0" x2="5" y2="18.3333333" id="Path" />
                  <path
                    d="M9.16666667,3.33333333 L2.91666667,3.33333333 C1.30583615,3.33333333 -7.40148683e-16,4.63916948 -7.40148683e-16,6.25 C-7.40148683e-16,7.86083052 1.30583615,9.16666667 2.91666667,9.16666667 L7.08333333,9.16666667 C8.69416383,9.1666667 10,10.4725028 10,12.0833333 C10,13.6941638 8.69416383,15 7.08333333,15 L-7.40148683e-16,15"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default DollarSignIcon;
