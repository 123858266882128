import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  Grid: {
    margin: "16px"
  },
  pageContent: {
    height: "85%",
    width: "75%",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    padding: "16px",
    margin: "24px auto",
  },
  masterContainerEdit: {
    margin: "0",
    height: "100%",
    backgroundColor: theme.palette.grays.light,
  },
  masterContainerCreate: {
    margin: "0",
    height: "100%",
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
  },
  spaceChildrenApart: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerStyling: {
    borderRadius: "10px",
    padding: "16px",
    margin: "16px auto"
  },

  ul: {
    paddingLeft: "1.2em"
  }
}));
