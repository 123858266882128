import React, { useContext } from "react"
import {InputLabel as MuiInputLabel, InputLabelProps} from "@mui/material"
import { PageLoadStateContext } from "../trackPageLoadState";

export const InputLabel: React.FunctionComponent<InputLabelProps> = (props) => {
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
    return (
        <MuiInputLabel {...props}>
            {pageLoadState.loaded && props.children}
        </MuiInputLabel>
    )
}