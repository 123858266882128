import React from "react";
import { useTheme } from "theme";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type CloseIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const CloseIcon: React.FC<CloseIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const theme = useTheme();
  const stroke = color ?? theme.palette.primary.fsBlue;
  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="14px"
        height="14px"
        role="img"
        viewBox="0 0 14 14"
        version="1.1"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-355.000000, -2397.000000)"
            stroke={stroke}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2300.000000)">
              <g transform="translate(356.000000, 98.000000)">
                <g>
                  <line x1="12" y1="0" x2="0" y2="12" id="Path" />
                  <line x1="0" y1="0" x2="12" y2="12" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default CloseIcon;
