import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
    formControl: {
        margin: theme.scales.spacing.normal,
        minWidth: 120
      },
      selectEmpty: {
        marginTop: "16px"
      }
}));
