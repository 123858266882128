class AppTheme {
  borderDefault = "1px solid #B7C2C6";

  boxShadow = "0 1px 8px 0 rgba(53,53,53,0.1)";

  navDropShadow = "0px 5px 5px #EEEEEE";

  navLogoSize = "36px";

  transitionTime = "250ms";

  // Inter font does not support italics
  font = {
    thin: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 100
    },
    extraLight: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 200
    },
    light: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 300
    },
    regular: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 400
    },
    medium: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 500
    },
    semiBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 600
    },
    bold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 700
    },
    extraBold: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 800
    },
    black: {
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: 900
    }
  };

  /** Colors used in the application, do not add colors without reviewing with feature team leads. */
  palette = {
    white: "#ffffff",
    transparent: "transparent",
    primary: {
      /** Use cases: Primary Actions */
      fsBlue: "#149EC6",
      /** Use cases: Hover */
      fsBluePrimary: "#1487AB",
      /** Use cases: Hover */
      fsBlueSecondary: "#E7F5F9",
      /** Use cases: Navigation BG, Text */
      fsDarkBlue: "#162E3F",
      /** Use cases: Sub-Navigation Menu */
      fsDarkBlueSub: "#2D4253"
    },
    secondary: {
      /** Use cases: Icons */
      turquoise: "#5EBFC0",
      /** Use cases: Icons/Error */
      red: "#B53D3D",
      /** Use cases: Icons/Status */
      green: "#76AE1B",
      lightGreen: "#B5D283",
      /** Use cases: Icons/Status */
      yellow: "#E5D283",
      lightYellow: "#EDCE84"
    },
    grays: {
      /** Use cases: Text */
      dark: "#3F4750",
      /** Use cases: Disabled Text Labels */
      neutralWarm: "#A5A9B0",
      /** Use cases: Dividers */
      neutralCool: "#B7C2C6",
      /** Use cases: Product Background */
      light: "#EAECF1",
      /** Use case: TopNav LabelText */
      lighter: "#AFB8BD",
      /** Use cases: Component Background, Zebra Striping */
      extraLight: "#F6F7FA",
      /** Use cases: SideNav divider */
      neutralCoolTransparent: "rgba(183,194,198,0.3)"
    },
    opacity: {
      // https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
      alpha60: "99", // 60%
      alpha30: "4D" // 30%
    },
    chart: {
      primary: {
        blue: "#4472C4",
        orange: "#ED7D31",
        gray: "#A5A5A5",
        yellow: "#FFC000",
        purple: "#AA77B4"
      },
      secondary: {
        green: "#70AD47"
      }
    }
  };

  scales = {
    spacing: {
      xsmall: 2,
      small: 4,
      normal: 8,
      medium: 20,
      large: 40,
      xlarge: 80,
      xxlarge: 100
    },
    input: {
      widths: {
        xsmall: 84,
        small: 170,
        medium: 400,
        large: 810
      },
      height: {
        xsmall: 28,
        small: 36,
        medium: 48,
        large: 132
      }
    },
    icon: {
      xxsmall: 12,
      xsmall: 16,
      small: 20,
      medium: 24,
      large: 34,
      xlarge: 48
    },
    button: {
      widths: {
        small: 134,
        normal: 190,
        medium: 236,
        large: 260
      }
    },
    dropdown: {
      maxHeight: 400,
      widths: {
        normal: 220,
        large: 250
      }
    },
    page: {
      maxWidth: 1280,
      formWidth: 852 // width to accomodate two default-width form inputs side by side, with padding
    },
    tooltip: {
      minWidth: 150
    },
    shoppingCard: {
      minWidth: 300
    },
    grid: {
      width: {
        xxsmall: 20,
        xsmall: 50,
        small: 100,
        normal: 150,
        medium: 200
      },
      height: {
        xsmall: 28,
        small: 36,
        medium: 48,
        large: 64,
        xlarge: 132
      },
      smallPadding: 12,
      padding: 20,
      paddingTop: 14,
      paddingBottom: 14,
      paddingLeft: 14,
      paddingRight: 14,
      minHeight: 32
    }
  };

  zIndex = {
    popup: 10,
    overlay: 20,
    overlayContent: 21,
    modal: 1300 // This must be >= MaterialUI Modal zIndex
  };

  tableLoading = {
    paged: 20
  };

  breakpoins = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400
  };
}

const defaultTheme = new AppTheme();

export { AppTheme, defaultTheme };