import React, { useState, useEffect } from "react";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams,
} from "@mui/x-data-grid-pro";
import Tooltip from "@mui/material/Tooltip";
import { methods } from "api/methods";
import { useApi } from "api/useApi";
import { resources } from "api/resources";
import { DispatchAction, PrimaryButton, LoadingIndicator } from "components/common";
import InfoIcon from "components/common/Icon/InfoIcon";
import ActionModal from "components/common/ActionModal";
import SecondaryButton from "components/common/SecondaryButton";
import { Tenant, CalculationModel } from "api/models";
import { hashCodeOfString } from "api/tools";
import { format } from "date-fns";
import useCNSVersionsAssignTenantsModalStyles from "./useCNSVersionsAssignTenantsModalStyles";
import { FormControlLabel, Switch, Typography, IconButton } from "@mui/material";
import { DateInput } from "components/common/DateInput";

export type CNSVersionsAssignTenantsModalProps = {
  tenantList: Tenant[];
  dispatch: React.Dispatch<any>;
  isOpen: boolean;
  modalDescriptionLabel: string | undefined;
  weightInputModalLabel: string | undefined;
  actionLink?: any;
  saveModalLabel: string;
  cancelModalLabel: string | undefined;
  modalHeaderLabel: string | undefined;
  selectedCNSVersionKey: string;
  selectedCNSVersionName: string;
  versionLabel: string;
  assignColumnLabel: string;
  tenantNameLabel: string;
  versionsAssignedLabel: string;
  effectiveDateLabel: string;
  errorMessageLabel: string;
  cnsVersionConflictMessage: string;
  effectiveDateFormat: string | undefined;
  triggerParentActions: (refresh: boolean, close: boolean) => any;
};

const CNSVersionsAssignTenantsModal: React.FC<CNSVersionsAssignTenantsModalProps> =
  (props) => {
    const {
      dispatch,
      isOpen,
      tenantList,
      triggerParentActions,
      selectedCNSVersionKey,
      selectedCNSVersionName,
      versionLabel,
      assignColumnLabel,
      tenantNameLabel,
      versionsAssignedLabel,
      effectiveDateLabel,
      errorMessageLabel,
      cnsVersionConflictMessage,
      effectiveDateFormat,
    } = props;

    const maxVersionsByTenant = 5;
    const [gridTenantData, setGridTenantData] = useState(tenantList);
    const [errorMessage, setErrorMessage] = useState("");
    const [isSaving, setIsSaving] = useState(false);
    const [hashState, setHashState] = useState("");
    const [firstTimeLoad, setFirstTimeLoad] = useState(true);
    const [isUntouched, setIsUntouched] = useState(true);
    const [isUnchanged, setIsUnchanged] = useState(true);
    const styles = useCNSVersionsAssignTenantsModalStyles();
    const execute = useApi();
    const [currentDate, setCurrentDate] = useState("");

    useEffect(() => {
      if (effectiveDateFormat) {
        setCurrentDate(format(new Date(), effectiveDateFormat));
      }
    }, [effectiveDateFormat]);

    useEffect(() => {
      if(firstTimeLoad){
        const sortedData = tenantList.sort((x, y) => {
          const result =
            x.calculationModels?.some(
              (k) => k.key === selectedCNSVersionKey
            ) ===
            y.calculationModels?.some((k) => k.key === selectedCNSVersionKey)
              ? 0
              : x.calculationModels?.some(
                  (k) => k.key === selectedCNSVersionKey
                )
              ? -1
              : 1;

          return result !== 0
            ? result
            : x.tenantOrgName?.localeCompare(y.tenantOrgName!) ?? 0;
        });
        if (!isSaving) {
          setGridTenantData(sortedData);
        }
        setFirstTimeLoad(false);
      }
      
    }, [tenantList, isSaving, selectedCNSVersionKey, firstTimeLoad]);

    useEffect(() => {
      if (!hashState) {
        setHashState(hashCodeOfString(JSON.stringify(gridTenantData)));
      } else {
        const newHash = hashCodeOfString(JSON.stringify(gridTenantData));
        setIsUnchanged(newHash === hashState);
      }
    }, [gridTenantData, hashState]);

    const onChangeDate = (newDate: Date | null, row: any) => {
      setIsUntouched(false);
      setGridTenantData((prevTenantRows) => {
        const newInputDate = newDate ?? new Date();
        const newEffectiveDate = format(
          newInputDate,
          effectiveDateFormat ?? ""
        );

        const tenantIndex = prevTenantRows.findIndex(
          (tenant) => tenant.organizationKey === row.id
        );
        const tenantToUpdate = prevTenantRows[tenantIndex];

        if (
          tenantToUpdate.calculationModels &&
          tenantToUpdate.calculationModels?.length > 0
        ) {
          const calcIndex =
            prevTenantRows[tenantIndex].calculationModels?.findIndex(
              (calc) => calc.key === selectedCNSVersionKey
            ) ?? -1;
          if (calcIndex !== -1) {
            tenantToUpdate.calculationModels[calcIndex].effectiveDate =
              newEffectiveDate;

            setErrorMessage("");
          } else {
            const newCalcVersion: CalculationModel = {
              key: selectedCNSVersionKey,
              versionName: selectedCNSVersionName,
              effectiveDate: newEffectiveDate,
            };

            tenantToUpdate.calculationModels.push(newCalcVersion);
          }
        } else {
          tenantToUpdate.calculationModels = [
            {
              key: selectedCNSVersionKey,
              versionName: selectedCNSVersionName,
              effectiveDate: newEffectiveDate,
            },
          ];

          setErrorMessage("");
        }
        return prevTenantRows.map((rowTenant, index) =>
          index === tenantIndex
            ? {
                ...rowTenant,
                calculationModels: tenantToUpdate.calculationModels,
              }
            : rowTenant
        );
      });
    };

    const onChangeAssign = (row: any) => {
      setIsUntouched(false);
      setGridTenantData((prevTenantRows) => {
        const tenantIndex = prevTenantRows.findIndex(
          (tenant) => tenant.organizationKey === row.id
        );
        const tenantToUpdate = prevTenantRows[tenantIndex];

        if (row.assign) {
          const calcIndex =
            prevTenantRows[tenantIndex].calculationModels?.findIndex(
              (calc) => calc.key === selectedCNSVersionKey
            ) ?? -1;

          tenantToUpdate.calculationModels?.splice(calcIndex, 1);
          setErrorMessage("");
        } else if (
          !row.assign &&
          (tenantToUpdate.calculationModels?.length ?? 0) < maxVersionsByTenant
        ) {
          const actualCalcVersions: CalculationModel[] =
            tenantToUpdate.calculationModels
              ? tenantToUpdate.calculationModels
              : [];
          const newCalcVersion: CalculationModel = {
            key: selectedCNSVersionKey,
            versionName: selectedCNSVersionName,
            effectiveDate: row.effectiveDate ?? currentDate,
          };

          actualCalcVersions.push(newCalcVersion);
          tenantToUpdate.calculationModels = actualCalcVersions;
          setErrorMessage("");
        } else {
          const stringError = `${errorMessageLabel} ${String(row.tenantName)}`;
          setErrorMessage(stringError);
        }
        const newValue = prevTenantRows.map((rowTenant, index) =>
          index === tenantIndex
            ? {
                ...rowTenant,
                calculationModels: tenantToUpdate.calculationModels,
              }
            : rowTenant
        );
        return newValue;
      });
    };

    const saveTenantData = () => {
      setIsSaving(true);
      execute(
        `${resources.CNSVersionsAssignTenants}`,
        methods.POST,
        gridTenantData
      )
        .then((data) => {
          const result = data as Tenant[];
          if (result !== null) {
            result.forEach((r, index) => {
              if (r.cannotUnassign) {
                onChangeAssign(rows[index]);
              }
            })
            const tenantNames = result
              .filter((t) => t.cannotUnassign)
              .map((t) => t.tenantOrgName)
              .join(", ")
              .concat(".");
            if (tenantNames.length > 1) {
              setErrorMessage(
                `${cnsVersionConflictMessage} ${String(tenantNames)}`
              );
              triggerParentActions(true, false);
            } else {
              triggerParentActions(true, true);
            }
            setIsSaving(false);
          }
        })
        .catch(() => {});
    };

    const columns: GridColDef[] = [
      {
        field: "assign",
        headerName: assignColumnLabel,
        flex: 1,
        renderHeader: () => (
          <Typography variant="overline" color="textSecondary">
            <strong>{assignColumnLabel}</strong>
          </Typography>
        ),
        renderCell: (params: GridCellParams) => (
          <FormControlLabel
            control={
              <Switch
                id="toggle"
                checked={params.value === true}
                onChange={() => onChangeAssign(params.row)}
                color="primary"
              />
            }
            label=""
          />
        ),
        sortable: false
      },
      {
        field: "tenantName",
        headerName: tenantNameLabel,
        flex: 1,
        renderHeader: () => (
          <Typography variant="overline" color="textSecondary">
            <strong>{tenantNameLabel}</strong>
          </Typography>
        )
      },
      {
        field: "versionsAssigned",
        headerName: versionsAssignedLabel,
        flex: 1,
        renderHeader: () => (
          <Typography variant="overline" color="textSecondary">
            <strong>{versionsAssignedLabel}</strong>
          </Typography>
        ),
        renderCell: (params: GridCellParams) => (
          <div className={styles.assignedVersionsCell}>
            {params.value as string}
            {params.value !== 0 && (
              <Tooltip
                title={
                  <span className={styles.tooltipTitle}>
                    {params.row.versionNames}
                  </span>
                }
              >
                <IconButton>
                  <InfoIcon iconSize={12} color="blue" />
                </IconButton>
              </Tooltip>
            )}
          </div>
        )
      },
      {
        field: "effectiveDate",
        headerName: effectiveDateLabel,
        display: "flex",
        flex: 1,
        renderHeader: () => (
          <Typography variant="overline" color="textSecondary">
            <strong>{effectiveDateLabel}</strong>
          </Typography>
        ),
        renderCell: (params: GridCellParams): React.ReactNode =>
          !params.value 
            ? <></> 
            : (
              <div className={styles.fieldContainer}>
                <DateInput
                  onChange={(newDate) => onChangeDate(newDate, params.row)}
                  value={new Date(params.row.effectiveDate)}
                  readOnlyInput
                  borderless
                  format={effectiveDateFormat}
                />
              </div>
            )
      },
    ];

    const rows: GridRowsProp = gridTenantData?.map<GridRowModel>((tenant) => ({
      id: tenant.organizationKey,
      assign: tenant.calculationModels?.some(
        (cm) => cm.key === selectedCNSVersionKey
      ),
      tenantName: tenant.tenantOrgName,
      versionsAssigned: tenant.calculationModels?.length,
      versionNames: tenant.calculationModels
        ?.map((x: CalculationModel) => x.versionName)
        .join("\n"),
      effectiveDate: tenant.calculationModels?.find(
        (cm) => cm.key === selectedCNSVersionKey
      )?.effectiveDate,
    }));

    return (
      <ActionModal
        fullWidth
        name="cns_versions_assign_tenants_modal"
        isOpen={isOpen}
        closeDispatch={dispatch}
        closeAction={() => triggerParentActions(false, true)}
        title={props.modalHeaderLabel}
        errorMessage={errorMessage}
        buttonBayRender={() => (
          <div className={styles.actionButtonsContainer}>
            <DispatchAction
              action={() => triggerParentActions(false, true)}
              dispatch={dispatch}
              event="onClick"
            >
              <SecondaryButton
                name="cns_versions_assign_tenants_modal_cancel_button"
                title={props.cancelModalLabel}
                disabled={isSaving}
                isBorderless
              />
            </DispatchAction>

            <PrimaryButton
              name="saveCNS"
              disabled={isSaving || isUntouched || isUnchanged}
              onClick={saveTenantData}
            >
              { isSaving && <div className={styles.isSavingLoader}>
                <LoadingIndicator />
              </div> }
              <span>
                {props.saveModalLabel}
              </span>
            </PrimaryButton>
          </div>
        )}
      >
        <strong>
          {versionLabel} {selectedCNSVersionName}
        </strong>
        <br />
        <br />
        <div>{props.modalDescriptionLabel}</div>
        <div className={styles.dataGridContainer}>
          <div className={styles.dataGridContainerContent}>
            <div className={styles.dataGridContainerContentChild}>
              <DataGridPro
                rows={rows}
                columns={columns}
                checkboxSelection={false}
                disableRowSelectionOnClick
                sortingOrder={["asc", "desc"]}
                hideFooterPagination
              />
            </div>
          </div>
        </div>
      </ActionModal>
    );
  };

export default CNSVersionsAssignTenantsModal;
