import React, { useContext } from 'react';
import { FormHelperText as MuiFormHelperText, FormHelperTextProps } from '@mui/material';
import { PageLoadStateContext } from '../trackPageLoadState';

export const FormHelperText: React.FunctionComponent<FormHelperTextProps> = (props) => {
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };

    return (
        <MuiFormHelperText
            {...props}
        >
            {pageLoadState.loaded && props.children}
        </MuiFormHelperText>
    );
}