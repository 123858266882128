import { AppTheme } from "theme";

export type NamedScale = "xsmall" | "small" | "medium" | "large" | "auto";
export type NamedSize =
  | NamedScale
  | "full"
  | "content"
  | "left-aligned"
  | "auto";
export type NamedInputWidth = NamedScale | NamedSize;
export type NamedInputHeight = NamedScale | NamedSize;

export function getSpacingValue(
  scale: NamedScale | number | undefined,
  theme: AppTheme,
  defaultValue?: number
): number | undefined {
  if (typeof scale === "number") return scale;
  switch (scale) {
    case "xsmall":
      return theme.scales.spacing.xsmall;
    case "small":
      return theme.scales.spacing.small;
    case "medium":
      return theme.scales.spacing.medium;
    case "large":
      return theme.scales.spacing.large;
    default:
      return defaultValue;
  }
}

export function getMarginBottom(
  scale: NamedScale | number | undefined,
  theme: AppTheme,
): number | undefined {
  if (typeof scale === "number") {
    return scale;
  }
  switch (scale) {
    case "xsmall":
      return theme.scales.spacing.xsmall;
    case "small":
      return theme.scales.spacing.small;
    case "medium":
      return theme.scales.spacing.medium;
    case "large":
      return theme.scales.spacing.large;
    default:
      return 0;
  }
}
