import React from "react";
import SecondaryButton from "components/common/SecondaryButton";
import { routes } from "routes";
import { useTranslations } from "components/useTranslations";
import { useNavigate } from "react-router";

const UsersInviteButton: React.FC = () => {
  const navigateTo = useNavigate();
  const t = useTranslations();
  return (
    <SecondaryButton
      name="inviteUser"
      onClick={() => navigateTo(routes.userInvite)}
    >
      {t`__USERS_INITIAL_VIEW_INVITE_BUTTON`}
    </SecondaryButton>
  );
};

export default UsersInviteButton;
