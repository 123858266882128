import isEqual from "react-fast-compare";
import React from "react";
import { useTranslations } from "components/useTranslations";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams,
  GridSortItem
} from "@mui/x-data-grid-pro";
import { Nutrient } from "api/models";
import { AppTypography, LinkButton } from "components/common";
import { useNavigate } from "react-router-dom";

// Maybe will be updated in the future
type NutrientsTableProps = {
  nutrients: Nutrient[];
  translations?: { [key: string]: string } | undefined;
  nutrientsLoading: boolean;
};

const NutrientsTable: React.FC<NutrientsTableProps> = props => {
  // retrieve data

  const navigateTo = useNavigate();
  const t = useTranslations();
  const renderEditButton = (params: GridCellParams) => {
    return (
      <LinkButton
        label={t`__NUTRIENTS_INITIAL_VIEW_VIEW_BUTTON`}
        onClick={() => navigateTo(`/nutrients/edit/${params.row.id}`)}
      />
    );
  };

  // Set Up Columns
  const NutrientsColumns: GridColDef[] = [
    {
      field: "name",
      flex: 2,
      headerName: t`__NUTRIENTS_INITIAL_VIEW_NAME_COLUMN`,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__NUTRIENTS_INITIAL_VIEW_NAME_COLUMN`}</strong>
        </AppTypography>
      ),
      renderCell: (params: GridCellParams) => <strong>{params.value as string}</strong>
    },
    {
      field: "code",
      flex: 1,
      headerName: t`__NUTRIENTS_INITIAL_VIEW_CODE_COLUMN`,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__NUTRIENTS_INITIAL_VIEW_CODE_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "unit",
      headerName: t`__NUTRIENTS_INITIAL_VIEW_UNIT_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__NUTRIENTS_INITIAL_VIEW_UNIT_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "edit",
      headerName: " ",
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary" />
      ),
      renderCell: renderEditButton,
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      width: 75,
      disableColumnMenu: true,
      display: "flex"
    }
  ];

  const rows_from_api: GridRowsProp = props.nutrients.map<GridRowModel>(
    nutrient => ({
      id: nutrient.key,
      name: nutrient.name,
      code: nutrient.code,
      unit: nutrient.unit
    })
  );

  // default sorting model
  const [sortModel, setSortModel] = React.useState([
    {
      field: "name",
      sort: "asc"
    }
  ] as GridSortItem[]);

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={props.nutrientsLoading ? [] : rows_from_api}
            rowHeight={50}
            columns={NutrientsColumns}
            pagination
            sortingOrder={["asc", "desc"]}
            sortModel={sortModel}
            onSortModelChange={model => {
              if (!isEqual(sortModel, model)) {
                setSortModel(model);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default NutrientsTable;
