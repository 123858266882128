import React, { useEffect, useState } from "react";
import { useTranslations } from "components/useTranslations";
import { Grid, Paper, Snackbar, SnackbarCloseReason } from "@mui/material";
import { TextField, InputLabel } from "components/common/";
import { Header2 } from "components/common";
import PrimaryButton from "components/common/PrimaryButton";
import { methods } from "api/methods";
import { resources } from "api/resources";
import { ReleaseNote } from "api/models";
import { useApi } from "api/useApi";
import Alert from "@mui/material/Alert";
import useReleaseNotesDetailsFormStyles from "./useReleaseNotesDetailsFormStyles";
import useReleaseNotesDetailsReducer, {
  ReleaseNotesDetailsActionType
} from "./useReleaseNotesDetailsReducer";
import { DateInput } from "components/common/DateInput";

export type ReleaseNotesDetailsFormProps = {
  releaseNote?: ReleaseNote;
  getReleaseNotesStateHandler?: () => void;
};

const ReleaseNotesDetailsForm: React.FC<ReleaseNotesDetailsFormProps> =
  props => {
    const styles = useReleaseNotesDetailsFormStyles();
    const execute = useApi();
    const { releaseNote } = props;
    const t = useTranslations();
    const [state, dispatch] = useReleaseNotesDetailsReducer();

    // On page load logic
    useEffect(() => {
      if (releaseNote?.key) {
        dispatch({
          type: ReleaseNotesDetailsActionType.ON_PAGE_LOAD,
          payload: {
            key: releaseNote?.key,
            formDetails: {
              releaseNotesVersion: releaseNote?.version ?? "",
              releaseNotesDate: releaseNote?.releaseDate ?? "",
              releaseNotesImprovements: releaseNote?.improvements ?? "",
              releaseNotesFixes: releaseNote?.fixes ?? ""
            },
            validationDetails: {
              releaseNotesVersionValidationMessage: "",
              releaseNotesDateValidationMessage: "",
              releaseNotesImprovementsValidationMessage: "",
              releaseNotesFixesValidationMessage: ""
            }
          }
        });
      } else {
        dispatch({
          type: ReleaseNotesDetailsActionType.ON_PAGE_LOAD,
          payload: {
            key: releaseNote?.key,
            formDetails: {
              releaseNotesVersion: releaseNote?.version ?? "",
              releaseNotesDate: `${(
                new Date().getMonth() + 1
              ).toString()}/${new Date().getDate().toString()}/${new Date()
                .getFullYear()
                .toString()}`,
              releaseNotesImprovements: releaseNote?.improvements ?? "",
              releaseNotesFixes: releaseNote?.fixes ?? ""
            }
          }
        });
      }
    }, [releaseNote, dispatch]);

    // Save Button
    const onClick = () => {
      const formValues: ReleaseNote = {
        key: releaseNote?.key ?? "",
        version: state.formDetails?.releaseNotesVersion,
        releaseDate: state.formDetails?.releaseNotesDate,
        improvements: state.formDetails?.releaseNotesImprovements,
        fixes: state.formDetails?.releaseNotesFixes
      };
      execute(
        releaseNote?.key
          ? `${resources.releaseNote(releaseNote?.key)}`
          : `${resources.releaseNotes}/create`,
        releaseNote?.key ? methods.PUT : methods.POST,
        formValues
      )
        .then((response: ReleaseNote) => {
          dispatch({
            type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
            payload: {
              key: "validationDetails",
              value: {
                ...state.validationDetails,
                releaseNotesVersionValidationMessage:
                  response.versionValidationMessage ?? "",
                releaseNotesDateValidationMessage:
                  response.dateValidationMessage ?? ""
              }
            }
          });
          if (response?.isInvalid) {
            setSuccess(false);
            return;
          }
          setSuccess(true);
          if (props.getReleaseNotesStateHandler !== undefined) {
            props.getReleaseNotesStateHandler();
          }
        })
        .catch(() => {});
    };

    // State whether form successful
    const [success, setSuccess] = useState(false);

    const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
      if (reason === "clickaway") {
        return;
      }
      setSuccess(false);
    };

    const handleInputImprovements = (event: React.KeyboardEvent) => {
      const bullet = "\u2022";
      if (state.formDetails?.releaseNotesImprovements?.length === 0) {
        dispatch({
          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
          payload: {
            key: "formDetails",
            value: {
              ...state.formDetails,
              releaseNotesImprovements: `${bullet} ${state.formDetails?.releaseNotesImprovements}`
            }
          }
        });
      }
      if (event.key === "Enter" && event.shiftKey) {
        dispatch({
          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
          payload: {
            key: "formDetails",
            value: {
              ...state.formDetails,
              releaseNotesImprovements: `${state.formDetails?.releaseNotesImprovements}${bullet} `
            }
          }
        });
      }
    };

    const handleInputFixes = (event: React.KeyboardEvent) => {
      const bullet = "\u2022";
      if (state.formDetails?.releaseNotesFixes?.length === 0) {
        dispatch({
          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
          payload: {
            key: "formDetails",
            value: {
              ...state.formDetails,
              releaseNotesFixes: `${bullet} ${state.formDetails?.releaseNotesFixes}`
            }
          }
        });
      }
      if (event.key === "Enter" && event.shiftKey) {
        dispatch({
          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
          payload: {
            key: "formDetails",
            value: {
              ...state.formDetails,
              releaseNotesFixes: `${state.formDetails?.releaseNotesFixes}${bullet} `
            }
          }
        });
      }
    };

    return (
      // 1) div = blue container
      // 2) Paper = container for form
      <div
        className={
          releaseNote?.key
            ? styles.masterContainerEdit
            : styles.masterContainerCreate
        }
      >
        <Paper className={styles.pageContent}>
          <form className={styles.form}>
            {/* First Row: form heading */}
            <Grid container>
              <Grid item xs={12} className={styles.spaceChildrenApart}>
                <Header2>{t`__RELEASE_NOTES_DETAILS_SUBTITLE`}</Header2>
                <Snackbar
                  anchorOrigin={{ vertical: "top", horizontal: "center" }}
                  open={success}
                  autoHideDuration={6000}
                  onClose={handleClose}
                >
                  <Alert onClose={handleClose} severity="success" variant="filled">
                    {t`__RELEASE_NOTES_DETAILS_SAVED`}
                  </Alert>
                </Snackbar>
                <PrimaryButton
                  disabled={!state.isFormDirty}
                  onClick={onClick}
                  name="saveReleaseNotes"
                >
                  {t`__RELEASE_NOTES_DETAILS_SAVE`}
                </PrimaryButton>
              </Grid>
            </Grid>
            {/* Second Row: version */}
            <Grid className={styles.rowOfInputs} container>
              <Grid item xs={12} md={4}>
                <TextField
                  className={styles.input}
                  size="small"
                  variant="outlined"
                  label={t`__RELEASE_NOTES_DETAILS_NAME`}
                  error={
                    state.validationDetails
                      ?.releaseNotesVersionValidationMessage !== ""
                  }
                  helperText={
                    state.validationDetails
                      ?.releaseNotesVersionValidationMessage
                  }
                  inputProps={{ maxLength: 50 }}
                  onChange={e => {
                    dispatch({
                      type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
                      payload: {
                        key: "formDetails",
                        value: {
                          ...state.formDetails,
                          releaseNotesVersion: e.target.value
                        }
                      }
                    });
                  }}
                  value={state.formDetails?.releaseNotesVersion}
                />
              </Grid>
            </Grid>
            {/* Row 3: release date */}
            <Grid className={styles.rowOfInputs} container>
              <Grid item xs={12} md={4}>
                <InputLabel
                  className={styles.label}
                  id="release-date-select-label"
                >
                  {t`__RELEASE_NOTES_DETAILS_RELEASE_DATE`}
                </InputLabel>
                    <DateInput
                      format={releaseNote?.dateFormatString}
                      readOnlyInput
                      onChange={(value) => {
                        dispatch({
                          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
                          payload: {
                            key: "formDetails",
                            value: {
                              ...state.formDetails,
                              releaseNotesDate: value
                            }
                          }
                        });
                      }}
                      value={new Date(state.formDetails?.releaseNotesDate ?? "")}
                    />
              </Grid>
            </Grid>
            <Grid className={styles.rowOfInputs} container>
              <Grid item xs={12} md={8}>
                <>
                  <InputLabel id="improvements-select-label">
                    {t`__RELEASE_NOTES_DETAILS_IMPROVEMENTS`}
                  </InputLabel>
                  <TextField
                    className={styles.input}
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder={t`__RELEASE_NOTES_DETAILS_PRESS_KEYS_BULLET_POINTS`}
                    onKeyUp={handleInputImprovements}
                    onFocus={() => {
                      if (
                        state?.formDetails?.releaseNotesImprovements?.length ===
                        0
                      ) {
                        dispatch({
                          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
                          payload: {
                            key: "formDetails",
                            value: {
                              ...state.formDetails,
                              releaseNotesImprovements: "\u2022 "
                            }
                          }
                        });
                      }
                    }}
                    onChange={e => {
                      dispatch({
                        type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
                        payload: {
                          key: "formDetails",
                          value: {
                            ...state.formDetails,
                            releaseNotesImprovements: e.target.value
                          }
                        }
                      });
                    }}
                    value={state.formDetails?.releaseNotesImprovements}
                    inputProps={{ maxLength: 1000 }}
                  />
                </>
              </Grid>
            </Grid>
            <Grid className={styles.rowOfInputs} container>
              <Grid item xs={12} md={8}>
                <>
                  <InputLabel id="fixes-select-label">
                    {t`__RELEASE_NOTES_DETAILS_FIXES`}
                  </InputLabel>
                  <TextField
                    className={styles.input}
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    variant="outlined"
                    placeholder={t`__RELEASE_NOTES_DETAILS_PRESS_KEYS_BULLET_POINTS`}
                    onKeyUp={handleInputFixes}
                    onFocus={() => {
                      if (state?.formDetails?.releaseNotesFixes?.length === 0) {
                        dispatch({
                          type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
                          payload: {
                            key: "formDetails",
                            value: {
                              ...state.formDetails,
                              releaseNotesFixes: "\u2022 "
                            }
                          }
                        });
                      }
                    }}
                    inputProps={{ maxLength: 1000 }}
                    onChange={(e) => {
                      dispatch({
                        type: ReleaseNotesDetailsActionType.CHANGE_FORM_STATE,
                        payload: {
                          key: "formDetails",
                          value: {
                            ...state.formDetails,
                            releaseNotesFixes: e.target.value
                          }
                        }
                      });
                    }}
                    value={state.formDetails?.releaseNotesFixes}
                  />
                </>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </div>
    );
  };

export default ReleaseNotesDetailsForm;
