import { createThemedUseStylesWithParams } from "theme";
import { TextProps } from "./TextTypes";

export default createThemedUseStylesWithParams<TextProps>((params, theme) => {
  const text = {
    ...theme.font.regular,
    color: params.color ?? "inherit",
    fontSize: "12px",
    lineHeight: "20px",
  };

  return {
    placeholder: {
      backgroundColor:
        params.isGreyBackground ?? false
          ? theme.palette.white
          : theme.palette.grays.light,
      color: 
        params.isGreyBackground ?? false
          ? theme.palette.white
          : theme.palette.grays.light
    },
    none: {
      ...text,
    },
    infoLarge: {
      ...text,
      fontSize: "16px",
      lineHeight: "26px"
    },
    infoLargeSlim: {
      composes: "$infoLarge",
      lineHeight: "18px"
    },
    infoLargeEmphasis: {
      ...theme.font.bold,
      composes: "$infoLarge"
    },
    infoNormal: {
      ...text,
      fontSize: "14px"
    },
    infoNormalEmphasis: {
      ...theme.font.semiBold,
      composes: "$infoNormal"
    },
    infoNormalStrongEmphasis: {
      ...theme.font.bold,
      composes: "$infoNormal"
    },
    label: {
      ...text,
      ...theme.font.bold,
      fontSize: "11px",
      lineHeight: "20px",
      color: theme.palette.grays.neutralCool
    },
    labelSideNav: {
      ...text,
      ...theme.font.bold,
      color: "currentColor",
      fontSize: "8px",
      lineHeight: "10px"
    },
    colorLabel: {
      composes: "$label",
      color: params.color ?? "inherit"
    },
    headerOne: {
      ...text,
      fontSize: "40px",
      lineHeight: "48px"
    },
    headerTwo: {
      ...text,
      fontSize: "28px",
      lineHeight: "32px"
    },
    headerTwoEmphasis: {
      ...theme.font.semiBold,
      composes: "$headerTwo"
    },
    headerFour: {
      ...text,
      fontSize: "18px",
      lineHeight: "22px"
    },
    headerFourEmphasis: {
      ...text,
      ...theme.font.bold,
      fontSize: "18px",
      lineHeight: "22px"
    },
    statusLabelNormal: {
      ...text,
      fontSize: "11px",
      lineHeight: "11px",
      color: params.color ?? "inherit",
      letterSpacing: "0"
    },
    statusLabelEmphasis: {
      ...theme.font.bold,
      fontSize: "11px",
      display: "flex",
      justifyContent: "center",
      alignContent: "center",
      alignItems: "center",
      letter: "normal",
      lineHeight: "13px",
      composes: "$statusLabelNormal"
    },
    topNavLabel: {
      ...theme.font.bold,
      fontSize: "11px",
      lineHeight: "13px",
      letter: "normal",
      textAlign: "left",
      color: theme.palette.grays.lighter
    },

    action: {
      ...theme.font.semiBold,
      composes: "$infoNormal",
      color: params.color ?? "inherit" // need to set explicitly if not use Text styling
    }
  };
});
