import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type CheckmarkIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const CheckmarkIcon: React.FC<CheckmarkIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="14px"
        height="10px"
        viewBox="0 0 14 10"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        {title}
        <g
          id="UI-+-Component-Guide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Cargill-Unity-Design-Guide"
            transform="translate(-275.000000, -2806.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g id="Icons" transform="translate(0.000000, 2670.000000)">
              <g
                id="Icons-/-Checkmark"
                transform="translate(276.000000, 137.000000)"
              >
                <polyline id="checkmark" points="12 0 4 8 0 4" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default CheckmarkIcon;
