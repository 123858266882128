import React from "react";
import { resources } from "api/resources";
import { useQuery } from "api/useQuery";
import { Supplier } from "api/models";
import { PageLoadStateProvider } from "components/common";
import { TranslationProvider } from "components/useTranslations";
import SupplierDetailForm from "./SupplierDetailForm";
import SupplierDetailHeader from "./SupplierDetailHeader";
import { useParams } from "react-router";

export type SupplierDetailPageProps = {
  creatingNew: boolean;
  supplierKey?: string;
};

const SupplierDetailPage: React.FC<SupplierDetailPageProps> = (props) => {
  const params = useParams();
  const [supplierName, setSupplierName] = React.useState<string>("");

  const { data: response } = useQuery<Supplier>(
    params.supplierKey
      ? resources.supplier(params.supplierKey)
      : resources.newSupplier
  );

  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <TranslationProvider translations={response?.translations}>
        {() => (
          <>
            <SupplierDetailHeader
              supplierName={supplierName}
              creatingNew={props.creatingNew}
            />
            <SupplierDetailForm
              changeName={setSupplierName}
              supplier={response}
              creatingNew={props.creatingNew}
              supplierTypeList={response?.supplierTypeOptions}
            />
          </>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};
export default SupplierDetailPage;
