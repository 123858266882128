import React from "react";
import { TranslationProvider } from "components/useTranslations";
import { InputLabel, Header2, PageLoadStateProvider } from "components/common/";
import { MenuItem, FormControl, Select } from "@mui/material";
import { useQuery } from "api/useQuery";
import { SegmentList } from "api/models";
import { resources } from "api/resources";
import SegmentItemsList from "./SegmentItemsList";
import useSegmentsPageStyles from "./useSegmentsPageStyles";

const SegmentsPage: React.FC = () => {
  const [currentSegment, setCurrentSegment] = React.useState("");
  const { data: segments } = useQuery<SegmentList>(resources.segments);

  const [isNew, setIsNew] = React.useState(false);
  const ADD_NEW = "ADD_NEW_SEGMENT";

  const handleChange = (event: any) => {
    const selectedItem = event.target.value;
    if (selectedItem === ADD_NEW) {
      setIsNew(true);
      setCurrentSegment(ADD_NEW);
    } else {
      setCurrentSegment(event.target.value);
      setIsNew(false);
    }
  };

  const segmentsList = segments?.segments ?? [];
  const classes = useSegmentsPageStyles();

  return (
    <PageLoadStateProvider isLoaded={!!segments?.translations}>
      <TranslationProvider translations={segments?.translations}>
        {t => (
          <div>
            <Header2>{t`__SEGMENTS_INITIAL_VIEW_TITLE`}</Header2>
            <FormControl className={classes.formControl} variant="standard">
              <InputLabel id="drp-segment-select-label">{t`__SEGMENTS_INITIAL_VIEW_SEGMENT`}</InputLabel>
              <Select
                id="drp-segment-select"
                labelId="drp-segment-select-label"
                className={classes.selectEmpty}
                displayEmpty
                value={currentSegment}
                onChange={handleChange}
              >
                <MenuItem
                  value={ADD_NEW}
                >{t`__SEGMENTS_INITIAL_VIEW_ADD_NEW`}</MenuItem>
                {segmentsList.map((segment, index) => {
                  return (
                    <MenuItem
                      value={segment.name}
                      key={`menu-item-${index.toString()}`}
                    >
                      {segment.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <SegmentItemsList segmentName={currentSegment} isNew={isNew} />
          </div>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default SegmentsPage;
