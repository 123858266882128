import React from "react";
import { useTranslations } from "components/useTranslations";
import { Header2 } from "components/common";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { ReleaseNote } from "api/models";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import useReleaseNotesDetailsHeaderStyles from "./useReleaseNotesDetailsHeaderStyles";

export type ReleaseNotesDetailsHeaderProps = {
  // Other props to come in future: id? etc.
  releaseNote?: ReleaseNote;
  dateFormat?: string;
};

const ReleaseNotesDetailsHeader: React.FC<ReleaseNotesDetailsHeaderProps> =
  props => {
    const styles = useReleaseNotesDetailsHeaderStyles();
    const { releaseNote } = props;
    const t = useTranslations();

    return (
      <div className={styles.headerStyling}>
        {releaseNote?.key ? (
          <Breadcrumbs separator={<ArrowForwardIcon />}>
            <Link color="inherit" href="/release-notes">
              <Header2>{t`__RELEASE_NOTES_DETAILS_TITLE_EXISTING`}</Header2>
            </Link>
            <Typography color="textPrimary" component="div">
              <Header2>{releaseNote?.version}</Header2>
            </Typography>
          </Breadcrumbs>
        ) : (
          <Header2>{t`__RELEASE_NOTES_DETAILS_TITLE_NEW`}</Header2>
        )}
      </div>
    );
  };

export default ReleaseNotesDetailsHeader;
