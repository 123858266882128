import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ProfileIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;

  /**
   * Degrees to rotate the icon
   */
  rotate?: number;
};

const PagingIcon: React.FC<ProfileIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox customHeight={30} customWidth={30} rotate={props.rotate}>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="30px"
        height="30px"
        viewBox="0 0 30 30"
        version="1.1"
      >
        {title}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-889.000000, -2796.000000)">
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(890.000000, 127.000000)">
                <rect
                  id="Rectangle"
                  stroke={color ?? "currentColor"}
                  fillOpacity="0"
                  fill="#FFFFFF"
                  x="0"
                  y="0"
                  width="28"
                  height="28"
                  rx="2"
                />
                <g
                  transform="translate(12.000000, 8.000000)"
                  fill={color ?? "currentColor"}
                  fillRule="nonzero"
                >
                  <path
                    d="M8.45546524,3 L-2.45346024,3 C-2.93940097,3 -3.18276099,3.53857096 -2.839149,3.85355339 L2.61531374,8.85355339 C2.82832376,9.04881554 3.17368124,9.04881554 3.38669126,8.85355339 L8.841154,3.85355339 C9.18476599,3.53857096 8.94140597,3 8.45546524,3 Z"
                    id="Path"
                    transform="translate(3.001003, 6.000000) rotate(-90.000000) translate(-3.001003, -6.000000) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default PagingIcon;
