import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ToolIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const TrashIcon: React.FC<ToolIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        role="img"
        width="16px"
        height="18px"
        viewBox="0 0 16 18"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-977.000000, -2802.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.55555556"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(978.000000, 133.000000)">
                <g>
                  <polyline id="Path" points="0 3.2 1.55555556 3.2 14 3.2" />
                  <path
                    d="M12.4444444,3.2 L12.4444444,14.4 C12.4444444,15.2836556 11.7479985,16 10.8888889,16 L3.11111111,16 C2.2520015,16 1.55555556,15.2836556 1.55555556,14.4 L1.55555556,3.2 M3.88888889,3.2 L3.88888889,1.6 C3.88888889,0.7163444 4.58533483,0 5.44444444,0 L8.55555556,0 C9.41466517,0 10.1111111,0.7163444 10.1111111,1.6 L10.1111111,3.2"
                    id="Shape"
                  />
                  <line
                    x1="5.44444444"
                    y1="7.2"
                    x2="5.44444444"
                    y2="12"
                    id="Path"
                  />
                  <line
                    x1="8.55555556"
                    y1="7.2"
                    x2="8.55555556"
                    y2="12"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default TrashIcon;
