import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type SearchIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const SearchIcon: React.FC<SearchIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        width="16px"
        height="16px"
        viewBox="0 0 16 16"
        version="1.1"
        role="img"
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-688.000000, -2766.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(689.000000, 97.000000)">
                <g transform="translate(0.000000, 0.000000)">
                  <ellipse
                    id="Oval"
                    cx="6.15915577"
                    cy="6.15384615"
                    rx="6.15915577"
                    ry="6.15384615"
                  />
                  <line
                    x1="13.8581005"
                    y1="13.8461538"
                    x2="10.5090595"
                    y2="10.5"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default SearchIcon;
