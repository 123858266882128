import { createThemedUseStylesWithParams } from "theme";
import { Header2Props } from "./Header2Types";
import { getMarginBottom } from "components/common/inputScales";

export default createThemedUseStylesWithParams<Header2Props>((params, theme) => ({
  h2: {
    marginTop: 0,
    marginLeft: 0,
    marginRight: 0,
    marginBottom: getMarginBottom(params.stackSpacing, theme)
  }
}));
