import React from "react";
import { TranslationProvider } from "components/useTranslations";
import SecondaryButton from "components/common/SecondaryButton";
import { routes } from "routes";
import { useNavigate } from "react-router";

export type NutrientsAdditionButtonProps = {
  translations?:{ [key: string]: string; } | undefined;
};

const NutrientsAdditionButton: React.FC<NutrientsAdditionButtonProps> = (props) => {

  const navigateTo = useNavigate();

  return (
    <TranslationProvider translations={props.translations}>
    {t => (
    <SecondaryButton
      name="createNutrient"
      onClick={() => navigateTo(routes.nutrientsCreate)}
    >
      {t`__NUTRIENTS_INITIAL_VIEW_CREATE_BUTTON`}
    </SecondaryButton>
    )}
    </TranslationProvider>
  );
};
export default NutrientsAdditionButton;
