import { useReducer } from "react";
import { CNSVersionDetails } from "api/models";

export type CNSVersionDetailsState = {
  details?: CNSVersionDetails;
  isFormDirty?: boolean;
  isLoaded?: boolean;
};

export type CNSVersionDetailsActions =
  | {
      type: "CNSVERSION_DETAILS_EDITED";
      payload: Partial<CNSVersionDetails>;
    }
  | {
      type: "CNSVERSION_DETAILS_EDITED";
      payload: Partial<CNSVersionDetailsState>;
    }
  | {
      type: "CNSVERSION_DETAILS_CREATED";
      payload: CNSVersionDetails;
    }
  | {
      type: "CNSVERSION_REQUEST_SENT";
    }
  | {
      type: "CNSVERSION_RESPONSE_RECEIVED";
      payload: Partial<CNSVersionDetails> | undefined;
    };

const initState: CNSVersionDetailsState = {
  details: undefined,
  isFormDirty: false,
  isLoaded: true,
};

export default function useCNSVersionDetailsFormReducer(): [
  CNSVersionDetailsState,
  React.Dispatch<CNSVersionDetailsActions>
] {
  const [newState, dispatch] = useReducer(
    (
      state: CNSVersionDetailsState,
      action: CNSVersionDetailsActions
    ): CNSVersionDetailsState => {
      switch (action.type) {
        case "CNSVERSION_DETAILS_EDITED": {
          const updatedDetails = {
            ...state.details,
            ...action.payload,
          };
          return {
            ...state,
            details: updatedDetails,
            isFormDirty: true,
            isLoaded: true,
          };
        }
        case "CNSVERSION_DETAILS_CREATED": {
          return {
            ...state,
            details: action.payload,
            isFormDirty: false,
            isLoaded: true,
          };
        }
        case "CNSVERSION_REQUEST_SENT": {
          return {
            ...state,
            isLoaded: false,
          };
        }
        case "CNSVERSION_RESPONSE_RECEIVED": {
          return {
            ...state,
            details: action.payload,
            isLoaded: true
          };
        }
        default:
          return state;
      }
    },
    initState
  );

  return [newState, dispatch];
}
