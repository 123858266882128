import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type InfoIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const InfoIcon: React.FC<InfoIconProps> = props => {
  const { color, decorative, label, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = `${iconSize ?? 22}px`;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 22 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <desc>Created with sketchtool.</desc>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-475.000000, -2763.000000)">
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(476.000000, 94.000000)">
                <g id="info">
                  <circle
                    stroke={color ?? "currentColor"}
                    strokeWidth="2"
                    strokeLinejoin="round"
                    cx="10"
                    cy="10"
                    r="10"
                  />
                  <path
                    d="M10,9 C10.5128358,9 10.9355072,9.38604019 10.9932723,9.88337887 L11,10 L11,14 C11,14.5522847 10.5522847,15 10,15 C9.48716416,15 9.06449284,14.6139598 9.00672773,14.1166211 L9,14 L9,10 C9,9.44771525 9.44771525,9 10,9 Z"
                    fill={color ?? "currentColor"}
                  />
                  <path
                    d="M9.29289322,5.29289322 C9.68341751,4.90236893 10.3165825,4.90236893 10.7071068,5.29289322 C11.0976311,5.68341751 11.0976311,6.31658249 10.7071068,6.70710678 C10.3165825,7.09763107 9.68341751,7.09763107 9.29289322,6.70710678 C8.90236893,6.31658249 8.90236893,5.68341751 9.29289322,5.29289322 Z"
                    fill={color ?? "currentColor"}
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default InfoIcon;
