import React, { useEffect, useState } from "react";
import { resources } from "api/resources";
import { methods } from "api/methods";
import { Ingredient } from "api/models";
import { useApi } from "api/useApi";
import { PageLoadStateProvider } from "components/common";
import IngredientsDetailsForm from "./IngredientsDetailsForm";
import IngredientsDetailsHeader from "./IngredientsDetailsHeader";
import { useParams } from "react-router-dom";

const IngredientsDetailsPage: React.FC = () => {
  const execute = useApi();
  const params = useParams();
  const [ingredientData, setIngredientData] = useState<Ingredient>();

  useEffect(() => {
    if (params.ingredientKey) {
      execute(resources.ingredient(params.ingredientKey), methods.GET)
        .then((response: Ingredient) => {
          setIngredientData(response);
        })
        .catch(() => {});
    } else {
      execute(resources.newIngredient, methods.GET)
        .then((response: Ingredient) => {
          if (!response.isInvalid) {
            setIngredientData(response);
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.ingredientKey]);

  return (
    <PageLoadStateProvider isLoaded={!!ingredientData?.translations}>
      <IngredientsDetailsHeader ingredient={ingredientData} />
      <IngredientsDetailsForm ingredient={ingredientData} />
    </PageLoadStateProvider>
  );
};

export default IngredientsDetailsPage;
