import { createThemedUseStylesWithParams } from "../../../../theme/index";
import { IconBoxProps } from "./IconBoxTypes";

export default createThemedUseStylesWithParams<IconBoxProps>((params, theme) => ({
  icon: {
    display: "flex",
    minHeight: params.customHeight ?? theme.scales.icon.medium, // fixed because icons svg sizes are fixed
    minWidth: params.customWidth ?? theme.scales.icon.medium, // fixed because icons svg sizes are fixed
    maxHeight: params.customHeight ?? theme.scales.icon.medium, // fixed because icons svg sizes are fixed
    maxWidth: params.customWidth ?? theme.scales.icon.medium, // fixed because icons svg sizes are fixed
    alignItems: "center",
    justifyContent: params.justifyContent ?? "center",
    transform: params.rotate ? `rotate(${params.rotate}deg)` : undefined
  }
}));
