import React, { useState, createRef } from "react";
import { useTheme } from "theme";
import Popper from "@mui/material/Popper";
import Icon from "components/common/Icon/index";
import BorderBox from "../BorderBox";
import { NamedInputWidth } from "../inputScales";
import { IconIndexKey } from "../Icon";
import useToolTipStyles from "./useToolTipStyles";

export type ToolTipAnchorPoint = "middleLeft" | "middleRight";

export type ToolTipProps = {
  children?: React.ReactNode;
  width?: number | NamedInputWidth;
  icon?: IconIndexKey;
  color?: string;
  parentRender?: () => JSX.Element;
};

const ToolTip: React.FC<ToolTipProps> = props => {
  const { width, icon, color, children, parentRender } = props;

  if (parentRender && icon) {
    throw new Error("You may specify the parent render or an icon; Not both.");
  }

  const node = createRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const styles = useToolTipStyles();

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const iconContent = (
    <div
      ref={node}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={() => setOpen(false)}
    >
      {!parentRender && (
        <Icon
          icon={icon ?? "info"}
          iconSize={theme.scales.icon.xxsmall + 1}
          color={color ?? theme.palette.primary.fsBlue}
        />
      )}
      {parentRender && parentRender()}
    </div>
  );
  return (
    <>
      {iconContent}
      <Popper
        open={open}
        anchorEl={anchorEl}
        className={styles.popover}
        placement="right"
      >
        <BorderBox treatment="float" width={width}>
          <div className={styles.tooltipContainer}>{children}</div>
        </BorderBox>
      </Popper>
    </>
  );
};

ToolTip.defaultProps = {
  width: undefined // responsive width
};

export default ToolTip;
