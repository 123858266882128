import React from "react";
import { HttpStatusCode, SiteError } from "api/models";
import { useTranslations } from "components/useTranslations";
import ErrorPage from "../ErrorPage";

const UnauthorizedPage: React.FC<{
  error?: SiteError;
  translations?: { [key: string]: string } | undefined;
}> = ({ translations }) => {
  const t = useTranslations(translations);

  return (
    <ErrorPage
      error={{
        httpStatus: HttpStatusCode.Forbidden,
        message: t`__NAVIGATION_UNAUTHORIZED_ACCESS`
      }}
    />
  );
};

export default UnauthorizedPage;
