import "./App.css";
import React from "react";
import Site from "components/Site";
import IdentityProvider from "identity";
import ConfigProvider from "config";
import { GlobalThemeProvider } from "theme";
import { ThemeProvider as MuiThemeProvider, theme as muiTheme } from "theme/MuiTheme";
import { BrowserRouter } from "react-router-dom";

const App: React.FC = () => {
  return (
    <div id="appContainer">
      <GlobalThemeProvider>
        <MuiThemeProvider theme={muiTheme}>
          <ConfigProvider>
            <IdentityProvider>
              <BrowserRouter future={{ v7_relativeSplatPath: true }}>
                <Site />
              </BrowserRouter>
            </IdentityProvider>
          </ConfigProvider>
        </MuiThemeProvider>
      </GlobalThemeProvider>
    </div>
  );
};

export default App;
