import { useContext } from "react";
import { ConfigContext } from "config";
import { IdentityContext } from "identity";
import { routes } from "routes";
import { methods } from "./methods";
import { HttpStatusCode, SiteDto } from "./models";
import { resources } from "./resources";
import { redirect } from "react-router";

function createApiUrl(
  base: string,
  path: string
): string {
  let resultUrl = base;
  if (!resultUrl.endsWith("/")) {
    resultUrl += "/";
  }

  resultUrl += path.startsWith("/") ? path.slice(1) : path;

  return resultUrl;
} 

export function useApi() {
  const config = useContext(ConfigContext);
  const baseUrl = config?.reactAppApiEndpoint ?? "";
  const identity = useContext(IdentityContext);

  const execute = (
    url: string,
    method: methods,
    body: any = null
  ): Promise<any> => {
    return identity.getAccessToken().then(token => {
      if (!token) {
        throw new Error("Token not found");
      }

      const headers = {
        "Access-Control-Allow-Origin": "*",
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`
      };
      let isOk = false;
      let responseStatus = 0;
      return fetch(createApiUrl(baseUrl, url), {
        method,
        headers,
        body: method === methods.GET ? null : JSON.stringify(body)
      })
        .then(response => {
          isOk = response.ok;
          responseStatus = response.status;
          const isJson = response.headers
            .get("content-type")
            ?.includes("application/json");
          if (isJson) {
            return response.json();
          }
          return Promise.resolve({});
        })
        .then(jsonData => {
          if (isOk) {
            return jsonData;
          }

          // jsonData.status will be 403 if unauthorized, update display accordingly
          if (responseStatus === 403) {
            if (url !== resources.site) {
              redirect(routes.unathorizedPage);
            } else {
              const siteError: SiteDto = {
                error: {
                  httpStatus: HttpStatusCode.Forbidden,
                  message: "You do not have permission to access this site."
                }
              };
              return siteError;
            }
          }

          throw new Error(jsonData.message as string);
        });
    });
  };

  return execute;
}
