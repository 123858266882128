import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

type MapPinIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const MapPinIcon: React.FC<MapPinIconProps> = props => {
  const { decorative, label, color, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = iconSize ?? 24;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        role="img"
        width={svgSize}
        height={svgSize}
        viewBox="0 0 20 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1142.000000, -2763.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(1143.000000, 94.000000)">
                <g>
                  <path d="M18,9.00000013 C18,16.0000001 9,22.0000001 9,22.0000001 C9,22.0000001 0,16.0000001 0,9.00000013 C0,4.0294373 4.0294373,1.34110451e-07 9,1.34110451e-07 C13.9705627,1.34110451e-07 18,4.0294373 18,9.00000013 L18,9.00000013 Z" />
                  <circle id="Oval" cx="9" cy="9" r="3" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default MapPinIcon;
