import React, { useContext } from "react";
import { PageLoadStateContext } from "../trackPageLoadState";
import { TextProps } from "./TextTypes";
import useTextStyles from "./useTextStyles";

const BodyText: React.FC<TextProps> = props => {
  const styles = useTextStyles(props);
  let textStyle = "";
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };

  switch (props.textStyle) {
    case "info-large":
      textStyle = styles.infoLarge;
      break;
    case "info-large-slim":
      textStyle = styles.infoLargeSlim;
      break;
    case "info-large-emphasis":
      textStyle = styles.infoLargeEmphasis;
      break;
    case "info-normal":
      textStyle = styles.infoNormal;
      break;
    case "info-normal-emphasis":
      textStyle = styles.infoNormalEmphasis;
      break;
    case "info-normal-strong-emphasis":
      textStyle = styles.infoNormalStrongEmphasis;
      break;
    case "label":
      textStyle = styles.label;
      break;
    case "label-sidenav":
      textStyle = styles.labelSideNav;
      break;
    case "color-label":
      textStyle = styles.colorLabel;
      break;
    case "header-one":
      textStyle = styles.headerOne;
      break;
    case "header-two":
      textStyle = styles.headerTwo;
      break;
    case "header-two-emphasis":
      textStyle = styles.headerTwoEmphasis;
      break;
    case "header-four":
      textStyle = styles.headerFour;
      break;
    case "header-four-emphasis":
      textStyle = styles.headerFourEmphasis;
      break;
    case "action":
      textStyle = styles.action;
      break;
    case "status-label":
      textStyle = styles.statusLabelNormal;
      break;
    case "status-label-emphasis":
      textStyle = styles.statusLabelEmphasis;
      break;
    case "none":
      textStyle = styles.none;
      break;
    case "top-nav-label":
      textStyle = styles.topNavLabel;
      break;
    default:
      break;
  }

  return (
    <span
      style={!pageLoadState.loaded ? {} : { color: props.color }}
      className={!pageLoadState.loaded ? styles.placeholder : textStyle}
    >
      {pageLoadState.loaded && props.children}
      {!pageLoadState.loaded && "Placeholder"}
    </span>
  );
};

export default BodyText;
