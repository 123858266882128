import React from "react";
import Text from "../index";
import { TextStyle } from "../TextTypes";
import { Header2Props } from "./Header2Types";
import useHeader2Styles from "./useHeader2Styles";

const Header2: React.FC<Header2Props> = props => {
  const headerStyle: TextStyle =
    props.fontWeight === "medium" ? "header-two-emphasis" : "header-two";
  const styles = useHeader2Styles(props);

  return (
    <div className={styles.h2} data-automation={props.qaAutomationLabel}>
      <Text textStyle={headerStyle}>{props.children}</Text>
    </div>
  );
};

export default Header2;
