import React from "react";
import Dialog, { DialogProps } from "@mui/material/Dialog";

export type ModalProps = {
  children: React.ReactNode;
  open?: boolean;
  disableEscapeKeyDown?: DialogProps["disableEscapeKeyDown"];
  onClose?: () => void;
  labelledById?: string;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: DialogProps["fullWidth"];
  scroll?: DialogProps["scroll"];
};

const Modal: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    open,
    children,
    disableEscapeKeyDown,
    labelledById,
    maxWidth,
    fullWidth,
    scroll,
    onClose,
  } = props;

  return (
    <Dialog
      open={open ?? false}
      disableEscapeKeyDown={disableEscapeKeyDown}
      onClose={onClose}
      aria-labelledby={labelledById}
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      scroll={scroll}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
