import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type FileIconProps = {
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const FilterIcon: React.FC<FileIconProps> = props => {
  const { decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="20px"
        height="19px"
        viewBox="0 0 20 19"
      >
        <title>{title}</title>
        <g
          id="UI-+-Component-Guide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Cargill-Unity-Design-Guide"
            transform="translate(-1227.000000, -3046.000000)"
            stroke="#149EC6"
            strokeWidth="1.8"
          >
            <g id="Icons" transform="translate(0.000000, 2880.000000)">
              <g
                id="Icons-/-Filter"
                transform="translate(1228.000000, 167.000000)"
              >
                <polygon points="18 -9.05941988e-14 0 -9.05941988e-14 7.2 8.514 7.2 14.4 10.8 16.2 10.8 8.514" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default FilterIcon;
