import React from "react";
import { useTheme } from "theme";
import { useTranslations } from "components/useTranslations";
import loading from "./cargill_loading.gif";
import useLoadingComponentStyles from "./useLoadingComponentStyles";

const LoadingIndicator: React.FC<{
  width?: number | string;
  height?: number | string;
  center?: boolean;
}> = props => {
  const styles = useLoadingComponentStyles();
  const t = useTranslations();
  const theme = useTheme();
  const {
    width = theme.scales.icon.medium,
    height = theme.scales.icon.medium
  } = props;

  return (
    <img
      src={loading}
      alt={t`Loading...`}
      className={props.center ? styles.loadingCenter : styles.loading}
      width={width}
      height={height}
    />
  );
};

export default LoadingIndicator;
