import { useReducer } from "react";

export type SideNavActions =
  | { type: "SUBMENU_CLOSE" }
  | { type: "SUBMENU_TOGGLE_DATA" };

export type SideNavSection = "home" | "data";

export type SideNavState = {
  openSection?: SideNavSection;
};

const initState: SideNavState = {
  openSection: undefined,
};

export default function useSideNavReducer(
  init?: SideNavState
): [SideNavState, React.Dispatch<SideNavActions>] {
  const [newState, dispatch] = useReducer(
    (state: SideNavState, action: SideNavActions): SideNavState => {
      switch (action.type) {
        case "SUBMENU_CLOSE":
          return {
            ...state,
            openSection: undefined,
          };
        case "SUBMENU_TOGGLE_DATA":
          return {
            ...state,
            openSection: state.openSection !== "data" ? "data" : undefined,
          };
        default:
          return state;
      }
    },
    init ?? initState
  );

  return [newState, dispatch];
}
