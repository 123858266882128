import { useReducer } from "react";
import { DropdownValue } from "api/models";
import { hashCodeOfString } from "api/tools";

// Reducer Types
export enum NutrientDetailsFormActionType {
  ON_PAGE_LOAD = "on-page-load",
  CHANGE_FORM_STATE = "change-form-state",
  CHANGE_UNIT = "change-unit"
}

export enum NutrientDetailsFormActionPayloadType {
  FORM_DETAILS = "formDetails",
  VALIDATION_DETAILS = "validationDetails"
}

export type FormDetails = {
  nutrientName?: string;
  nutrientCode?: string;
  nutrientUnit?: string;
  nutrientUnitKey?: string;
  numeratorNutrientKey?: string;
  denominatorNutrientKey?: string;
  nutrientPackages?: string[];
  nutrientOvsName?: string;
};

export type ValidationDetails = {
  nutrientNameValidationMessage?: string;
  nutrientCodeValidationMessage?: string;
  nutrientUnitValidationMessage?: string;
  nutrientNumeratorValidationMessage?: string;
  nutrientDenominatorValidationMessage?: string;
  nutrientOvsNameValidationMessage?: string;
};

export type NutrientDetailsFormState = {
  isFormDirty?: boolean;
  formDetails?: FormDetails;
  validationDetails?: ValidationDetails;
  key?: string;
  nutrientUnits?: DropdownValue[] | undefined;
  nutrientPackageOptions?: DropdownValue[] | undefined;
  stateHash?: string;
  nutrientOptions?: DropdownValue[] | undefined;
};

export type NutrientDetailsFormAction =
  | {
      type: NutrientDetailsFormActionType.ON_PAGE_LOAD;
      payload: NutrientDetailsFormState;
    }
  | {
      type: NutrientDetailsFormActionType.CHANGE_FORM_STATE;
      payload: {
        key: NutrientDetailsFormActionPayloadType;
        value: FormDetails | ValidationDetails;
      };
    }
  | {
      type: NutrientDetailsFormActionType.CHANGE_UNIT;
      payload: {
        key: NutrientDetailsFormActionPayloadType;
        value: FormDetails;
      };
    };

export const initState: NutrientDetailsFormState = {
  isFormDirty: false,
  formDetails: {
    nutrientName: "",
    nutrientCode: "",
    nutrientUnit: "",
    nutrientUnitKey: "",
    numeratorNutrientKey: "",
    denominatorNutrientKey: "",
    nutrientPackages: [],
    nutrientOvsName: ""
  },
  validationDetails: {
    nutrientNameValidationMessage: "",
    nutrientCodeValidationMessage: "",
    nutrientUnitValidationMessage: "",
    nutrientNumeratorValidationMessage: "",
    nutrientDenominatorValidationMessage: "",
    nutrientOvsNameValidationMessage: ""
  },
  key: "",
  nutrientUnits: [],
  nutrientPackageOptions: [],
  nutrientOptions: [],
  stateHash: ""
};

// Reducer Function Logic
function reducer(
  state: NutrientDetailsFormState,
  action: NutrientDetailsFormAction
) {
  switch (action.type) {
    case NutrientDetailsFormActionType.ON_PAGE_LOAD: {
      const initialLoadState = { ...state, ...action.payload };
      return {
        ...initialLoadState,
        isFormDirty: false,
        stateHash: hashCodeOfString(
          JSON.stringify(initialLoadState.formDetails)
        )
      };
    }
    case NutrientDetailsFormActionType.CHANGE_FORM_STATE:
    case NutrientDetailsFormActionType.CHANGE_UNIT: {
      const updatedDetails = {
        ...state,
        [action.payload.key]: action.payload.value
      };

      const hashCodeAfterChange = hashCodeOfString(
        JSON.stringify(updatedDetails.formDetails)
      );

      // This means that our current page render is no different than initial state, return isFormDirty: false
      if (hashCodeAfterChange === state.stateHash) {
        return {
          ...updatedDetails,
          isFormDirty: false
        };
      }

      return { ...updatedDetails, isFormDirty: true };
    }
    default:
      return state;
  }
}

// Component
export default function useNutrientDetailsFormReducer(
  init?: NutrientDetailsFormState
): [NutrientDetailsFormState, React.Dispatch<NutrientDetailsFormAction>] {
  const [newState, dispatch] = useReducer(reducer, init ?? initState);
  return [newState, dispatch];
}
