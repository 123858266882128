import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import {
  CountryOptionListItem,
  StateProvinceOption,
  Supplier,
  SupplierType,
  SupplierTypeKind
} from "api/models";
import {
  Header2,
  Layout,
  PrimaryButton,
  InputLabel,
  TextField,
  FormHelperText
} from "components/common";
import { useQuery } from "api/useQuery";
import { resources } from "api/resources";
import { methods } from "api/methods";
import { useApi } from "api/useApi";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/lab";
import { useTranslations } from "components/useTranslations";
import {
  Box,
  MenuItem,
  Select,
  Grid,
  FormControl,
  Paper,
  Snackbar,
  SnackbarCloseReason
} from "@mui/material";
import ConfirmationModal from "components/common/ConfirmationModal";
import useSupplierDetailFormStyles from "./useSupplierDetailFormStyles";

export type SuppliersDetailPageProps = {
  creatingNew: boolean;
  changeName: (name: string) => void;
  supplierTypeList?: SupplierType[] | undefined;
  supplier?: Supplier;
};

export interface SupplierForm extends Supplier {

  // validation related fields
  supplierNameValidationMessage?: string | undefined;
  codeValidationMessage?: string | undefined;
  cityValidationMessage?: string | undefined;
  stateProvinceValidationMessage?: string | undefined;
  countryValidationMessage?: string | undefined;
  isInvalid?: boolean;
  invalidMessage?: string | undefined;
}
interface AlertState {
  error: boolean;
  success: boolean;
}

const SupplierDetailForm: React.FC<SuppliersDetailPageProps> = (props) => {
  const styles = useSupplierDetailFormStyles();
  const execute = useApi();
  const { supplierTypeList, supplier, changeName } = props;
  const [editSupplier, setEditSupplier] = useState<SupplierForm>(supplier as SupplierForm);
  const t = useTranslations();
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  // State whether form busy
  const [busy, setBusy] = useState(false);

  // Returns values of existing supplier if editing, and default/empty values if creating new
  const getDefaultValues = () => {
    let defaultValue: SupplierForm;
    if (!props.creatingNew && editSupplier) {
      defaultValue = {
        key: editSupplier.key,
        supplierName: editSupplier.supplierName,
        code: editSupplier.code,
        type: editSupplier.type ? editSupplier.type : SupplierTypeKind.Supplier,
        city: editSupplier.city,
        stateProvinceKey: editSupplier.stateProvinceKey,
        countryKey: editSupplier.countryKey,
        supplierNameValidationMessage:
          editSupplier.supplierNameValidationMessage
            ? editSupplier.supplierNameValidationMessage
            : "",
        codeValidationMessage: editSupplier.codeValidationMessage
          ? editSupplier.codeValidationMessage
          : "",
        cityValidationMessage: editSupplier.cityValidationMessage
          ? editSupplier.cityValidationMessage
          : "",
        countryValidationMessage: editSupplier.countryValidationMessage
          ? editSupplier.countryValidationMessage
          : "",
        stateProvinceValidationMessage:
          editSupplier.stateProvinceValidationMessage
            ? editSupplier.stateProvinceValidationMessage
            : ""
      };
    } else {
      defaultValue = {
        key: "",
        supplierName: "",
        code: "",
        type: SupplierTypeKind.Supplier,
        city: "",
        stateProvinceKey: "",
        countryKey: "",
        supplierNameValidationMessage: "",
        codeValidationMessage: "",
        cityValidationMessage: "",
        countryValidationMessage: "",
        stateProvinceValidationMessage: ""
      };
    }
    return defaultValue;
  };

  const [stateOptions, setStateOptions] = React.useState<StateProvinceOption[]>(
    []
  );

  const {
    control,
    getValues,
    setValue,
    formState: { errors, isDirty },
    setError,
    trigger,
    handleSubmit,
    reset
  } = useForm<SupplierForm>({ defaultValues: getDefaultValues() });

  // on mounting retrieve values for selected supplier
  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSupplier]);

  useEffect(() => {
    setEditSupplier(supplier as SupplierForm);
  }, [supplier]);

  const [open, setOpen] = React.useState<AlertState>({
    error: false,
    success: false
  });

  let { data: countryOptions } = useQuery<CountryOptionListItem[]>(
    resources.listItem("countries")
  );

  const [countryStateMap, setCountryStateMap] = useState(
    new Map<string, StateProvinceOption[]>()
  );

  countryOptions = countryOptions ?? [];

  useEffect(() => {
    UpdateStates(editSupplier?.countryKey || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSupplier, countryStateMap]);

  useEffect(() => {
    if (countryOptions && countryOptions.length > 0) {
      countryOptions.forEach((country) => {
        setCountryStateMap(
          countryStateMap.set(country.countryKey, country.stateProvinceOptions)
        );
      });
      setCountryStateMap(new Map(countryStateMap));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryOptions]);

  function UpdateStates(countryKey: string) {
    setStateOptions(countryStateMap.get(countryKey) || []);
  }

  const route = props.creatingNew
    ? `${resources.suppliers}/create`
    : `${resources.suppliers}/edit/${props.supplier?.key}`;

  const httpMethod = props.creatingNew ? methods.POST : methods.PUT;

  // Save Button
  const onClick = () => {
    if (props.supplier?.key) {
      if (!confirmationModalIsOpen) setConfirmationModalIsOpen(true);
    } else {
      onSave();
    }
  };

  // Save Action
  const onSave = () => {
    trigger()
      .then((isValid) => {
        if (isValid) {
          handleSubmit((data) => {
            // TODO: refactor this using useCall methods
            execute(route, httpMethod, data)
              .then((response: SupplierForm) => {
                setBusy(false);
                if (response?.isInvalid) {
                  setOpen({
                    error: true,
                    success: false
                  });
                  if (response.supplierNameValidationMessage !== "") {
                    setError("supplierName", {
                      type: "custom",
                      message: response.supplierNameValidationMessage
                    });
                  }
                  if (response.codeValidationMessage !== "") {
                    setError("code", {
                      type: "custom",
                      message: response.codeValidationMessage
                    });
                  }
                  return;
                }
                setOpen({
                  error: false,
                  success: true
                });
                setEditSupplier(data);
                changeName(data.supplierName);
              })
              .catch((err: any) => {
                // eslint-disable-next-line no-console
                console.error(err);
              });
          })().catch((err: any) => {
            // eslint-disable-next-line no-console
            console.error(err);
            setBusy(false);
          });
        }
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setBusy(false);
      });
  };

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen({ error: false, success: false });
  };

  return (
    <div
      className={
        props.supplier?.key
          ? styles.masterContainerEdit
          : styles.masterContainerCreate
      }
    >
      <ConfirmationModal
        isOpen={confirmationModalIsOpen}
        setIsOpen={setConfirmationModalIsOpen}
        callback={onSave}
      />
      <Paper className={styles.pageContent}>
        <Layout layoutStyle="form-uniform">
          <form>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open.success}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <Alert elevation={6} variant="filled" severity="success">
                {t`__SUPPLIERS_DETAILS_SAVED`}
              </Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              open={open.error}
              autoHideDuration={2000}
              onClose={handleClose}
            >
              <Alert elevation={6} variant="filled" severity="error">
                <AlertTitle>{t`__SUPPLIERS_DETAILS_FAILED`}</AlertTitle>
              </Alert>
            </Snackbar>
            <Box>
              <Box
                display="flex"
                justifyContent="space-between"
                pb={2}
                alignContent="center"
                ml={1}
                mr={1}
              >
                <Header2>{t`__SUPPLIERS_DETAILS_TITLE_DETAILS`}</Header2>
                <PrimaryButton
                  name="saveIngredient"
                  disabled={!isDirty || busy}
                  onClick={onClick}
                >
                  {t`__SUPPLIERS_DETAILS_SAVE_BUTTON`}
                </PrimaryButton>
              </Box>
              <Grid container justifyContent="flex-start">
                <Grid item xs={9} md={6}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <TextField
                          {...passProps}
                          variant="outlined"
                          label={t`__SUPPLIERS_DETAILS_NAME`}
                          error={!!errors.supplierName}
                          fullWidth
                          helperText={
                            errors.supplierName
                              ? errors.supplierName.message
                              : ""
                          }
                          onBlur={() => {
                            trigger("supplierName").catch((err: any) => {
                              // eslint-disable-next-line no-console
                              console.error(err);
                            });
                          }}
                        />
                      );
                    }}
                    name="supplierName"
                    control={control}
                    rules={{
                      required: t`__SUPPLIERS_DETAILS_NAME_ERROR_MESSAGE`
                    }}
                  />
                </Grid>
                <Grid item xs={9} md={6}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <TextField
                          {...passProps}
                          variant="outlined"
                          label={t`__SUPPLIERS_DETAILS_CODE`}
                          error={!!errors.code}
                          helperText={errors.code ? errors.code.message : ""}
                          onBlur={() => {
                            trigger("code").catch((err: any) => {
                              // eslint-disable-next-line no-console
                              console.error(err);
                            });
                          }}
                          fullWidth
                        />
                      );
                    }}
                    name="code"
                    control={control}
                    rules={{
                      required: t`__SUPPLIERS_DETAILS_CODE_ERROR_MESSAGE`
                    }}
                  />
                </Grid>

                <Grid item xs={9} md={6}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="type-select-label">{t`__SUPPLIERS_DETAILS_TYPE`}</InputLabel>
                          <Select
                            {...passProps}
                            label={t`__SUPPLIERS_DETAILS_TYPE`}
                            labelId="type-select-label"
                            id="type-select"
                            error={!!errors.type}
                            variant="outlined"
                          >
                            {(supplierTypeList ?? []).map((supplierType) => (
                              <MenuItem
                                key={supplierType.key}
                                value={supplierType.key}
                              >
                                {supplierType.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={!!errors.type}>
                            {errors.type ? errors.type.message : ""}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                    name="type"
                    control={control}
                    rules={{
                      required: t`__SUPPLIERS_DETAILS_TYPE_ERROR_MESSAGE`
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="space-between">
                <Grid item xs={9} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel>{t`__SUPPLIERS_DETAILS_COUNTRY`}</InputLabel>
                          <Select
                            {...passProps}
                            label={t`__SUPPLIERS_DETAILS_COUNTRY`}
                            error={!!errors.countryKey}
                            onBlur={() => {
                              trigger("countryKey").catch((err: any) => {
                                // eslint-disable-next-line no-console
                                console.error(err);
                              });
                            }}
                            onChange={(event: any) => {
                              setValue("countryKey", event.target.value);
                              UpdateStates(event.target.value);
                            }}
                          >
                            {(countryOptions ?? []).map((c, index) => (
                              <MenuItem
                                value={c.countryKey}
                                key={`menu-item-${index.toString()}`}
                              >
                                {c.countryName}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText error={!!errors.countryKey}>
                            {errors.countryKey
                              ? errors.countryKey.message
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                    name="countryKey"
                    control={control}
                    rules={{
                      required: t`__SUPPLIERS_DETAILS_COUNTRY_ERROR_MESSAGE`
                    }}
                  />
                </Grid>
                <Grid item xs={9} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <TextField
                          {...passProps}
                          variant="outlined"
                          label={t`__SUPPLIERS_DETAILS_CITY`}
                          error={!!errors.city}
                          helperText={errors.city ? errors.city.message : ""}
                          onBlur={() => {
                            trigger("city").catch((err: any) => {
                              // eslint-disable-next-line no-console
                              console.error(err);
                            });
                          }}
                          fullWidth
                        />
                      );
                    }}
                    name="city"
                    control={control}
                    rules={{
                      required: t`__SUPPLIERS_DETAILS_CITY_ERROR_MESSAGE`
                    }}
                  />
                </Grid>
                <Grid item xs={9} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="state-select-label">
                            {t`__SUPPLIERS_DETAILS_STATE_PROVINCE`}
                          </InputLabel>
                          <Select
                            disabled={getValues("countryKey") === ""}
                            variant="outlined"
                            {...passProps}
                            label={t`__SUPPLIERS_DETAILS_STATE_PROVINCE`}
                            error={!!errors.stateProvinceKey}
                            onBlur={() => {
                              trigger("stateProvinceKey").catch(
                                (err: any) => {
                                  // eslint-disable-next-line no-console
                                  console.error(err);
                                }
                              );
                            }}
                            fullWidth
                          >
                            {stateOptions.map((s, index) => (
                              <MenuItem
                                value={s.stateProvinceKey}
                                key={`menu-item-${index.toString()}`}
                              >
                                {s.stateProvinceCode}
                              </MenuItem>
                            ))}
                          </Select>
                          <FormHelperText
                            error={!!errors.stateProvinceKey}
                          >
                            {errors.stateProvinceKey
                              ? errors.stateProvinceKey.message
                              : getValues("countryKey") === ""
                              ? t`__SUPPLIERS_DETAILS_SELECT_COUNTRY`
                              : ""}
                          </FormHelperText>
                        </FormControl>
                      );
                    }}
                    name="stateProvinceKey"
                    control={control}
                    rules={{
                      required: t`__SUPPLIERS_DETAILS_STATE_PROVINCE_ERROR_MESSAGE`
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </form>
        </Layout>
      </Paper>
    </div>
  );
};

export default SupplierDetailForm;
