import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type FileIconProps = {
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const BeakersIcon: React.FC<FileIconProps> = props => {
  const { decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="22px"
        height="19px"
        viewBox="0 0 22 19"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <g
          id="UI-+-Component-Guide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Cargill-Unity-Design-Guide"
            transform="translate(-1226.000000, -3084.000000)"
            stroke="#149EC6"
            strokeWidth="1.5"
          >
            <g id="Icons" transform="translate(0.000000, 2880.000000)">
              <g
                id="Ico_Beakers"
                transform="translate(1227.000000, 205.000000)"
              >
                <path
                  d="M18.7575758,-1.65201186e-13 L18.7575758,4 L16.9387273,4 L16.9394915,7.01914074 C18.7163067,7.73842244 19.969697,9.48041449 19.969697,11.5151515 C19.969697,14.1928958 17.7989564,16.3636364 15.1212121,16.3636364 C12.4434679,16.3636364 10.2727273,14.1928958 10.2727273,11.5151515 C10.2727273,9.48041449 11.5261175,7.73842244 13.3029327,7.01914074 L13.3027273,4 L11.4848485,4 L11.4848485,-1.65201186e-13 L18.7575758,-1.65201186e-13 Z"
                  id="Combined-Shape"
                />
                <path
                  d="M7.27272727,0 L7.27272727,4 L6.06,4 L6.06060606,13.9393939 C6.06060606,15.2782661 4.97523576,16.3636364 3.63636364,16.3636364 C2.29749152,16.3636364 1.21212121,15.2782661 1.21212121,13.9393939 L1.212,4 L6.61692923e-13,4 L6.61692923e-13,0 L7.27272727,0 Z"
                  id="Combined-Shape"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default BeakersIcon;
