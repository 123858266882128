import React from "react";
import { Header2 } from "components/common";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Ingredient } from "api/models";
import { Breadcrumbs, Typography, Link} from "@mui/material";
import { TranslationProvider } from "components/useTranslations";
import useIngredientsDetailsHeaderStyles from "./useIngredientsDetailsHeaderStyles";

export type IngredientsDetailsHeaderProps = {
  // Other props to come in future: id? etc.
  ingredient?: Ingredient;
};

const IngredientsDetailsHeader: React.FC<IngredientsDetailsHeaderProps> =
  props => {
    const styles = useIngredientsDetailsHeaderStyles();
    const { ingredient } = props;
    return (
      <TranslationProvider translations={ingredient?.translations}>
        {t => (
          <div className={styles.headerStyling}>
            {ingredient?.key ? (
              <Breadcrumbs separator={<ArrowForwardIcon />}>
                <Link color="inherit" href="/ingredients">
                  <Header2> {t`__INGREDIENT_DETAILS_TITLE_EXISTING`} </Header2>
                </Link>
                <Typography color="textPrimary" component="div">
                  <Header2>{ingredient?.name}</Header2>
                </Typography>
              </Breadcrumbs>
            ) : (
              <Header2>{t`__INGREDIENT_DETAILS_TITLE_NEW`}</Header2>
            )}
          </div>
        )}
      </TranslationProvider>
    );
  };

export default IngredientsDetailsHeader;
