import React from "react";
import useBaseButtonStyles from "./useBaseButtonStyles";

export type BaseButtonProps = {
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  disabled?: boolean | undefined;
  name: string;
  type?: "button" | "submit" | undefined;
};

const BaseButton: React.FC<BaseButtonProps> = props => {
  const { className, onClick, disabled, name, type } = props;
  const styles = useBaseButtonStyles();

  return (
    <button
      className={`${styles.button} ${className}`}
      disabled={disabled}
      onClick={onClick}
      name={name}
      type={type === "submit" ? "submit" : "button"}
    >
      {props.children}
    </button>
  );
};

export default BaseButton;
