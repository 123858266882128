import { createThemedUseStylesWithParams } from "theme";
import { BorderBoxProps, Treatment } from "./BorderBoxTypes";
import { NamedInputHeight, NamedInputWidth } from "../inputScales";

export default createThemedUseStylesWithParams<BorderBoxProps>(
  (params, theme) => {
    
    const getHeight = (height?: number | NamedInputHeight) => {
      if (typeof height === "number") {
        return height;
      }
      switch (height) {
        case "xsmall":
          return theme.scales.input.height.xsmall;
        case "small":
          return theme.scales.input.height.small;
        case "medium":
          return theme.scales.input.height.medium;
        case "large":
          return theme.scales.input.height.large;
        case "content":
          return "fit-content";
        case "full":
          return "100%";
        default:
          return theme.scales.input.height.medium;
      }
    };

    const getWidth = (width?: number | NamedInputWidth) => {
      if (typeof width === "number") {
        return width;
      }
      switch (width) {
        case "xsmall":
          return theme.scales.input.widths.xsmall;
        case "small":
          return theme.scales.input.widths.small;
        case "medium":
          return theme.scales.input.widths.medium;
        case "large":
          return theme.scales.input.widths.large;
        case "full":
          return "100%";
        case "content":
          return "fit-content";
        case "auto":
          return "auto";
        default:
          return theme.scales.input.widths.medium;
      }
    };

    const getBackgroundColor = (treatment?: Treatment) => {
      switch (treatment) {
        case "disabled":
          return theme.palette.grays.extraLight + theme.palette.opacity.alpha60;
        case "float":
          return theme.palette.white;
        case "float-transparent":
          return theme.palette.transparent;
        case "placeholder":
          return theme.palette.grays.light;
        case "none":
          return theme.palette.transparent;
        case "underline":
          return theme.palette.transparent;
        case "normal-transparent":
          return theme.palette.transparent;
        case "outline":
          return theme.palette.white;
        default:
          return theme.palette.grays.extraLight;
      }
    };

    const getBorder = (ptreatment?: Treatment) => {
      switch (ptreatment) {
        case "underline":
          return "0px solid";
        case "none":
          return "none";
        default:
          return "1px solid";
      }
    };

    const getBorderColor = (treatment?: Treatment) => {
      switch (treatment) {
        case "normal":
        case "normal-transparent":
          return theme.palette.grays.neutralCool;
        case "disabled":
          return (
            theme.palette.grays.neutralCool + theme.palette.opacity.alpha60
          );
        case "float":
          return theme.palette.grays.neutralCool;
        case "float-transparent":
          return theme.palette.grays.neutralCool;
        case "placeholder":
          return theme.palette.grays.light;
        case "invalid":
          return theme.palette.secondary.red;
        case "none":
          return theme.palette.transparent;
        default:
          return theme.palette.grays.neutralCool;
      }
    };
    
    return {
      borderBox: {
        minHeight: params.disableMinHeight ? "initial" : 0, // Constraining flex content within parent requires setting a min-height
        display: "flex",
        overflow: params.clip ? "hidden" : "",
        height: getHeight(params?.height),
        width: getWidth(params?.width),
        flexDirection: params.direction ?? "row",
        flexWrap: "nowrap",
        alignContent: "stretch",
        backgroundColor: getBackgroundColor(params?.treatment),
        border: getBorder(params?.treatment),
        borderColor: getBorderColor(params?.treatment),
        borderRadius: params.treatment === "placeholder" || params.treatment === "underline"
          ? 0
          : params.borderRadius ?? 4,
        padding: 0,
        boxSizing: "border-box",
        boxShadow: params.treatment === "float" || params.treatment === "float-transparent"
          ? theme.boxShadow
          : ""
      },
      highlight: {
        width: 4,
        backgroundColor: params.highlightColor ?? theme.palette.primary.fsBlue
      }
    };
  }
);
