import { CargillNutritionCloudLogo } from "components/common";
import React from "react";
import useTopNavStyles from "./useTopNavStyles";

type TopNavProps = {
  canEditTenantDefaultSettings?: boolean | undefined;
};

const TopNav: React.FC<TopNavProps> = () => {
  const styles = useTopNavStyles();

  return (
    <header className={styles.root}>
      <div className={styles.container}>
        <div className={styles.container}>
          <div className={styles.logo}>
            <CargillNutritionCloudLogo />
          </div>
          <div className={styles.separator} />
        </div>
        <div className={styles.dropdownContainer} />
      </div>
    </header>
  );
};

export default TopNav;
