import { Segment } from "api/models"

export type SegmentManagementModel = {
    segment: Segment,
    hasChanges: boolean
}


const segmentManagementReducer = (state: SegmentManagementModel, 
        action: { 
            type: string,
            segment?: Segment,
            newKeyName?: string,
            newSegmentName?: string,
            index?: number
        }) => {
    switch(action.type) {
        case 'init': {
            const { segment } = action;
            if (segment) {
                return {  
                    segment,
                    hasChanges: false,
                };
            }
            return state;
        }
        case 'save': {
            return { 
                ...state,
                hasChanges: false,
            };
        }
        case 'setSegmentName': {
            const { newSegmentName } = action;
            if (newSegmentName === undefined) {
                throw new Error('newSegmentName must be specified') 
            }
            return { ...state,
                segment: {
                    ...state.segment,
                    name: newSegmentName
                },
                hasChanges: true
            };
        }
        case 'setKey': {
            const { index, newKeyName } = action;
            if (index === undefined) {
                throw new Error('index must be specified') 
            }
            if (newKeyName === undefined) {
                throw new Error('newKeyName must be specified') 
            }
            return { ...state,
                segment: {
                    ...state.segment,
                    keys: state.segment.keys.map((k,i) => {
                        if (i === index) {
                            return newKeyName;
                        }
                        return k;
                    })
                },
                hasChanges: true
            };
        }
        case 'removeKey': {
            const { index } = action;
            if (index === undefined) {
                throw new Error('index must be specified') 
            }

            return { ...state, 
                segment: { 
                    ...state.segment, 
                    keys: state.segment.keys.filter((key, i) => i !== index) 
                },
                hasChanges: true, 
            }
        }
        case 'addKey': {
            return { ...state, 
                segment: { 
                    ...state.segment, 
                    keys: [...state.segment.keys, '']
                },
                hasChanges: true,
            }
        }
        default:
            throw new Error(`Unknown action ${action.type}`);
    }
}

export default segmentManagementReducer;