import { NotificationSeverityKind, TenantDetails } from "api/models";
import { useReducer } from "react";

export type TenantDetailsActions =
  | {
      type: "TENANT_DETAILS_LOADED";
      payload: TenantDetails;
    }
  | {
      type: "TENANT_SAVE_REQUEST_SENT";
    }
  | {
      type: "TENANT_SAVE_RESPONSE_RECEIVED";
      payload: TenantDetails;
    }
  | {
      type: "TENANT_INIT";
    }
  | {
      type: "TENANT_DETAILS_EDITED";
      payload: TenantDetails;
    };

// This contains Tenant class from api/models.ts
export type TenantDetailsState = {
  details: TenantDetails;
  isFormDirty?: boolean;
  isLoaded?: boolean;
};

// Initial value for reducer
const initState: TenantDetailsState = {
  details: {
    organizationKey: "",
    tenantOrgName: "",
    locationName: "",
    locationType: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    currency: "",
    measurementSystem: "",
    calcModels: [""],
    binSentryOrgId: "",
    binTracOrgId: "",
    nutrientPackages: [""],
    segments: [""],
    feedLineDisplayType: "",
    nutrientBasis: "",
    noFeedNotificationPreferences: [
      {
        disappearanceRate: "",
        hours: "",
        severity: NotificationSeverityKind.High,
        severityDisplayName: "High/Warning",
      },
      {
        disappearanceRate: "",
        hours: "",
        severity: NotificationSeverityKind.Medium,
        severityDisplayName: "Medium/Caution",
      },
    ],

    calcModelOptions: [],
    currencyOptions: [],
    locationTypeOptions: [],
    nutrientPackageOptions: [],
    segmentOptions: [],
    measurementSystemOptions: [],
    binSentryAccountOptions: [],
    binTracAccountOptions: [],
    feedLineDisplayTypeOptions: [],
    nutrientBasisOptions: [],
    canonicalId: "",
    displayCanonicalId: false,
  },
  isFormDirty: false,
  isLoaded: false,
};

export function useTenantDetailsReducer(
  init?: TenantDetailsState
): [TenantDetailsState, React.Dispatch<TenantDetailsActions>] {
  const [newState, dispatch] = useReducer(
    (
      state: TenantDetailsState,
      action: TenantDetailsActions
    ): TenantDetailsState => {
      switch (action.type) {
        case "TENANT_INIT": {
          return initState;
        }
        case "TENANT_SAVE_REQUEST_SENT": {
          return {
            ...state,
            isLoaded: false,
          };
        }
        case "TENANT_SAVE_RESPONSE_RECEIVED": {
          return {
            details: action.payload,
            isLoaded: true,
          };
        }
        case "TENANT_DETAILS_EDITED": {
          const updatedDetails = {
            ...state.details,
            ...action.payload,
          };
          return {
            ...state,
            isFormDirty: true,
            details: updatedDetails,
          };
        }
        case "TENANT_DETAILS_LOADED": {
          const loadedDetails = {
            ...initState.details,
            calcModelOptions: action.payload.calcModelOptions,
            currencyOptions: action.payload.currencyOptions,
            locationTypeOptions: action.payload.locationTypeOptions,
            nutrientPackageOptions: action.payload.nutrientPackageOptions,
            segmentOptions: action.payload.segmentOptions,
            measurementSystemOptions: action.payload.measurementSystemOptions,
            binSentryAccountOptions: action.payload.binSentryAccountOptions,
            binTracAccountOptions: action.payload.binTracAccountOptions,
            feedLineDisplayTypeOptions:
              action.payload.feedLineDisplayTypeOptions,
            nutrientBasisOptions: action.payload.nutrientBasisOptions,
            ...action.payload,
          };
          return {
            ...state,
            isFormDirty: false,
            isLoaded: true,
            details: loadedDetails,
          };
        }
        default:
          return state;
      }
    },
    init ?? initState
  );
  return [newState, dispatch];
}
