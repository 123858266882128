import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => {
  const button = {
    minHeight: "50px",
    borderRadius: 2,
    background: theme.palette.white,
    borderColor: theme.palette.primary.fsBlue,
    fontSize: "14px",
    lineHeight: "18px",
    cursor: "pointer",
    padding: `16px ${theme.scales.spacing.medium}px`,
    "&:hover:not(:disabled)": {
      backgroundColor: theme.palette.primary.fsBlueSecondary
    },
    "&:disabled": {
      borderColor: theme.palette.grays.neutralWarm,
      color: theme.palette.grays.neutralWarm,
      cursor: "default"
    },
    transition: `background ${theme.transitionTime}`,
    ...theme.font.semiBold
  };

  return {
    borderlessBlue: {
      ...button,
      color: theme.palette.primary.fsBlue,
      border: "none"
    },
    borderlessDarkBlue: {
      ...button,
      color: theme.palette.primary.fsDarkBlue,
      border: "none"
    },
    white: {
      ...button,
      color: theme.palette.white,
      backgroundColor: theme.palette.transparent,
      borderColor: theme.palette.white,
      borderWidth: 2,
      borderStyle: "solid",
      "&:hover:not(:disabled)": {
        backgroundColor: theme.palette.white + theme.palette.opacity.alpha30
      }
    },
    secondaryButton: {
      ...button,
      borderWidth: 1,
      borderStyle: "solid",
      minWidth: "190px"
    }
  };
});
