import React, { useContext } from 'react'
import { Button as MuiButton, ButtonProps } from '@mui/material';
import { PageLoadStateContext } from '../trackPageLoadState';

export const Button: React.FunctionComponent<ButtonProps> = (props) => {
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
    return (
        <MuiButton
            {...props}
            disabled = {!pageLoadState.loaded ? true: props.disabled}
        >
            {pageLoadState.loaded && props.children}
        </MuiButton>
    )
}