import React, { useContext } from "react"
import { PageLoadStateContext } from "../trackPageLoadState";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import dateInputTheme from "./dateInputTheme";
import { createThemedUseStyles } from "theme";

const useDateInputStyles = createThemedUseStyles(() => ({
  borderlessDatePickerInput: {
    "fieldset": {
      border: "none !important"
    }
  }
}));

export interface DateInputProps {
  value?: Date | null;
  onChange?: (value: Date | null) => void;
  format?: string;
  borderless?: boolean;
  readOnlyInput?: boolean;
}

export const DateInput: React.FunctionComponent<DateInputProps> = (props) => {
  const styles = useDateInputStyles();

  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
  return (
    <MuiThemeProvider theme={dateInputTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns} >
        <DatePicker
          onChange={(value) => props?.onChange && props.onChange(value)}
          value={pageLoadState.loaded ? props.value : null}
          format={props.format}
          slotProps={{textField: { size: "small" }, openPickerButton: { color: "primary" }, field: { readOnly: props.readOnlyInput }}}
          className={props.borderless ? styles.borderlessDatePickerInput : ""}
        />
      </LocalizationProvider>
    </MuiThemeProvider>
  )
}