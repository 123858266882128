import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  root: {
    alignItems: "center",
    boxShadow: theme.navDropShadow,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px"
  },

  dropdownContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.scales.spacing.medium
  },

  iconsContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row"
  },

  iconContainer: {
    margin: "0 12px"
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logo: {
    width: "100%",
    maxWidth: 95,
    height: "100%",
    paddingRight: theme.scales.spacing.medium
  },
  separator: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 1,
    height: theme.scales.input.height.medium,
    backgroundColor: theme.palette.grays.neutralCool
  }
}));
