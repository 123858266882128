import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      width: "100%",
      margin: theme.scales.spacing.normal
    }
  },
  pageContent: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    padding: "16px",
    margin: "20px auto",
    height: "85%",
    width: "75%"
  },
  masterContainerCreate: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },
  masterContainerEdit: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.grays.light,
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },
  spaceChildrenApart: {
    display: "flex",
    justifyContent: "space-between"
  },
  input: {
    // Material UI edge case
    minWidth: "100%"
  },
  dropdown: {
    height: "40px",
    // Material UI Edge Case
    minWidth: "100%"
  },
  dropdownContainer: {
    // Material UI Edge Case
    marginLeft: theme.scales.spacing.normal
  },
  label: {
    marginBottom: theme.scales.spacing.normal
  },
  rowOfInputs: {
    marginTop: "16px"
  }
}));
