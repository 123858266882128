import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ProfileIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   *  Width and Height of the Icon  icon = 20px and image = 40px. Having one property ensures width and height are always equal - necessary for circle styling
   */
  dimension?: "20px" | "40px" | "60px" | "80px";
};

const ProfileIcon: React.FC<ProfileIconProps> = props => {
  const { decorative, label, color, dimension } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width={dimension}
        height={dimension}
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <defs>
          <circle id="path-1" cx="9" cy="9" r="9" />
        </defs>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-727.000000, -2801.000000)">
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(728.000000, 132.000000)">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1" />
                </mask>
                <use
                  stroke={color ?? "currentColor"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  xlinkHref="#path-1"
                />
                <g
                  id="user"
                  mask="url(#mask-2)"
                  stroke={color ?? "currentColor"}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                >
                  <g transform="translate(2.454545, 3.354545)">
                    <path
                      d="M13.0909091,14.8272727 L13.0909091,13.1909091 C13.0909091,11.3834317 11.6256592,9.91818182 9.81818182,9.91818182 L3.27272727,9.91818182 C1.46524991,9.91818182 0,11.3834317 0,13.1909091 L0,14.8272727"
                      id="Path"
                    />
                    <circle cx="6.54545455" cy="3.54545455" r="2.7" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

ProfileIcon.defaultProps = {
  dimension: "20px"
};

export default ProfileIcon;
