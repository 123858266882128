import * as React from "react";
import { useTranslations } from "components/useTranslations";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams
} from "@mui/x-data-grid-pro";
import {
  BorderBox,
  Header2,
  Layout,
  LinkButton,
  SecondaryButton,
  PageLoadStateProvider,
  AppTypography
} from "components/common/";
import { useQuery } from "api/useQuery";
import { ReleaseNotesList } from "api/models";
import { resources } from "api/resources";
import { routes } from "routes";
import useReleaseNotesPageStyles from "./useReleaseNotesPageStyles";
import { useNavigate } from "react-router-dom";

const ReleaseNotesPage: React.FC = () => {
  const { data: ReleaseNotes } = useQuery<ReleaseNotesList>(
    resources.releaseNotes
  );
  const styles = useReleaseNotesPageStyles();
  const t = useTranslations(ReleaseNotes?.translations);
  const releaseNotesList = ReleaseNotes?.releaseNotes ?? [];
  const navigateTo = useNavigate();
  const renderEditButton = (params: GridCellParams) => {
    return (
      <LinkButton
        label={t`__RELEASE_NOTES_INITIAL_VIEW_VIEW_BUTTON`}
        onClick={() => navigateTo(`/release-notes/edit/${params.row.id}`)}
      />
    );
  };

  // setup data grid
  const columns: GridColDef[] = [
    {
      field: "version",
      headerName: t`__RELEASE_NOTES_INITIAL_VIEW_NAME_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__RELEASE_NOTES_INITIAL_VIEW_NAME_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "releaseDate",
      headerName: t`__RELEASE_NOTES_INITIAL_VIEW_DATE_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__RELEASE_NOTES_INITIAL_VIEW_DATE_COLUMN`}</strong>
        </AppTypography>
      ),
    },
    {
      field: "edit",
      headerName: " ",
      renderCell: renderEditButton,
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      flex: 0.6,
      display: "flex"
    }
  ];

  const rows: GridRowsProp = releaseNotesList.map<GridRowModel>(version => ({
    id: version.key,
    version: version.version,
    releaseDate: version.releaseDate
  }));

  return (
    <PageLoadStateProvider isLoaded={!!ReleaseNotes?.translations}>
      <BorderBox width="full" height="full">
        <Layout layoutStyle="page">
          <div className={styles.headerStyling}>
            <Header2>{t`__RELEASE_NOTES_INITIAL_VIEW_TITLE`}</Header2>
            <SecondaryButton
              name="createReleaseNotes"
              onClick={() => navigateTo(routes.releaseNotesCreate)}
            >
              {t`__RELEASE_NOTES_INITIAL_VIEW_CREATE_BUTTON`}
            </SecondaryButton>
          </div>
          <div style={{ height: "75vh", width: "100%", marginTop: 10 }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGridPro
                  rows={rows}
                  columns={columns}
                  checkboxSelection={false}
                  sortingOrder={["asc", "desc"]}
                  hideFooter
                />
              </div>
            </div>
          </div>
        </Layout>
      </BorderBox>
    </PageLoadStateProvider>
  );
};

export default ReleaseNotesPage;
