import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ListIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const ListIcon: React.FC<ListIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="20px"
        height="14px"
        role="img"
        viewBox="0 0 20 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-19.000000, -207.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.5"
          >
            <g transform="translate(0.000000, 70.000000)">
              <g>
                <g>
                  <g transform="translate(20.000000, 137.000000)">
                    <line x1="5" y1="1" x2="18" y2="1" id="Path" />
                    <line x1="5" y1="7" x2="18" y2="7" id="Path" />
                    <line x1="5" y1="13" x2="18" y2="13" id="Path" />
                    <line x1="0" y1="1" x2="1" y2="1" id="Path" />
                    <line x1="0" y1="7" x2="1" y2="7" id="Path" />
                    <line x1="0" y1="13" x2="1" y2="13" id="Path" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default ListIcon;
