import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => ({
  textBoxContainer: {
    width: "80%",
    "@media (max-width: 768px)": {
      width: "100%"
    }
  },
  dateTimeContainer: {
    display: "flex",
    flexDirection: "row"
  },
  actionButtonsContainer: {
    display: "flex",
    flexDirection: "row",
    leftMargin: 120,
    "@media (max-width: 768px)": {
      display: "flex",
      width: "100%",
      flexDirection: "column-reverse"
    }
  },
  fieldContainer: {
    display: "flex"
  },
  assignedVersionsCell: {
    display: "flex",
    alignItems: "center"
  },
  tooltipTitle: {
    whiteSpace: "pre-line"
  },
  dataGridContainer: {
    height: "70vh",
    width: "100%",
    marginTop: 10
  },
  dataGridContainerContent: {
    display: "flex",
    height: "100%"
  },
  dataGridContainerContentChild: {
    flexGrow: 1
  },
  isSavingLoader: {
    display: "inline",
    float: "left"
  }

}));
