import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type StatusIndicatorIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
};

const SortIcon: React.FC<StatusIndicatorIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="10px"
        height="10px"
        viewBox="0 0 10 10"
        version="1.1"
        className={props.className}
      >
        {title}
        <g
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-76.000000, -2769.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(77.000000, 100.000000)">
                <g transform="translate(4.000000, 4.000000) rotate(-180.000000) translate(-4.000000, -4.000000) translate(0.000000, -0.000000)">
                  <line x1="4" y1="8" x2="4" y2="0" id="Path" />
                  <polyline id="Path" points="0 4 4 0 8 4" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default SortIcon;
