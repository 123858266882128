import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ArrowIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
};

const ArrowIcon: React.FC<ArrowIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width="14px"
        height="14px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          id="UI-+-Component-Guide"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            id="Cargill-Unity-Design-Guide"
            transform="translate(-941.000000, -2766.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.5"
          >
            <g id="Icons" transform="translate(0.000000, 2670.000000)">
              <g
                id="Icons-/-Arrow"
                transform="translate(942.000000, 97.000000)"
              >
                <g id="corner-down-right">
                  <polyline id="Path" points="8.25 4.5 12 8.25 8.25 12" />
                  <path
                    d="M0,0 L0,5.25 C0,6.90685425 1.34314575,8.25 3,8.25 L12,8.25"
                    id="Path"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default ArrowIcon;
