import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  pageContent: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    padding: "16px",
    margin: "24px auto",
    height: "90%",
    width: "75%"
  },
  masterContainerCreate: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
    display: "flex",
    justifyContent: "center"
  },
  masterContainerEdit: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.grays.light,
    display: "flex",
    justifyContent: "center"
  },
  spaceChildrenApart: {
    display: "flex",
    justifyContent: "space-between"
  },
  input: {
    // Material UI edge case
    minWidth: "100%"
  },
  label: {
    marginBottom: theme.scales.spacing.normal
  },
  rowOfInputs: {
    marginTop: "16px"
  }
}));
