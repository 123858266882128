import React, { useContext } from 'react'
import { MenuItem as MuiMenuItem, MenuItemProps } from '@mui/material';
import { PageLoadStateContext } from '../trackPageLoadState';

export const MenuItem: React.FunctionComponent<MenuItemProps> = (props) => {
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
    return (
        <MuiMenuItem
            value={props.value}
            key={props.key}
        >
            {pageLoadState.loaded && props.children}
        </MuiMenuItem>
    )
}