import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type BinIconProps = {
  color?: string;
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const BinIcon: React.FC<BinIconProps> = props => {
  const { decorative, label, color, iconSize = 30 } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const strokeWidth = 2;
  const height = iconSize;
  const width = (iconSize * 2) / 3;
  const leftPoint = strokeWidth;
  const rightPoint = leftPoint + width;
  const arcHeight = height / 6;
  const middleHeight = (height + arcHeight) / 2;
  const middlePoint = (strokeWidth * 2 + width) / 2;

  return (
    <IconBox justifyContent="flex-start">
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width={iconSize}
        height={iconSize}
        viewBox={`0 0 ${iconSize} ${iconSize}`}
        version="1.1"
      >
        {title}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-976.000000, -3078.000000)">
            <g transform="translate(0.000000, 2880.000000)">
              <g transform="translate(986.000000, 213.000000) rotate(-360.000000) translate(-986.000000, -213.000000) translate(977.000000, 199.000000)">
                <path
                  d={`
                  M ${leftPoint} ${arcHeight},
                  A 2 1 0 0 1 ${rightPoint} ${arcHeight},
                  Z`}
                  stroke={color}
                  strokeWidth={strokeWidth * 0.9}
                  id="arc"
                />
                <line
                  x1={leftPoint}
                  y1={arcHeight}
                  x2={leftPoint}
                  y2={height}
                  stroke={color}
                  strokeWidth={strokeWidth}
                />
                <line
                  x1={rightPoint}
                  y1={arcHeight}
                  x2={rightPoint}
                  y2={height}
                  stroke={color}
                  strokeWidth={strokeWidth}
                />
                <path
                  d={`
                  M ${leftPoint + strokeWidth / 2} ${middleHeight},
                  L ${rightPoint - strokeWidth / 2} ${middleHeight},
                  L ${middlePoint} ${middleHeight * 1.35},
                  L ${middlePoint - iconSize * 0.1} ${middleHeight * 1.35},
                  L ${middlePoint + iconSize * 0.1} ${middleHeight * 1.35},
                  L ${middlePoint} ${middleHeight * 1.35},
                  Z`}
                  stroke={color}
                  strokeWidth={strokeWidth * 0.8}
                  id="triangle"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default BinIcon;
