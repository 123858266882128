import React, { useContext } from 'react'
import { TableCell as MuiTableCell, TableCellProps } from '@mui/material';
import { PageLoadStateContext } from '../trackPageLoadState';

export const TableCell: React.FunctionComponent<TableCellProps> = (props) => {
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
    return (
        <MuiTableCell {...props}>
            {pageLoadState.loaded && props.children}
        </MuiTableCell>
    )
}