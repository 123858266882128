import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ToolIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const ToolIcon: React.FC<ToolIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        role="img"
        width="22px"
        height="23px"
        viewBox="0 0 22 23"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-18.000000, -143.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.5"
          >
            <g transform="translate(0.000000, 70.000000)">
              <g>
                <g>
                  <g transform="translate(19.000000, 74.000000)">
                    <path d="M18.3226336,10.4410512 C20.1260049,8.63679803 20.4743334,5.9242454 19.3720282,3.76990012 L16.4522422,6.68086768 L14.1030093,6.68086768 L14.1030093,4.20464929 L16.9504841,1.28398144 C14.7970207,0.182558083 12.056249,0.53176837 10.2528777,2.33425783 C8.45038827,4.13674729 8.08265926,6.84136332 9.18496446,8.99482675 L0.519081918,17.6536545 C-0.16963837,18.341493 -0.174929435,19.4587895 0.514672697,20.146628 C1.20339298,20.8362301 2.318044,20.8362301 3.00676429,20.146628 L11.6629465,11.488682 C13.8172918,12.5909872 16.521026,12.2426588 18.3226336,10.4410512 L18.3226336,10.4410512 Z" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default ToolIcon;
