import React, { useEffect } from "react";
import { useTranslations } from "components/useTranslations";
import { Grid, MenuItem, Paper, Select, Snackbar, SnackbarCloseReason } from "@mui/material";
import {
  FormHelperText,
  Header2,
  PrimaryButton,
  TextField,
} from "components/common";
import { methods } from "api/methods";
import { resources } from "api/resources";
import { useApi } from "api/useApi";
import Alert from "@mui/material/Alert";
import { CNSVersionDetails } from "api/models";
import useCNSVersionDetailsPageFormStyles from "./useCNSVersionDetailsPageFormStyles";
import useCNSVersionDetailsFormReducer from "./useCNSVersionDetailsFormReducer";
import { CNSVersionDetailsFormProps } from "./CNSVersionDetailsFormTypes";

const CNSVersionDetailsForm: React.FC<CNSVersionDetailsFormProps> = (props) => {
  const styles = useCNSVersionDetailsPageFormStyles();
  const execute = useApi();
  const [state, dispatch] = useCNSVersionDetailsFormReducer();

  useEffect(() => {
    if (!state.details) {
          dispatch({ type: "CNSVERSION_RESPONSE_RECEIVED", payload: props.pageData });
    }
  }, [props.pageData, dispatch, state.details]);

  const t = useTranslations();
  
  // State whether form successful
  const [success, setSuccess] = React.useState(false);

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  // BUTTON FUNCTIONALITY
  const onClick = () => {
    const http_request_message = {
      ...state.details
    };
    dispatch({ type: "CNSVERSION_REQUEST_SENT" });
    execute(
      `${resources.CNSVersions}/create`,
      methods.POST,
      http_request_message
    )
      .then((response: any) => {
        if (!response.isInvalid) {
          setSuccess(true);
          dispatch({
            type: "CNSVERSION_DETAILS_CREATED",
            payload: response as CNSVersionDetails
          });
          return;
        }
        setSuccess(false);
        dispatch({
          type: "CNSVERSION_DETAILS_EDITED",
          payload: {
            ...state?.details,
            versionValidationMessage: response.versionValidationMessage,
            nameValidationMessage: response.nameValidationMessage
          },
        });
      })
      .catch(() => {});
  };

  return (
    // 1) div = blue container
    // 2) Paper = container for form
    <div className={styles.masterContainerCreate}>
      <Paper className={styles.pageContent}>
        <form className={styles.form}>
          {/* First Row: form heading */}
          <Grid container className={styles.headerRow}>
            <Grid item xs={8}>
              <Header2>{t`__CNSVERSIONS_DETAILS_SUBTITLE`}</Header2>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={success}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert onClose={handleClose} severity="success" elevation={6} variant="filled">
                  {t`__CNSVERSIONS_DETAILS_SAVED`}
                </Alert>
              </Snackbar>
            </Grid>
            <Grid item xs={4} className={styles.buttonContainer}>
              <PrimaryButton
                onClick={onClick}
                name="saveCNS"
                disabled={!state?.isFormDirty || !state?.isLoaded}
              >
                {t`__CNSVERSIONS_DETAILS_SAVE`}
              </PrimaryButton>
            </Grid>
          </Grid>
          {/* Second Row: name, code */}
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                value="CNS"
                className={styles.input}
                size="small"
                variant="outlined"
                label={t`__CNSVERSIONS_DETAILS_NAME`}
                disabled
              />
            </Grid>
            <Grid item xs={6} md={6}>
              <Select 
                value={state.details?.version ?? ""}
                variant="outlined"
                className={styles.dropdown}
                labelId="cns-verions-select-label"
                id="cns-verions-select"
                error={
                  state?.details?.versionValidationMessage !== undefined &&
                  state?.details?.versionValidationMessage !== null &&
                  state?.details?.versionValidationMessage !== ""
                }
                MenuProps={{ classes: { paper: styles.menuPaper } }}
                onChange={(value) => {
                  dispatch({
                    type: "CNSVERSION_DETAILS_EDITED",
                    payload: {
                      ...state?.details,
                      version: value.target.value,
                    },
                  });
                }}
              >
                {state?.details?.availableCnsVersions?.map((cnsVersion) => {
                  return (
                    <MenuItem key={cnsVersion} value={cnsVersion}>
                      {cnsVersion}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText
                variant="outlined"
                error={
                  state?.details?.versionValidationMessage !== undefined &&
                  state?.details?.versionValidationMessage !== null &&
                  state?.details?.versionValidationMessage !== ""
                }
              >
                {state?.details?.versionValidationMessage}
              </FormHelperText>
            </Grid>
          </Grid>
          {/* Third Row */}
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <TextField
                value={state?.details?.releaseDate ?? ""}
                className={styles.input}
                size="small"
                variant="outlined"
                label={t`__CNSVERSIONS_DETAILS_RELEASE_DATE`}
                disabled
              />
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default CNSVersionDetailsForm;
