import React from "react";
import CheckmarkIcon from "./CheckmarkIcon";
import CloseIcon from "./CloseIcon";
import ExternalLinkIcon from "./ExternalLinkIcon";
import HomeIcon from "./HomeIcon";
import LightningBoltIcon from "./LightningBoltIcon";
import ListIcon from "./ListIcon";
import SearchIcon from "./SearchIcon";
import SmallCaretIcon from "./SmallCaretIcon";
import ToolIcon from "./ToolIcon";
import ProfileIcon from "./ProfileIcon";
import SettingsIcon from "./SettingsIcon";
import HelpIcon from "./HelpIcon";
import ChevronIcon from "./ChevronIcon";
import ArrowIcon from "./ArrowIcon";
import AlertIcon from "./AlertIcon";
import InfoIcon from "./InfoIcon";
import FlagIcon from "./FlagIcon";
import EditIcon from "./EditIcon";
import TrashIcon from "./TrashIcon";
import DocumentIcon from "./DocumentIcon";
import DollarSignIcon from "./DollarSignIcon";
import FileIcon from "./FileIcon";
import PlusCircleIcon from "./PlusCircleIcon";
import FolderPlusIcon from "./FolderPlusIcon";
import FolderMinusIcon from "./FolderMinusIcon";
import MapPinIcon from "./MapPinIcon";
import CaretUpIcon from "./CaretUpIcon";
import StatusIndicatorIcon from "./StatusIndicatorIcon";
import ShareIcon from "./ShareIcon";
import SortIcon from "./SortIcon";
import PagingIcon from "./PagingIcon";
import CopyIcon from "./CopyIcon";
import RefreshIcon from "./RefreshIcon";
import ParametricIcon from "./ParametricIcon";
import InternationalCurrencyIcon from "./InternationalCurrencyIcon";
import BinFillMediumIcon from "./BinFillIcons/BinFillMediumIcon";
import BinFillHighIcon from "./BinFillIcons/BinFillHighIcon";
import BinFillLowIcon from "./BinFillIcons/BinFillLowIcon";
import SensorIcon from "./SensorIcon";
import LinkIcon from "./LinkIcon";
import LockIcon from "./LockIcon";
import CheckCircleIcon from "./CheckCircle";
import BinIcon from "./BinIcon";
import FilterIcon from "./FilterIcon";
import BeakersIcon from "./BeakersIcon";
import ClockIcon from "./ClockIcon";

/**
 * The <Icon/> element can be used instead of directly referencing specific icons.
 * This makes it easier to "browse" icons since you don't have to know the icons name
 * you can use intellisense to find what you are looking for.
 */

export const iconIndex = {
  caret: SmallCaretIcon,
  checkmark: CheckmarkIcon,
  close: CloseIcon,
  externalLink: ExternalLinkIcon,
  home: HomeIcon,
  lightningBolt: LightningBoltIcon,
  list: ListIcon,
  search: SearchIcon,
  tool: ToolIcon,
  profile: ProfileIcon,
  settings: SettingsIcon,
  help: HelpIcon,
  chevron: ChevronIcon,
  arrow: ArrowIcon,
  alert: AlertIcon,
  info: InfoIcon,
  flag: FlagIcon,
  edit: EditIcon,
  trash: TrashIcon,
  document: DocumentIcon,
  dollarSign: DollarSignIcon,
  file: FileIcon,
  plusCircle: PlusCircleIcon,
  folderPlus: FolderPlusIcon,
  folderMinus: FolderMinusIcon,
  mapPin: MapPinIcon,
  caretUp: CaretUpIcon,
  statusIndicator: StatusIndicatorIcon,
  share: ShareIcon,
  sort: SortIcon,
  paging: PagingIcon,
  copy: CopyIcon,
  refresh: RefreshIcon,
  parametric: ParametricIcon,
  internationalCurrency: InternationalCurrencyIcon,
  bin: BinIcon,
  binFillMedium: BinFillMediumIcon,
  binFillHigh: BinFillHighIcon,
  binFillLow: BinFillLowIcon,
  sensorIcon: SensorIcon,
  link: LinkIcon,
  lock: LockIcon,
  filter: FilterIcon,
  beakers: BeakersIcon,
  checkCircle: CheckCircleIcon,
  clockIcon: ClockIcon
};

export type IconIndexKey = keyof typeof iconIndex;

type ComponentProps<T> = T extends React.FC<infer P> ? P : never;

export type NamedIconProps<N, I extends N> = {
  icon: I;
  props: ComponentProps<I>;
};

function createNamedIcon<T>(index: T) {
  return <I extends keyof T>(props: { icon: I } & ComponentProps<T[I]>) => {
    const NamedIcon = (index as any)[props.icon];
    const newProps = { ...(props as any), icon: undefined };
    return <NamedIcon {...newProps} />;
  };
}

const Icon = createNamedIcon(iconIndex);

export default Icon;
