import React from "react";
import { TranslationProvider } from "components/useTranslations";
import { Grid } from "@mui/material";
import {
  BorderBox,
  Header2,
  Layout,
  PageLoadStateProvider
} from "components/common";
import { resources } from "api/resources";
import { useQuery } from "api/useQuery";
import { NutrientsList } from "api/models";
import NutrientsTable from "./NutrientsTable";
import NutrientsAdditionButton from "./NutrientsAddButton";
import useNutrientsPageStyles from "./useNutrientsPageStyles";

const NutrientsPage: React.FC = () => {
  const { data: response, isLoading: nutrientsLoading } =
    useQuery<NutrientsList>(resources.nutrients);
  const styles = useNutrientsPageStyles();

  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <TranslationProvider translations={response?.translations}>
        {t => (
          <BorderBox width="full" height="full">
            <Layout layoutStyle="page">
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.headerStyling}>
                    <Header2>{t`__NUTRIENTS_INITIAL_VIEW_TITLE`}</Header2>
                    <NutrientsAdditionButton
                      translations={response?.translations}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <NutrientsTable
                    nutrients={response?.nutrients ?? []}
                    nutrientsLoading={nutrientsLoading}
                    translations={response?.translations}
                  />
                </Grid>
              </Grid>
            </Layout>
          </BorderBox>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default NutrientsPage;
