import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  button: {
    backgroundColor: theme.palette.transparent,
    border: "none",
    cursor: "pointer",
    padding: 0
  }
}));
