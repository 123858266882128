import React from "react";
import { useTranslations } from "components/useTranslations";
import { Header2 } from "components/common";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Nutrient } from "api/models";
import { Breadcrumbs, Link, Typography } from "@mui/material";
import useNutrientsDetailsHeaderStyles from "./useNutrientsDetailsHeaderStyles";

export type NutrientsDetailsHeaderProps = {
  nutrient?: Nutrient | undefined;
};

const NutrientsDetailsHeader: React.FC<NutrientsDetailsHeaderProps> = props => {
  const styles = useNutrientsDetailsHeaderStyles();
  const { nutrient } = props;
  const t = useTranslations();
  return (
    <div className={styles.headerStyling}>
      {nutrient?.key ? (
        <Breadcrumbs separator={<ArrowForwardIcon />}>
          <Link color="inherit" href="/nutrients">
            <Header2>{t`__NUTRIENT_DETAILS_TITLE_EXISTING`}</Header2>
          </Link>
          <Typography color="textPrimary" component="div">
            <Header2>{nutrient?.name}</Header2>
          </Typography>
        </Breadcrumbs>
      ) : (
        <Header2>{t`__NUTRIENT_DETAILS_TITLE_NEW`}</Header2>
      )}
    </div>
  );
};

export default NutrientsDetailsHeader;
