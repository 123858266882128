import React, { useState, useEffect } from "react";

export type Config = {
  reactAppApiEndpoint?: string;
  reactAppAuthProviderAuthority?: string;
  reactAppAuthProviderClientId?: string;
  reactAppAuthProviderScope?: string;
  reactAppAuthProviderRedirectUrl?: string;
  reactAppEnablePseudoTranslations?: boolean;
  materialUiProLicenseKey?: string;
};

export const ConfigContext = React.createContext<Config | null>(null);

const ConfigProvider: React.FC<{ children: React.ReactNode }> = props => {
  const [config, setConfig] = useState<Config | null>(null);
  useEffect(() => {
    fetch("/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(res => res.json().then((data: Config) => setConfig(data)))
      .catch(() => {});
  }, []);

  if (!config) {
    return null;
  }

  return (
    <ConfigContext.Provider value={config}>
      {props.children}
    </ConfigContext.Provider>
  );
};

export default ConfigProvider;
