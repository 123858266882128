import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type SensorIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const SensorIcon: React.FC<SensorIconProps> = props => {
  const { decorative, label } = props;
  const color = props.color ?? "#85929C";

  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = props.iconSize ?? "13px";

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 13 13"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1058.000000, -3049.000000)">
            <g transform="translate(0.000000, 2880.000000)">
              <g transform="translate(1058.000000, 170.000000)">
                <path
                  d="M0.923076923,4.84615385 C4.36423575,4.84615385 7.15384615,7.63576425 7.15384615,11.0769231"
                  stroke={color}
                  strokeWidth="1.29230769"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M0.923076923,0 C7.04069261,0 12,4.95930739 12,11.0769231"
                  stroke={color}
                  strokeWidth="1.29230769"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.61538462,9.23076923 C0.978132981,9.23076923 0.461538462,9.74736375 0.461538462,10.3846154 C0.461538462,11.021867 0.978132981,11.5384615 1.61538462,11.5384615 C2.25263625,11.5384615 2.76923077,11.021867 2.76923077,10.3846154 C2.76923077,9.74736375 2.25263625,9.23076923 1.61538462,9.23076923 Z"
                  fill={color}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default SensorIcon;
