import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  linkButton: {
    background: "transparent",
    border: 0,
    fontSize: "14px",
    color: theme.palette.primary.fsBlue,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.fsBluePrimary
    },
    "&:disabled": {
      color: theme.palette.grays.neutralWarm,
      cursor: "default"
    },
    padding: 5,
    transition: `color ${theme.transitionTime}`,
    ...theme.font.semiBold,
    textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center"
  },
  placeholder: {
    display: "none"
  }
}));
