import * as React from "react";
import { useTranslations } from "components/useTranslations";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams
} from "@mui/x-data-grid-pro";
import {
  AppTypography,
  BorderBox,
  Header2,
  Layout,
  PageLoadStateProvider
} from "components/common";
import { useApi } from "api/useApi";
import { CNSVersionList, CNSVersion, Tenant } from "api/models";
import { resources } from "api/resources";
import { routes } from "routes";
import SecondaryButton from "components/common/SecondaryButton";
import LinkButton from "components/common/LinkButton";
import CNSVersionsAssignTenantsModal from "./CNSVersionsAssignTenantsModal";
import useCnsVersionsPageStyles from "./useCnsVersionsPageStyles";
import { useNavigate } from "react-router";
import { methods } from "api/methods";

const CNSVersionsPage: React.FC = () => {
  const navigateTo = useNavigate();
  const [page, setPage] = React.useState<CNSVersionList>();
  const execute = useApi();
  const styles = useCnsVersionsPageStyles();
  const t = useTranslations(page?.translations);
  const [CNSVersions, setCNSVersions] = React.useState<CNSVersion[]>([]);
  const [tenants, setTenants] = React.useState<Tenant[]>([]);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedCNSVersionKey, setSelectedCNSVersionKey] = React.useState("");
  const [selectedCNSVersionName, setSelectedCNSVersionName] = React.useState("");

  React.useEffect(() => {
    loadPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (page) {
      setCNSVersions(page?.cnsVersions);
      setTenants(page?.tenantsList);
    }
  }, [page]);

  const loadPage = () => {
    setPage(undefined);
    setCNSVersions([]);
    setTenants([]);
    execute(resources.CNSVersions, methods.GET)
      .then(data => {
        setPage(data as CNSVersionList);
      })
      .catch(() => {});
  };

  const getAssignedTenants = (CNSVersionKey: string) => {
    let totalAssignedTenants = 0;
    tenants?.forEach(tenant => {
      if (tenant.calculationModels?.some(cm => cm.key === CNSVersionKey)) {
        totalAssignedTenants++;
      }
    });
    if (totalAssignedTenants > 0) {
      return totalAssignedTenants;
    }
    return "";
  };

  const renderEditButton = (params: GridCellParams) => {
    return (
      <LinkButton
        label={t`__CNSVERSIONS_INITIAL_VIEW_EDIT_BUTTON`}
        onClick={() => {
          setIsModalOpen(true);
          setSelectedCNSVersionKey(params.row.id as string);
          setSelectedCNSVersionName(params.row.version as string);
        }}
      />
    );
  };

  const closeModalAction = (refresh: boolean, close: boolean) => {
    if (close) {
      setIsModalOpen(false);
    }
    if (refresh) {
      loadPage();
    }
  };

  // setup data grid
  const columns: GridColDef[] = [
    {
      field: "versionName",
      headerName: t`__CNSVERSIONS_INITIAL_VIEW_VERSION_NAME_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__CNSVERSIONS_INITIAL_VIEW_VERSION_NAME_COLUMN`}</strong>
        </AppTypography>
      ),
      renderCell: (params: GridCellParams) => <strong>{params.value as string}</strong>
    },
    {
      field: "releaseDate",
      headerName: t`__CNSVERSIONS_INITIAL_VIEW_RELEASE_DATE_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__CNSVERSIONS_INITIAL_VIEW_RELEASE_DATE_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "tenantsAssigned",
      headerName: t`__CNSVERSIONS_INITIAL_VIEW_TENANTS_ASSIGNED_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__CNSVERSIONS_INITIAL_VIEW_TENANTS_ASSIGNED_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "locations",
      headerName: t`__CNSVERSIONS_INITIAL_VIEW_LOCATIONS_COLUMN`,
      flex: 1,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__CNSVERSIONS_INITIAL_VIEW_LOCATIONS_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "edit",
      headerName: " ",
      renderCell: (params: GridCellParams) => renderEditButton(params),
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      width: 75,
      display: "flex"
    }
  ];

  const rows: GridRowsProp = CNSVersions.map<GridRowModel>(version => ({
    id: version.key,
    name: version.name,
    version: version.version,
    versionName: `${version.name} ${version.version}`,
    releaseDate: version.releaseDate,
    tenantsAssigned: getAssignedTenants(version.key ?? ""),
    locations: version.locations === 0 ? "" : version.locations
  }));

  return (
    <PageLoadStateProvider isLoaded={!!page?.translations}>
      <BorderBox width="full" height="full">
        <Layout layoutStyle="page">
          <div className={styles.headerStyling}>
            <Header2>{t`__CNSVERSIONS_INITIAL_VIEW_TITLE`}</Header2>
            <SecondaryButton
              name="createCNSVersion"
              onClick={() => navigateTo(routes.CNSVersionsCreate)}
            >
              {t`__CNSVERSIONS_INITIAL_VIEW_CREATE_BUTTON`}
            </SecondaryButton>
          </div>
          <div style={{ height: "75vh", width: "100%", marginTop: 10 }}>
            <div style={{ display: "flex", height: "100%" }}>
              <div style={{ flexGrow: 1 }}>
                <DataGridPro
                  rows={rows}
                  columns={columns}
                  checkboxSelection={false}
                  sortingOrder={["asc", "desc"]}
                  hideFooter
                />
              </div>
            </div>
          </div>
        </Layout>
        {isModalOpen && (
          <CNSVersionsAssignTenantsModal
            tenantList={JSON.parse(JSON.stringify(tenants ?? []))}
            isOpen={isModalOpen}
            triggerParentActions={(refresh: boolean, close: boolean) =>
              closeModalAction(refresh, close)
            }
            selectedCNSVersionKey={selectedCNSVersionKey}
            selectedCNSVersionName={selectedCNSVersionName}
            modalDescriptionLabel={t`__CNSVERSIONS_EDIT_MODAL_SUBTITLE_PARAGRAPH`}
            weightInputModalLabel="1000"
            dispatch={() => {}}
            saveModalLabel={t`__CNSVERSIONS_EDIT_MODAL_SAVE_BUTTON`}
            cancelModalLabel={t`__CNSVERSIONS_EDIT_MODAL_CANCEL_BUTTON`}
            modalHeaderLabel={t`__CNSVERSIONS_EDIT_MODAL_TITTLE`}
            versionLabel={t`__CNSVERSIONS_EDIT_MODAL_VERSION`}
            assignColumnLabel={t`__CNSVERSIONS_EDIT_MODAL_ASSIGN_COLUMN`}
            tenantNameLabel={t`__CNSVERSIONS_EDIT_MODAL_TENANT_NAME_COLUMN`}
            versionsAssignedLabel={t`__CNSVERSIONS_EDIT_MODAL_VERSIONS_ASSIGNED_COLUMN`}
            effectiveDateLabel={t`__CNSVERSIONS_EDIT_MODAL_EFFECTIVE_DATE_COLUMN`}
            errorMessageLabel={t`__CNSVERSIONS_EDIT_MODAL_ERROR_MESSAGE`}
            cnsVersionConflictMessage={t`__CNSVERSIONS_EDIT_MODAL_CANNOT_DEACTIVATE_ERROR_MESSAGE`}
            effectiveDateFormat={page?.dateFormatString}
          />
        )}
      </BorderBox>
    </PageLoadStateProvider>
  );
};

export default CNSVersionsPage;
