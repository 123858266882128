import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  page: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
  },
  allContent: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  landingPageContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    width: "fit-content",
    marginTop: 35,
    marginBottom: 35
  },
  errorContainer: {
    marginTop: 24,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: theme.scales.spacing.medium,
    paddingRight: theme.scales.spacing.medium
  },
  errorIconContainer: {
    marginRight: theme.scales.spacing.normal
  }
}));
