import React, { PropsWithChildren } from "react";
import Icon from "components/common/Icon";
import InfoText from "components/common/Text/InfoText";
import Modal from "components/common/Modal";
import { DispatchAction, FunctionAction } from "components/common/actions";
import BaseButton from "components/common/BaseButton";
import { useTheme } from "theme";
import { DialogProps } from "@mui/material/Dialog";
import MuiDialogTitle from "@mui/material/DialogTitle";
import MuiDialogContent from "@mui/material/DialogContent";
import MuiDialogActions from "@mui/material/DialogActions";
import useUniqueId from "components/useUniqueId";
import useActionModalStyles from "./useActionModalStyles";

export type ActionModalProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  title?: string | null;
  onClose?: () => void;
  closeDispatch?: React.Dispatch<any>;
  closeAction?: (data: any) => any;
  buttonBayRender?: () => JSX.Element;
  disableEscapeKeyDown?: DialogProps["disableEscapeKeyDown"];
  name: string;
  maxWidth?: DialogProps["maxWidth"];
  fullWidth?: DialogProps["fullWidth"];
  scroll?: DialogProps["scroll"];
  helpText?: string | null;
  errorMessage?: string | null;
};

function CloseAction(
  closeActionProps: PropsWithChildren<{
    event: string;
    onClose?: () => void;
    closeDispatch?: React.Dispatch<any>;
    closeAction?: (data: any) => any;
  }>
) {
  const {
    event,
    children,
    onClose,
    closeDispatch,
    closeAction
  } = closeActionProps;

  if (onClose) {
    return (
      <FunctionAction event={event} func={onClose}>
        {children}
      </FunctionAction>
    );
  }

  if (closeDispatch && closeAction) {
    return (
      <DispatchAction
        action={closeAction}
        dispatch={closeDispatch}
        event={event}
      >
        {children}
      </DispatchAction>
    );
  }

  throw "Use event handler approach or dispatch";
}

const ActionModal: React.FC<ActionModalProps> = props => {
  const {
    isOpen,
    title,
    onClose,
    closeDispatch,
    closeAction,
    buttonBayRender,
    children,
    disableEscapeKeyDown,
    name,
    maxWidth = "md",
    fullWidth,
    scroll,
    helpText,
    errorMessage
  } = props;

  const theme = useTheme();
  const styles = useActionModalStyles();
  const dialogTitleId = useUniqueId();

  return (
    <CloseAction
      event="onClose"
      onClose={onClose}
      closeDispatch={closeDispatch}
      closeAction={closeAction}
    >
      <Modal
        open={isOpen}
        disableEscapeKeyDown={disableEscapeKeyDown}
        labelledById={dialogTitleId}
        maxWidth={maxWidth}
        fullWidth={fullWidth}
        scroll={scroll}
      >
        <MuiDialogTitle
          id={dialogTitleId}
          className={styles.dialogTitle}
        >
          <div className={styles.closeIcon}>
            <CloseAction
              event="onClick"
              onClose={onClose} 
              closeDispatch={closeDispatch}
              closeAction={closeAction}
            >
              <BaseButton name={`${name}_close_button`}>
                <Icon icon="close" color={theme.palette.primary.fsBlue} />
              </BaseButton>
            </CloseAction>
          </div>
          <strong>{title}</strong>
        </MuiDialogTitle>
        {helpText && (
          <div className={styles.helpText}>
            <InfoText>{helpText}</InfoText>
          </div>
        )}
        <MuiDialogContent>{children}</MuiDialogContent>
        {buttonBayRender && (
          <>
            <div className={styles.errorMessage}>{errorMessage}</div>
            <MuiDialogActions>{buttonBayRender()}</MuiDialogActions>
          </>
        )}
      </Modal>
    </CloseAction>
  );
};

export default ActionModal;
