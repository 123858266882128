import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ChevronIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
};

const ChevronIcon: React.FC<ChevronIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width="8px"
        height="14px"
        viewBox="0 0 8 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-193.000000, -2767.000000)">
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(185.000000, 92.000000)">
                <g transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) ">
                  <g>
                    <rect x="0" y="0" width="24" height="24" />
                    <path
                      d="M6.70710678,8.29289322 C6.31658249,7.90236893 5.68341751,7.90236893 5.29289322,8.29289322 C4.90236893,8.68341751 4.90236893,9.31658249 5.29289322,9.70710678 L11.2928932,15.7071068 C11.6834175,16.0976311 12.3165825,16.0976311 12.7071068,15.7071068 L18.7071068,9.70710678 C19.0976311,9.31658249 19.0976311,8.68341751 18.7071068,8.29289322 C18.3165825,7.90236893 17.6834175,7.90236893 17.2928932,8.29289322 L12,13.5857864 L6.70710678,8.29289322 Z"
                      fill={color ?? "currentColor"}
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default ChevronIcon;
