import React, { useContext } from "react";
import TopNav from "components/pages/TopNav";
import HomePage from "components/pages/home";
import FeaturesPage from "components/pages/FeaturesPage";
import { routes } from "routes";
import SideNav from "components/pages/SideNav";
import SegmentsPage from "components/pages/SegmentsPage";
import TenantsPage from "components/pages/TenantsPage";
import TenantDetailPage from "components/pages/TenantDetailsPage";
import TenantEditPage from "components/pages/TenantEditPage";
import SuppliersPage from "components/pages/SuppliersPage";
import SupplierDetailPage from "components/pages/SupplierDetailPage";
import IngredientsPage from "components/pages/IngredientsPage";
import NutrientsPage from "components/pages/NutrientsPage";
import IngredientsDetailsPage from "components/pages/IngredientsDetailsPage";
import CNSVersionDetailsPage from "components/pages/CNSVersionDetailsPage";
import CNSVersionsPage from "components/pages/CNSVersionsPage";
import UsersPage from "components/pages/UsersPage";
import UserDetailPage from "components/pages/UserDetailPage";
import ReleaseNotesPage from "components/pages/ReleaseNotesPage";
import NutrientsDetailsPage from "components/pages/NutrientsDetailsPage";
import ReleaseNotesDetailsPage from "components/pages/ReleaseNotesDetailsPage";
import { resources } from "api/resources";
import { SiteDto } from "api/models";
import { useQuery } from "api/useQuery";
import useSiteStyles from "./useSiteStyles";
import Errors from "./Errors";
import UnauthorizedPage from "./Errors/UnauthorizedPage";
import { Routes, Route } from "react-router";
import { ConfigContext } from "config";
import { LicenseInfo } from "@mui/x-license-pro";

const Site: React.FC = () => {
  const styles = useSiteStyles();
  const { data: site } = useQuery<SiteDto>(resources.site);
  const config = useContext(ConfigContext);

  // Material UI Framework Pro License Subscription Key
  LicenseInfo.setLicenseKey(config?.materialUiProLicenseKey || "");

  return (
    <>
      <TopNav />
      {site?.error && (
        <div className={styles.divLoader}>
          <Errors error={site?.error} />
        </div>
      )}
      {!site?.error && (
        <div className={styles.siteContentRoot}>
          <SideNav
            translations={site?.translations}
            canViewAllAdminOptions={site?.canViewAllAdminOptions}
            canViewOnlyReleaseNotes={site?.canViewOnlyReleaseNotes}
          />
          <div className={styles.siteContent}>
            <Routes>
              <Route 
                path={routes.unathorizedPage}
                element={<UnauthorizedPage translations={site?.translations} />}
              />
              <Route 
                path={routes.home}
                element={<HomePage />}
              />
              <Route 
                path={routes.features}
                element={<FeaturesPage />}
              />
              <Route 
                path={routes.segments}
                element={<SegmentsPage />}
              />
              <Route 
                path={routes.tenants}
                element={<TenantsPage />}
              />
              <Route 
                path={routes.tenantsCreate}
                element={<TenantDetailPage creatingNew tenantKey="" />}
              />
              <Route 
                path={`${routes.tenantsEdit}:tenantKey`}
                element={<TenantEditPage />}
              />
              <Route 
                path={routes.suppliers}
                element={<SuppliersPage />}
              />
              <Route 
                path={routes.suppliersCreate}
                element={<SupplierDetailPage creatingNew supplierKey="" />}
              />
               <Route 
                path={`${routes.suppliersEdit}:supplierKey`}
                element={<SupplierDetailPage creatingNew={false} supplierKey="" />}
              />
              <Route 
                path={routes.ingredients}
                element={<IngredientsPage />}
              />
              <Route 
                path={routes.ingredientsCreate}
                element={<IngredientsDetailsPage />}
              />
              <Route 
                path={routes.ingredientsEdit}
                element={<IngredientsDetailsPage />}
              />
              <Route 
                path={routes.nutrients}
                element={<NutrientsPage />}
              />
              <Route 
                path={routes.nutrientsCreate}
                element={<NutrientsDetailsPage />}
              />
              <Route 
                path={`${routes.nutrientsEdit}:nutrientKey`}
                element={<NutrientsDetailsPage />}
              />
              <Route 
                path={routes.CNSVersions}
                element={<CNSVersionsPage />}
              />
              <Route 
                path={routes.CNSVersionsCreate}
                element={<CNSVersionDetailsPage />}
              />
              <Route 
                path={routes.users}
                element={<UsersPage />}
              />
              <Route 
                path={routes.usersEdit}
                element={<UserDetailPage creatingNew={false} />}
              />
              <Route 
                path={routes.userInvite}
                element={<UserDetailPage creatingNew />}
              />
              <Route 
                path={routes.releaseNotes}
                element={<ReleaseNotesPage />}
              />
              <Route 
                path={routes.releaseNotesCreate}
                element={<ReleaseNotesDetailsPage />}
              />
              <Route 
                path={routes.releaseNotesEdit}
                element={<ReleaseNotesDetailsPage />}
              />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

export default Site;
