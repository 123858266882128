import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  formControl: {
    margin: "16px",
    width: 400,
    minWidth: 120,
    maxWidth: 400
  },
  pageContent: {
    height: "100%",
    width: "70%",
    margin: "0 auto",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    minHeight: "600px",
  },
  masterContainerCreate: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
    display: "flex",
    justifyContent: "center",
  },
  masterContainerEdit: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.grays.light,
    display: "flex",
    justifyContent: "center"
  },
  spaceChildrenApart: {
    display: "flex",
    justifyContent: "space-between"
  },
  headerStyling: {
    borderRadius: "10px",
    padding: "24px",
    margin: "0 auto"
  },
  divider: {
    margin: "8px 8px 16px 8px"
  },
  labelMultipleSelect: {
    zIndex: 1000,
    backgroundImage: "linear-gradient(white 80%, rgba(242,242,242, 0) 20%)",
    marginLeft: "1%",
    marginRight: "1%",
    transform: `translate(0px,-70%)`,
    fontSize: "0.8rem"
  },
  multiSelectInfoTool: {
    padding: 0
  }
}));
