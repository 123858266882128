import React, { ReactNode, createContext, useContext } from "react";
import { makeStyles } from "tss-react/mui";
import { CSSObject } from "tss-react";
import { defaultTheme, AppTheme } from "./AppTheme";

const GlobalThemeContext = createContext(defaultTheme);

const GlobalThemeProvider: React.FC<{ children: ReactNode }> = ({ children }) => (
  <GlobalThemeContext.Provider value={defaultTheme}>
    {children}
  </GlobalThemeContext.Provider>
);

const useTheme = () => {
  const context = useContext(GlobalThemeContext);
  if (context === undefined) {
    throw new Error("useTheme must be used within a GlobalThemeProvider");
  }

  return context;
};

const createUseStylesFactory =
  (theming: AppTheme) =>
    (
      styles:
        | Record<string, CSSObject>
        | ((theme: AppTheme) => Record<string, CSSObject>)
    ) => {
      const stylesWithDefaultTheme =
        typeof styles === "function" ? styles(theming) : styles;
      const useStyles = makeStyles()(stylesWithDefaultTheme);
      return () => {
        const { classes } = useStyles();
        return classes;
      };
    };

const createUseStylesWithParamsFactory =
  (theming: AppTheme) =>
    <Params extends object>(
      styles: (params: Params, theme: AppTheme) => Record<string, CSSObject>
    ) => {
      return (params: Params) => {
        const stylesWithDefaultTheme = styles(params, theming);
        const useStyles = makeStyles<Params>()(stylesWithDefaultTheme);
        const { classes } = useStyles(params);
        return classes;
      };
    };

const createThemedUseStyles = createUseStylesFactory(defaultTheme);
const createThemedUseStylesWithParams = createUseStylesWithParamsFactory(defaultTheme);

export {
  AppTheme,
  defaultTheme,
  GlobalThemeProvider,
  useTheme,
  createThemedUseStyles,
  createThemedUseStylesWithParams
};
