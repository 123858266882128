import { useReducer } from "react";
import { hashCodeOfString } from "api/tools";

// Reducer Types
export enum ReleaseNotesDetailsActionType {
  ON_PAGE_LOAD = "on-page-load",
  CHANGE_FORM_STATE = "change-form-state"
}

type FormDetails = {
  releaseNotesVersion?: string;
  releaseNotesDate?: string;
  releaseNotesImprovements?: string;
  releaseNotesFixes?: string;
};

export type ValidationDetails = {
  releaseNotesVersionValidationMessage?: string;
  releaseNotesDateValidationMessage?: string;
  releaseNotesImprovementsValidationMessage?: string;
  releaseNotesFixesValidationMessage?: string;
};

export type ReleaseNotesDetailsState = {
  isFormDirty?: boolean;
  formDetails?: FormDetails;
  validationDetails?: ValidationDetails;
  key?: string;
  stateHash: string;
};

export type ReleaseNotesDetailsAction = {
  type: ReleaseNotesDetailsActionType;
  payload?: any;
};

const initState: ReleaseNotesDetailsState = {
  isFormDirty: false,
  formDetails: {
    releaseNotesVersion: "",
    releaseNotesDate: "",
    releaseNotesImprovements: "",
    releaseNotesFixes: ""
  },
  validationDetails: {
    releaseNotesVersionValidationMessage: "",
    releaseNotesDateValidationMessage: "",
    releaseNotesImprovementsValidationMessage: "",
    releaseNotesFixesValidationMessage: ""
  },
  key: "",
  stateHash: ""
};

// Reducer Function Logic
function reducer(
  state: ReleaseNotesDetailsState,
  action: ReleaseNotesDetailsAction
) {
  switch (action.type) {
    case ReleaseNotesDetailsActionType.ON_PAGE_LOAD: {
      const initialLoadState = { ...state, ...action.payload };
      return {
        ...initialLoadState,
        isFormDirty: false,
        stateHash: hashCodeOfString(
          JSON.stringify(initialLoadState.formDetails)
        )
      };
    }
    case ReleaseNotesDetailsActionType.CHANGE_FORM_STATE: {
      const updatedDetails = {
        ...state,
        [action.payload.key]: action.payload.value
      };

      let hashCodeAfterChange = hashCodeOfString(
        JSON.stringify(updatedDetails.formDetails)
      );

      if (updatedDetails.formDetails?.releaseNotesVersion?.trim() === "") {
        hashCodeAfterChange = state.stateHash;
      }

      // This means that our current page render is no different than initial state, return isFormDirty: false
      if (hashCodeAfterChange === state.stateHash) {
        return {
          ...updatedDetails,
          isFormDirty: false
        };
      }

      return { ...updatedDetails, isFormDirty: true };
    }
    default:
      return state;
  }
}

// Component
export default function useReleaseNotesDetailsReducer(
  init?: ReleaseNotesDetailsState
): [ReleaseNotesDetailsState, React.Dispatch<ReleaseNotesDetailsAction>] {
  const [newState, dispatch] = useReducer(reducer, init ?? initState);
  return [newState, dispatch];
}
