import ToolTip from "components/common/ToolTip";
import { PageLoadStateContext } from "components/common/trackPageLoadState";
import React, { useContext, useEffect, useRef, useState } from "react";
import Text from "../index";
import { TextStyle } from "../TextTypes";
import useInfoTextStyles from "./useInfoTextStyles";

export type InfoTextProps = {
  children?: React.ReactNode;
  id?: string;
  labelledById?: string;
  textStyle?: TextStyle;
  color?: string;
  isGreyBackground?: boolean;
  useFlex?: boolean;
  truncate?: boolean;
  truncateWidth?: number | string;
  nowrap?: boolean;
  qaAutomationLabel?: string;
};

const InfoText: React.FC<InfoTextProps> = props => {
  const styles = useInfoTextStyles();
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };

  const ref = useRef<HTMLParagraphElement>(null);

  const [truncated, setTruncated] = useState(false);

  useEffect(() => {
    function updateTruncationState() {
      setTruncated(
        pageLoadState.loaded && ref.current
          ? ref.current.offsetWidth < ref.current.scrollWidth
          : false
      );
    }
    updateTruncationState();
    // Register a listener to trigger the update to the truncation state when the window
    // is resized
    window.addEventListener("resize", updateTruncationState);
    return () => {
      window.removeEventListener("resize", updateTruncationState);
    };
  }, [pageLoadState.loaded]);

  const paragraphStyle =
    props.useFlex ?? false ? styles.flexParagraph : styles.paragraph;

  const truncationStyle: React.CSSProperties | undefined = props.truncate
    ? {
        width: props.truncateWidth,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: props.nowrap ? "nowrap" : "normal"
      }
    : undefined;

  const text = (
    <p
      className={paragraphStyle}
      id={props.id}
      aria-labelledby={props.labelledById}
      style={truncationStyle}
      ref={ref}
      data-automation={props.qaAutomationLabel}
    >
      <Text
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        textStyle={
          props.textStyle ?? InfoText.defaultProps?.textStyle ?? "info-normal"
        }
        color={props?.color}
        isGreyBackground={props.isGreyBackground}
      >
        {props.children}
      </Text>
    </p>
  );

  return (
    <div style={truncationStyle}>
      {props.truncate && truncated && (
        <ToolTip parentRender={() => text}>{props.children}</ToolTip>
      )}

      {(!props.truncate || (props.truncate && !truncated)) && text}
    </div>
  );
};

InfoText.defaultProps = {
  textStyle: "info-normal"
};

export default InfoText;
