import { useEffect, useState } from "react";
import { useApi } from "api/useApi";
import { methods } from "./methods";

const useQuery = <Type>(url: string) => {
  const [data, setData] = useState<Type>();
  const [isLoading, setIsLoading] = useState(false);
  const execute = useApi();

  useEffect(() => {
    setIsLoading(true);
    execute(url, methods.GET)
      .then((response: any) => {
        setData(response);
        setIsLoading(false);
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url]);

  return { data, isLoading };
};

const useCall = <Type>() => {
  const [data, setData] = useState<Type>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const execute = useApi();

  const call = (
    url: string,
    method: methods,
    body?: Type,
    callback?: (...args: any[]) => any
  ) => {
    setIsLoading(true);
    return execute(url, method, body)
      .then((response: any) => {
        setData(response);
        setError("");
        if (callback) callback();
      })
      .catch((e: any) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return { call, result: { data, isLoading, error } };
};

export { useQuery, useCall };
