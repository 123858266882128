import { createThemedUseStyles } from "theme";

const useStyles = createThemedUseStyles((theme) => ({
  form: {
    "& .MuiFormControl-root": {
      width: "80%",
      margin: theme.scales.spacing.normal,
    },
  },
  pageContent: {
    height: "70%",
    width: "75vw",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    padding: "16px",
    margin: "24px auto",
  },
  masterContainerEdit: {
    margin: "0",
    height: "100vh",
    backgroundColor: theme.palette.grays.light,
  },
  masterContainerCreate: {
    margin: "0",
    height: "100vh",
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
  },
  input: {
    // Material UI edge case
    minWidth: "100%",
  },
  dropdown: {
    height: "40px",
    // Material UI Edge Case
    minWidth: "calc(100%)",
    margin: theme.scales.spacing.normal
  },
  dropdownContainer: {
    // Material UI Edge Case
    marginLeft: theme.scales.spacing.normal,
  },
  label: {
    marginBottom: theme.scales.spacing.normal,
  },
  headerRow: {
    marginBottom: "24px",
    display: "flex",
    justifyContent: "space-between",
  },
  menuPaper: {
    maxHeight: 170,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "end",
    marginRight: "-8px"
  }
}));

export default useStyles;
