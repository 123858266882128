import { ThemeProvider, createTheme  } from "@mui/material";
import { enUS, frFR, ptPT, zhCN, trTR, esES } from "@mui/material/locale";
import { enUS as gridEnUs, frFR as gridFrFR, ptBR as gridPtBR, trTR as gridTrTR, esES as gridEsES } from  "@mui/x-data-grid-pro/locales";

// TODO: solve missing locale support (to be added in MUI in future releases?)
const { language } = navigator;
const localeMap = [
  { code : "en", locale: enUS, gridLocale : gridEnUs},
  { code : "fr", locale: frFR, gridLocale : gridFrFR},
  { code : "pt", locale: ptPT, gridLocale : gridPtBR},
  { code : "tr", locale: trTR, gridLocale : gridTrTR},
  { code : "zh-CN", locale: zhCN, gridLocale : gridEnUs},
  { code : "en-CA", locale: enUS, gridLocale : gridEnUs},
  { code : "fr-CA", locale: frFR, gridLocale : gridFrFR},
  { code : "es", locale: esES, gridLocale : gridEsES}
];

const current = localeMap.filter(l => l.code === language);
const locale = current.length > 0 ? current[0].locale : enUS;
const gridLocale = current.length > 0 ? current[0].gridLocale : gridEnUs;


const theme = createTheme({
  components: {
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          padding: "16px 16px 0 20px"
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "8px 20px"
        }
      }
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "8px 20px 20px 20px"
        }
      }
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          lineHeight: "unset",
          "&$selected": {
            backgroundColor: "unset"
          }
        }
      }
    },
    MuiGrid: {
      styleOverrides:{
        item: {
          padding: "8px !important"
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          margin: "0"
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: "0"
        }
      }
    }
  }
},
locale,
gridLocale);

export { ThemeProvider, theme };
