import React, { useContext } from "react";
import {
  Table,
  TableBody,
  TableCell,
  Box,
  TableHead,
  TableRow,
  TableContainer
} from "@mui/material";
import {
  FormHelperText,
  TextField,
  AppTypography,
  PageLoadStateContext
} from "components/common";
import { useTranslations } from "components/useTranslations";
import { TenantDetailsState } from "../useTenantDetailsReducer";
import useNoFeedNotificationPreferencesStyles from "./useNoFeedNotificationPreferencesStyles";

export type NoFeedNotificationPreferencesProps = {
  state: TenantDetailsState;
  dispatch: any;
};

const defaultProps = {
  bgcolor: "background.paper",
  borderColor: "grey.500",
  m: 0,
  p: 0,
  border: 1,
  borderRadius: 3
};

const NoFeedNotificationPreferences: React.FC<NoFeedNotificationPreferencesProps> =
  props => {
    const styles = useNoFeedNotificationPreferencesStyles();
    const currentNoFeedNotificationPreferences =
      props?.state?.details?.noFeedNotificationPreferences?.slice();
    const t = useTranslations();
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
    function createData(
      alert: string,
      lessThan: string,
      disappearanceRate: string,
      over: string,
      numberOfHours: string
    ) {
      return {
        alert,
        lessThan,
        disappearanceRate,
        over,
        numberOfHours
      };
    }

    const noFeedNotificationRows = [
      createData(
        t`__TENANT_DETAILS_HIGH_WARNING_GRID_CONTENT`,
        "<=",
        "%",
        t`__TENANT_DETAIL_OVER_GRID_CONTENT`,
        t`__TENANT_DETAILS_HOURS_GRID_CONTENT`
      ),
      createData(
        t`__TENANT_DETAILS_MEDIUM_CAUTION_GRID_CONTENT`,
        "<=",
        "%",
        t`__TENANT_DETAIL_OVER_GRID_CONTENT`,
        t`__TENANT_DETAILS_HOURS_GRID_CONTENT`
      )
    ];

    // Validation Functions
    // 1) Get Validation Message
    const getValidationMessages = () => {
      const possible_error_messages: (string | undefined)[] = [];
      const errors = currentNoFeedNotificationPreferences
        ?.filter(item => item.errorMessages && item.errorMessages?.length)
        ?.map(item => item.errorMessages as string[])
        ?.reduce((prev, curr) => [...prev, ...curr], []);
      const errorMessajes = errors.map(msg => msg);
      errorMessajes?.forEach(row => {
        if (row && possible_error_messages.indexOf(row) < 0) {
          possible_error_messages.push(row);
        }
      });
      return possible_error_messages;
    };

    return (
      <>
        <AppTypography variant="subtitle2" gutterBottom>
          <strong>{t`__TENANT_DETAILS_NO_FEED_TO_ANIMALS_TITLE`}</strong>
        </AppTypography>
        <AppTypography variant="body2" gutterBottom>
          <span className={styles.descriptionContent}>
            {t`__TENANT_DETAILS_NO_FEED_TO_ANIMALS_PARAGRAPH`}
            <br /> <br />
          </span>
        </AppTypography>

        <Box {...defaultProps}>
          <TableContainer>
            <Table className={styles.table} aria-label="simple table">
              {/* Table HEADER */}
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <AppTypography variant="overline" color="textSecondary">
                      <strong
                        className={styles.smallBold}
                      >{t`__TENANT_DETAILS_ALERT_GRID_COLUMN`}</strong>
                    </AppTypography>
                  </TableCell>
                  <TableCell align="center" />
                  <TableCell align="center">
                    <AppTypography variant="overline" color="textSecondary">
                      <strong className={styles.smallBold}>
                        {t`__TENANT_DETAILS_DISAPPEARANCE_RATE_GRID_COLUMN`}
                      </strong>
                    </AppTypography>
                  </TableCell>
                  <TableCell align="center" />
                  <TableCell align="center">
                    <AppTypography variant="overline" color="textSecondary">
                      <strong
                        className={styles.smallBold}
                      >{t`__TENANT_DETAILS_NUMBER_HOURS_GRID_COLUMN`}</strong>
                    </AppTypography>
                  </TableCell>
                </TableRow>
              </TableHead>

              {/* Table Body */}
              <TableBody>
                {pageLoadState.loaded &&
                  noFeedNotificationRows.map((row, index) => (
                    <TableRow key={row.alert}>
                      <TableCell align="center">
                        <AppTypography variant="caption">
                          {row.alert}
                        </AppTypography>
                      </TableCell>
                      <TableCell align="center">
                        <strong>{row.lessThan}</strong>
                      </TableCell>
                      <TableCell align="center">
                        <div className={styles.flexCenter}>
                          <TextField
                            value={
                              props?.state?.details
                                ?.noFeedNotificationPreferences?.[index]
                                .disappearanceRate
                            }
                            className={styles.smallTextField}
                            onChange={event => {
                              const updated_row = {
                                ...currentNoFeedNotificationPreferences?.[
                                  index
                                ],
                                disappearanceRate: event.target.value
                              };

                              if (currentNoFeedNotificationPreferences) {
                                currentNoFeedNotificationPreferences[index] =
                                  updated_row;
                              }
                              // dispatch updates
                              props.dispatch({
                                type: "TENANT_DETAILS_EDITED",
                                payload: {
                                  ...props.state.details,
                                  noFeedNotificationPreferences:
                                    currentNoFeedNotificationPreferences
                                }
                              });
                            }}
                          />
                          <AppTypography
                            display="inline"
                            className={styles.descLiteral}
                          >
                            {row.disappearanceRate}
                          </AppTypography>
                        </div>
                      </TableCell>
                      <TableCell align="center">
                        <strong>{row.over}</strong>
                      </TableCell>
                      <TableCell align="center">
                        <div className={styles.flexCenter}>
                          <TextField
                            value={
                              props?.state?.details
                                ?.noFeedNotificationPreferences?.[index].hours
                            }
                            className={styles.smallTextField}
                            onChange={event => {
                              const updated_row = {
                                ...currentNoFeedNotificationPreferences?.[
                                  index
                                ],
                                hours: event.target.value
                              };

                              if (currentNoFeedNotificationPreferences) {
                                currentNoFeedNotificationPreferences[index] =
                                  updated_row;
                              }

                              // dispatch updates
                              props.dispatch({
                                type: "TENANT_DETAILS_EDITED",
                                payload: {
                                  ...props.state.details,
                                  noFeedNotificationPreferences:
                                    currentNoFeedNotificationPreferences
                                }
                              });
                            }}
                          />
                          <AppTypography
                            className={styles.descLiteral}
                            display="inline"
                          >
                            {row.numberOfHours}
                          </AppTypography>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {getValidationMessages().map(error_row => (
          <FormHelperText error>{error_row}</FormHelperText>
        ))}
      </>
    );
  };

export default NoFeedNotificationPreferences;
