import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    margin: "24px",
  },
  segmentName: {
    width: "100%",
    maxWidth: 300,
    marginTop: "16px",
    marginLeft: "32px"
  },
  list: {
    width: "100%",
    maxWidth: 360,
    margin: "24px",
    backgroundColor: theme.palette.white
  },
  button: {
    margin: "24px"
  }
}));
