import React from "react";
import useUniqueId from "components/useUniqueId";

export type ClockIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const ClockIcon: React.FC<ClockIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <svg
      aria-hidden={decorative}
      aria-labelledby={labelledBy}
      width={14}
      height={14}
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      {title}
      <g
        transform="translate(1 1)"
        stroke={color ?? "currentColor"}
        strokeWidth={1.2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <circle cx={6} cy={6} r={6} />
        <path d="M6 2.4V6l2.4 1.2" />
      </g>
    </svg>
  );
};

export default ClockIcon;
