import isEqual from "react-fast-compare";
import React from "react";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams,
  GridSortItem
} from "@mui/x-data-grid-pro";
import { Ingredient } from "api/models";
import { useTranslations } from "components/useTranslations";
import { AppTypography, LinkButton } from "components/common";
import { useNavigate } from "react-router-dom";

// Maybe will be updated in the future
type IngredientsTableProps = {
  ingredients: Ingredient[];
  ingredientsLoading: boolean;
};

const IngredientsTable: React.FC<IngredientsTableProps> = props => {
  const navigateTo = useNavigate();
  const t = useTranslations();
  const renderEditButton = (params: GridCellParams) => {
    return (
      <LinkButton
        label={t`__INGREDIENTS_INITIAL_VIEW_VIEW_BUTTON`}
        onClick={() => navigateTo(`/ingredients/edit/${params.row.id}`)}
      />
    );
  };

  // Set Up Columns
  const IngredientsColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t`__INGREDIENTS_INITIAL_VIEW_NAME_COLUMN`,
      flex: 2.0,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__INGREDIENTS_INITIAL_VIEW_NAME_COLUMN`}</strong>
        </AppTypography>
      ),
      renderCell: (params: GridCellParams) => <strong>{params.value as string}</strong>
    },
    {
      field: "code",
      headerName: t`__INGREDIENTS_INITIAL_VIEW_CODE_COLUMN`,
      flex: 0.8,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__INGREDIENTS_INITIAL_VIEW_CODE_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "ingredientType",
      headerName: t`__INGREDIENTS_INITIAL_VIEW_TYPE_COLUMN`,
      flex: 1.5,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__INGREDIENTS_INITIAL_VIEW_TYPE_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "edit",
      headerName: " ",
      renderCell: renderEditButton,
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      display: "flex",
      flex: 0.6
    }
  ];

  const rows_from_api: GridRowsProp = props.ingredients.map<GridRowModel>(
    ingredient => ({
      id: ingredient.key,
      name: ingredient.name,
      code: ingredient.code,
      ingredientType: ingredient.ingredientTypeDisplayName
    })
  );

  // default sorting model
  const [sortModel, setSortModel] = React.useState([
    {
      field: "name",
      sort: "asc"
    }
  ] as GridSortItem[]);

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={props.ingredientsLoading ? [] : rows_from_api}
            rowHeight={50}
            columns={IngredientsColumns}
            pagination
            sortingOrder={["asc", "desc"]}
            sortModel={sortModel}
            onSortModelChange={model => {
              if (!isEqual(sortModel, model)) {
                setSortModel(model);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default IngredientsTable;
