import React, { useContext } from "react";
import useSecondaryButtonStyles from "./useSecondaryButtonStyles";
import { withAction } from "../actions";
import InfoText from "../Text/InfoText";
import { NamedInputWidth } from "../inputScales";
import { PageLoadStateContext } from "../trackPageLoadState";

type ColorStyle = "dark-blue" | "blue" | "white";

type Props = {
  children?: React.ReactNode;
  title?: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  colorStyle?: ColorStyle;
  isBorderless?: boolean;
  width?: number | NamedInputWidth;
  name: string;
};

const SecondaryButton: React.FC<Props> = props => {
  const styles = useSecondaryButtonStyles();
  const { onClick, title, name } = props;
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };

  let className = "";
  const borderless = props?.isBorderless ?? false;
  const style = props?.colorStyle ?? "dark-blue";

  if (borderless) {
    // Only the borderless version has a color style option
    className =
      style === "dark-blue" ? styles.borderlessDarkBlue : styles.borderlessBlue;
  } else {
    className = style === "white" ? styles.white : styles.secondaryButton;
  }

  const buttonContent = (
    <>
      {title && <InfoText textStyle="info-normal-emphasis">{title}</InfoText>}
      {!title && props.children}
    </>
  );

  return (
    <button
      className={className}
      onClick={onClick}
      disabled={!pageLoadState.loaded ? true : props.disabled}
      name={name}
      type="button"
    >
      {!pageLoadState.loaded ? undefined : buttonContent}
    </button>
  );
};

export default withAction(SecondaryButton, "onClick");
