import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type CheckCircleIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const CheckCircleIcon: React.FC<CheckCircleIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="22px"
        height="23px"
        viewBox="0 0 22 23"
        version="1.1"
      >
        <title>{title}</title>
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-311.000000, -2972.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2880.000000)">
              <g transform="translate(312.000000, 93.000000)">
                <path d="M20,10.08 L20,11 C19.9974678,15.4286859 17.082294,19.328213 12.8353524,20.583901 C8.58841086,21.839589 4.02139355,20.1523121 1.61095509,16.4370663 C-0.799483376,12.7218205 -0.479136554,7.86363898 2.39827419,4.49707214 C5.27568494,1.13050531 10.0247126,0.0575252842 14.07,1.86" />
                <polyline points="20 3 10 13 7 10.002997" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default CheckCircleIcon;
