import React, { useLayoutEffect, useState } from "react";
import { InfoText, Icon } from "components/common";
import { useTheme } from "theme";
import { HttpStatusCode, SiteError } from "api/models";
import useErrorIndicatorStyles from "./useErrorIndicatorStyles";

export type ErrorIndicatorProps = {
  isOpen?: boolean;
  error: SiteError | undefined | null;
  onToggle?: () => void;
};

export function isErrorIndicatorHidden(
  error: SiteError | undefined | null
): boolean {
  return (
    !error ||
    (error.httpStatus !== undefined &&
      error.httpStatus !== HttpStatusCode.InternalServerError &&
      error.httpStatus !== HttpStatusCode.Forbidden)
  );
}

const ErrorIndicator: React.FC<ErrorIndicatorProps> = props => {
  const { isOpen, error, onToggle } = props;
  const [isOpenState, setIsOpenState] = useState(isOpen);
  const styles = useErrorIndicatorStyles();
  const theme = useTheme();

  useLayoutEffect(() => {
    if (error && setIsOpenState) {
      // in uncontrolled mode show the message if there is a message
      setIsOpenState(true);
    }
  }, [error, setIsOpenState]);

  // hide if no error
  if (isErrorIndicatorHidden(error)) {
    return null;
  }

  const openedState = (
    <div className={styles.messageHost}>
      <span className={styles.message}>
        <InfoText>{error?.message}</InfoText>
      </span>
      <button
        name="error_indicator_close_button"
        className={styles.close}
        onClick={() =>
          setIsOpenState ? setIsOpenState(false) : onToggle && onToggle()
        }
        type="button"
      >
        <Icon icon="close" color={theme.palette.white} />
      </button>
    </div>
  );

  const closedState = (
    <button
      name="error_indicator_open_button"
      className={styles.open}
      onClick={() =>
        setIsOpenState ? setIsOpenState(true) : onToggle && onToggle()
      }
      type="button"
    >
      <Icon icon="caret" />
    </button>
  );

  return (
    <div className={styles.root}>
      {(isOpenState !== false && openedState) || closedState}
    </div>
  );
};

export default ErrorIndicator;
