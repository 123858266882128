import React from "react";
import Text from "../index";
import { TextStyle } from "../TextTypes";
import useActionTextStyles from "./useActionTextStyles";

export type ActionTextProps = {
  children?: React.ReactNode;
  id?: string;
  textStyle?: TextStyle;
  color?: string;
};

const ActionText: React.FC<ActionTextProps> = props => {
  const styles = useActionTextStyles();
  const defaultTextStyle = "action";

  return (
    <p id={props.id} className={styles.paragraph}>
      <Text
        textStyle={
          props.textStyle ??
          ActionText.defaultProps?.textStyle ??
          defaultTextStyle
        }
        color={props.color}
      >
        {props.children}
      </Text>
    </p>
  );
};

ActionText.defaultProps = {
  textStyle: "action"
};

export default ActionText;
