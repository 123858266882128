import React, { useContext } from "react";
import { withAction } from "../actions";
import useLinkButtonStyles from "./useLinkButtonStyles";
import { PageLoadStateContext } from "../trackPageLoadState";

export type LinkButtonProps = {
  label: string;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

const LinkButton: React.FC<LinkButtonProps> = props => {
  const styles = useLinkButtonStyles();
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
  const { onClick, label } = props;
  return (
    <div>
      <button
        type="button"
        className={`${
          pageLoadState.loaded ? styles.linkButton : styles.placeholder
        }`}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

export default withAction(LinkButton, "onClick");
