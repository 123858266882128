import React from "react";
import { Header2 } from "components/common";
import { useTranslations } from "components/useTranslations";
import useCNSVersionDetailsHeaderStyles from "./useCNSVersionDetailsHeaderStyles";

const CNSVersionDetailsHeader: React.FC = () => {
  const styles = useCNSVersionDetailsHeaderStyles();
  const t = useTranslations();
  return (
    <div className={styles.headerStyling}>
      <Header2>{t`__CNSVERSIONS_DETAILS_TITLE_NEW`}</Header2>
    </div>
  );
};

export default CNSVersionDetailsHeader;
