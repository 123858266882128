import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ShareIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const ShareIcon: React.FC<ShareIconProps> = props => {
  const { color, decorative, label, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = iconSize ?? 16;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 15 18"
        version="1.1"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1017.000000, -2765.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(1018.000000, 96.000000)">
                <g>
                  <path
                    d="M0,8 L0,14.4 C0,15.2836556 0.7163444,16 1.6,16 L11.2,16 C12.0836556,16 12.8,15.2836556 12.8,14.4 L12.8,8"
                    id="Path"
                  />
                  <polyline id="Path" points="9.6 3.2 6.4 0 3.2 3.2" />
                  <line x1="6.4" y1="0" x2="6.4" y2="10.4" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default ShareIcon;
