import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import { Config } from "config";
import App from "./App";

// Add "config" file to "Window" type
declare global {
  interface Window {
    config: Config;
    configPromise: Promise<Response>;
  }
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<App />);