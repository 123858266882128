import React, { useEffect, useState } from "react";
import { useApi } from "api/useApi";
import { resources } from "api/resources";
import { Nutrient } from "api/models";
import { methods } from "api/methods";
import { PageLoadStateProvider } from "components/common";
import { TranslationProvider } from "components/useTranslations";
import NutrientsDetailsForm from "./NutrientsDetailsForm";
import NutrientsDetailsHeader from "./NutrientsDetailsHeader";
import { useParams } from "react-router";

const NutrientsDetailsPage: React.FC = () => {
  const execute = useApi();

  const params = useParams();

  const [nutrientData, setNutrientData] = useState<Nutrient>();

  useEffect(() => {
    if (params.nutrientKey) {
      execute(resources.nutrient(params.nutrientKey), methods.GET)
        .then((response: Nutrient) => {
          setNutrientData(response);
        })
        .catch(() => {});
    } else {
      execute(resources.newNutrient, methods.GET)
        .then((response: Nutrient) => {
          if (!response.isInvalid) {
            setNutrientData(response);
          }
        })
        .catch(() => {});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.nutrientKey]);
  return (
    <PageLoadStateProvider isLoaded={!!nutrientData?.translations}>
      <TranslationProvider translations={nutrientData?.translations}>
        {() => (
          <>
            <>
              <NutrientsDetailsHeader nutrient={nutrientData} />
              <NutrientsDetailsForm nutrient={nutrientData} />
            </>
          </>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default NutrientsDetailsPage;
