import React from "react";
import { Header2 } from "components/common";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { createThemedUseStyles } from "theme";
import { useTranslations } from "components/useTranslations";
import { Breadcrumbs, Typography, Link } from "@mui/material";

export type SupplierDetailHeaderProps = {
  supplierKey?: string;
  supplierName?: string;
  creatingNew: boolean;
};

const useStyles = createThemedUseStyles(() => ({
  headerStyling: {
    width: "75vw",
    padding: "16px",
    margin: "0 auto"
  }
}));

const SupplierDetailHeader: React.FC<SupplierDetailHeaderProps> = props => {
  const styles = useStyles();
  const t = useTranslations();

  return (
    <div className={styles.headerStyling}>
      {props.creatingNew ? (
        <Header2>{t`__SUPPLIERS_DETAILS_HEADER_NEW`}</Header2>
      ) : (
        <Breadcrumbs separator={<ArrowForwardIcon />}>
          <Link color="inherit" href="/suppliers">
            <Header2> {t`__SUPPLIERS_DETAILS_HEADER_TITLE`}</Header2>
          </Link>
          <Typography color="textPrimary" component="div">
            <Header2>{props.supplierName}</Header2>
          </Typography>
        </Breadcrumbs>
      )}
    </div>
  );
};

export default SupplierDetailHeader;
