import React from "react";
import SecondaryButton from "components/common/SecondaryButton";
import { routes } from "routes";
import { useTranslations } from "components/useTranslations";
import { useNavigate } from "react-router";

const IngredientsAdditionButton: React.FC = () => {
  const navigateTo = useNavigate();
  const t = useTranslations();
  return (
    <SecondaryButton
      name="createIngredient"
      onClick={() => navigateTo(routes.ingredientsCreate)}
    >
      {t`__INGREDIENTS_INITIAL_VIEW_CREATE_BUTTON`}
    </SecondaryButton>
  );
};

export default IngredientsAdditionButton;
