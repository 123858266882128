import React, { useEffect } from "react";
import { TranslationProvider } from "components/useTranslations";
import { useReducer } from "reinspect";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  FormControl,
  Select,
  Tooltip,
  FormControlLabel,
  IconButton,
  Switch
} from "@mui/material";
import {
  TextField,
  AppTypography,
  PageLoadStateProvider,
  statuses,
  Button,
  MenuItem,
  TableCell,
  Header2
} from "components/common/";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useQuery, useCall } from "api/useQuery";
import { resources } from "api/resources";
import { methods } from "api/methods";
import { Feature, FeatureList, FeatureModel, SegmentList } from "api/models";
import featureManagementReducer from "./featureManagementReducer";
import useFeaturePageStyles from "./useFeaturePageStyles";

const FeaturesPage: React.FC = () => {
  const [state, dispatch] = useReducer(featureManagementReducer, {
    features: [],
    availableSegments: []
  });
  const classes = useFeaturePageStyles();

  const { data: features, isLoading: featuresLoading } = useQuery<FeatureList>(
    resources.features
  );

  const { data: segmentsData } = useQuery<SegmentList>(resources.segments);

  const { call: updateFeature } = useCall<Feature>();
  const {
    call: createFeature,
    result: { isLoading: featureCreateLoading, error: featureCreateError }
  } = useCall<Feature>();

  const init = () => {
    const segments = segmentsData?.segments ?? [];
    const featuresList = features?.features ?? [];
    dispatch({
      type: "init",
      pageState: {
        features: featuresList.map(f => {
          return {
            name: f.name,
            enabled: f.enabledByDefault,
            type: f.type,
            segment: f.segment,
            availableSegments: segments,
            status: statuses.INITIAL
          };
        }),
        availableSegments: segments.map(s => s.name)
      }
    });
  };

  const saveFeatures = (
    featuresToCreate: FeatureModel[],
    featuresToUpdate: FeatureModel[]
  ) => {
    featuresToUpdate.forEach(fm => {
      updateFeature(
        resources.feature(fm.name),
        methods.PUT,
        {
          name: fm.name,
          enabledByDefault: fm.enabled,
          type: fm.type,
          segment: fm.segment
        },
        () => dispatch({ type: "saveAll", name: fm.name })
      ).catch(() => {});
    });

    featuresToCreate.forEach(fm => {
      createFeature(
        resources.features,
        methods.POST,
        {
          name: fm.name,
          enabledByDefault: fm.enabled,
          type: fm.type,
          segment: fm.segment
        },
        () => dispatch({ type: "saveAll", name: fm.name })
      ).catch(() => {});
    });
  };

  const saveAll = () => {
    saveFeatures(
      state.features.filter(f => f.status === statuses.ADDED.toString()),
      state.features.filter(f => f.status === statuses.MODIFIED.toString())
    );
  };

  useEffect(init, [features, segmentsData?.segments]);

  const renderSegmentSelect = (feature: FeatureModel) => {
    return (
      <FormControl variant="standard">
        <Select
          id={`drp-segment-select-${feature.name}`}
          labelId="drp-segment-select-label"
          value={feature.segment ?? ""}
          onChange={(event: any) =>
            dispatch({
              type: "setWhitelist",
              name: feature.name,
              segmentName: event.target.value
            })
          }
        >
          {state.availableSegments.map(segment => {
            return (
              <MenuItem
                key={`drp-segment-menu-${feature.name}-${segment}`}
                value={segment}
              >
                {segment}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
  };

  function renderFeature(feature: FeatureModel, index: number) {
    return (
      <TranslationProvider
        translations={features?.translations}
        key={`translation-provider-${index}`}
      >
        {t => (
          <TableRow key={`row_${feature.name}`}>
            <TableCell>
              {feature.status === statuses.ADDED.toString() ? (
                <TextField
                  label={t`__FEATURES_DETAILS_NAME_PLACEHOLDER`}
                  error={featureCreateError !== ""}
                  helperText={featureCreateError}
                  value={feature.name}
                  variant="standard"
                  onChange={event =>
                    dispatch({
                      type: "updateName",
                      name: feature.name,
                      newName: event.target.value
                    })
                  }
                  autoFocus
                />
              ) : (
                <span>{feature.name}</span>
              )}
              <span hidden={feature.status !== statuses.ADDED.toString()}>
                <IconButton onClick={() => dispatch({ type: "undo" })}>
                  <DeleteIcon />
                </IconButton>
              </span>
            </TableCell>
            <TableCell>
              {feature.status === statuses.ADDED.toString() ? (
                <Select
                  id={`drp-segment-feature-type-${feature.name}`}
                  value={feature.type ?? ""}
                  variant="standard"
                  onChange={(event: any) =>
                    dispatch({
                      type: "setFeatureType",
                      name: feature.name,
                      featureType: event.target.value
                    })
                  }
                >
                  <MenuItem value="DEV_GATE">{t`__FEATURES_DETAILS_TYPE_DEV_GATE`}</MenuItem>
                  <MenuItem value="FEATURE">{t`__FEATURES_DETAILS_TYPE_FEATURE`}</MenuItem>
                </Select>
              ) : (
                <AppTypography>{feature.type}</AppTypography>
              )}
            </TableCell>
            <TableCell>
              <FormControlLabel
                control={
                  <Switch
                    id={`chk_${feature.name}`}
                    defaultChecked={feature.enabled}
                    disabled={feature.type === "FEATURE"}
                    onChange={() =>
                      dispatch({
                        type: "toggleEnabledByDefault",
                        name: feature.name
                      })
                    }
                    color="primary"
                  />
                }
                label=""
              />
            </TableCell>
            <TableCell>
              {feature.type === "FEATURE" ? (
                renderSegmentSelect(feature)
              ) : (
                <span>{t`__FEATURES_DETAILS_NA`}</span>
              )}
            </TableCell>
          </TableRow>
        )}
      </TranslationProvider>
    );
  }

  return (
    <PageLoadStateProvider isLoaded={!!features?.translations}>
      <TranslationProvider translations={features?.translations}>
        {t => (
          <>
            <Header2>{t`__FEATURES_INITIAL_VIEW_TITLE`}</Header2>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t`__FEATURES_INITIAL_VIEW_NAME_COLUMN`}</TableCell>
                    <TableCell>{t`__FEATURES_INITIAL_VIEW_TYPE_COLUMN`}</TableCell>
                    <TableCell>{t`__FEATURES_INITIAL_VIEW_ENABLED_COLUMN`}</TableCell>
                    <TableCell>{t`__FEATURES_INITIAL_VIEW_WHITELISTED_COLUMN`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{state.features.map(renderFeature)}</TableBody>
              </Table>
              <div
                hidden={
                  state.features.some(f => f.status === statuses.ADDED.toString()) ||
                  featuresLoading
                }
              >
                <FormControl error={featureCreateError !== ""}>
                  <Tooltip title="Add New Feature" aria-label="add">
                    <IconButton
                      className={classes.addButton}
                      color="primary"
                      onClick={() => dispatch({ type: "add" })}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </FormControl>
              </div>
              <div>
                <Button
                  className={classes.button}
                  variant="contained"
                  disabled={
                    state.features.every(f => f.status === statuses.INITIAL.toString()) ||
                    featureCreateLoading
                  }
                  color="primary"
                  onClick={saveAll}
                >
                  {t`__FEATURES_INITIAL_VIEW_SAVE_BUTTON`}
                </Button>
              </div>
            </TableContainer>
          </>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default FeaturesPage;
