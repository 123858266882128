import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  form: {
    "& .MuiFormControl-root": {
      margin: theme.scales.spacing.normal
    }
  },
  pageContent: {
    height: "75vh",
    width: "75vw",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    padding: "16px",
    margin: "20px auto"
  },
  masterContainerEdit: {
    margin: "0",
    height: "100vh",
    backgroundColor: theme.palette.grays.light
  },
  masterContainerCreate: {
    margin: "0",
    height: "100vh",
    backgroundColor: theme.palette.primary.fsDarkBlueSub
  },
  spaceChildrenApart: {
    display: "flex",
    justifyContent: "space-between"
  },
  input: {
    // Material UI edge case
    minWidth: "100%"
  },
  dropdown: {
    height: "40px",
    // Material UI Edge Case
    minWidth: "calc(100% + 8px)"
  },
  dropdownContainer: {
    // Material UI Edge Case
    marginLeft: theme.scales.spacing.normal
  },
  label: {
    marginBottom: theme.scales.spacing.normal
  },
  rowOfInputs: {
    marginTop: "24px"
  },
  menuPaper: {
    maxHeight: 170
  }
}));
