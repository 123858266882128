import React, { useEffect, useState } from "react";
import { TranslationProvider } from "components/useTranslations";
import { resources } from "api/resources";
import { methods } from "api/methods";
import { Header2, InputLabel, TextField } from "components/common";
import { useApi } from "api/useApi";
import PrimaryButton from "components/common/PrimaryButton";
import { User } from "api/models";
import {
  Grid,
  Snackbar,
  Paper,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  SnackbarCloseReason
} from "@mui/material";
import Alert from "@mui/material/Alert";
import useUserDetailFormStyles from "./useUserDetailFormStyles";
import useUserDetailsFormReducer, {
  UserDetailsFormActionType
} from "./useUserDetailsFormReducer";

export type UserDetailFormProps = {
  user?: User;
  creatingNew: boolean;
};

const UserDetailForm: React.FC<UserDetailFormProps> = props => {
  const { user } = props;
  const execute = useApi();
  const styles = useUserDetailFormStyles();
  const [state, dispatch] = useUserDetailsFormReducer();

  // on page load logic
  useEffect(() => {
    if (user?.key) {
      dispatch({
        type: UserDetailsFormActionType.ON_PAGE_LOAD,
        payload: {
          key: user?.key,
          formDetails: {
            firstName: user?.firstName ?? "",
            lastName: user?.lastName ?? "",
            currentEmail: user?.currentEmail ?? "",
            updatedEmail: user?.updatedEmail ?? "",
            userRoles: user?.selectedUserRoles ?? null
          },
          validationDetails: {
            firstNameValidationMessage: "",
            lastNameValidationMessage: "",
            currentEmailValidationMessage: "",
            updatedEmailValidationMessage: "",
            confirmEmailValidationMessage: "",
            selectedUserRolesValidationMessage: ""
          },
          userRolesOptions: user?.userRolesOptions
        }
      });
    } else {
      dispatch({
        type: UserDetailsFormActionType.ON_PAGE_LOAD,
        payload: {
          key: user?.key,
          formDetails: {
            firstName: "",
            lastName: "",
            currentEmail: "",
            updatedEmail: "",
            confirmEmail: "",
            userRoles: []
          },
          userRolesOptions: user?.userRolesOptions
        }
      });
    }
  }, [user, dispatch]);

  const onClick = () => {
    const formValues: User = {
      key: user?.key ?? "",
      firstName: state.formDetails?.firstName,
      lastName: state.formDetails?.lastName,
      currentEmail: state.formDetails?.currentEmail,
      updatedEmail: state.formDetails?.updatedEmail,
      confirmEmail: state.formDetails?.confirmEmail,
      selectedUserRoles: state.formDetails?.userRoles ?? []
    };
    const creating = !user?.key;
    execute(
      user?.key ? `${resources.user(user?.key)}` : `${resources.userInvite}`,
      user?.key ? methods.PUT : methods.POST,
      formValues
    )
      .then((response: User) => {
        let currentEmailValidationMessage = "";
        if (response.currentEmailValidationMessage) {
          currentEmailValidationMessage =
            response.currentEmailValidationMessage;
        } else if (response.internalCargillEmailValidationMessage) {
          currentEmailValidationMessage =
            response.internalCargillEmailValidationMessage;
        }
        dispatch({
          type: UserDetailsFormActionType.CHANGE_FORM_STATE,
          payload: {
            key: "validationDetails",
            value: {
              ...state.validationDetails,
              firstNameValidationMessage:
                response.firstNameValidationMessage ?? "",
              lastNameValidationMessage:
                response.lastNameValidationMessage ?? "",
              currentEmailValidationMessage:
                currentEmailValidationMessage ?? "",
              confirmEmailValidationMessage:
                response.confirmEmailValidationMessage ?? "",
              updatedEmailValidationMessage:
                response.updatedEmailValidationMessage ?? "",
              selectedUserRolesValidationMessage:
                response.selectedUserRolesValidationMessage ?? ""
            }
          }
        });
        if (response?.isInvalid) {
          setSuccess(false);
          return;
        }
        dispatch({
          type: UserDetailsFormActionType.ON_PAGE_LOAD,
          payload: creating
            ? {
                key: "",
                formDetails: {
                  firstName: "",
                  lastName: "",
                  updatedEmail: "",
                  currentEmail: "",
                  confirmEmail: "",
                  userRoles: []
                },
                validationDetails: {
                  firstNameValidationMessage: "",
                  lastNameValidationMessage: "",
                  currentEmailValidationMessage: "",
                  updatedEmailValidationMessage: "",
                  confirmEmailValidationMessage: "",
                  selectedUserRolesValidationMessage: ""
                },
                userRolesOptions: response?.userRolesOptions
              }
            : {
                key: response?.key,
                formDetails: {
                  firstName: response?.firstName ?? "",
                  lastName: response?.lastName ?? "",
                  updatedEmail: response?.updatedEmail ?? "",
                  currentEmail: response?.currentEmail ?? "",
                  confirmEmail: response?.confirmEmail ?? "",
                  userRoles: response?.selectedUserRoles ?? []
                },
                validationDetails: {
                  firstNameValidationMessage: "",
                  lastNameValidationMessage: "",
                  currentEmailValidationMessage: "",
                  updatedEmailValidationMessage: "",
                  confirmEmailValidationMessage: "",
                  selectedUserRolesValidationMessage: ""
                },
                userRolesOptions: response?.userRolesOptions
              }
        });
        setSuccess(true);
      })
      .catch(() => {});
  };

  const [success, setSuccess] = useState(false);

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <TranslationProvider translations={user?.translations}>
      {t => (
        <div
          className={
            user?.key
              ? styles.masterContainerEdit
              : styles.masterContainerCreate
          }
        >
          <Paper className={styles.pageContent}>
            <form>
              {/* Form heading */}
              <Grid container>
                <Grid item xs={12} className={styles.spaceChildrenApart}>
                  <Snackbar
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "center"
                    }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                      {!props.creatingNew
                        ? t`__USER_DETAILS_USER_UPDATED`
                        : t`__USER_DETAILS_USER_INVITED`}
                    </Alert>
                  </Snackbar>
                  <Header2>{t`__USER_DETAILS_SUBTITLE`}</Header2>
                  <PrimaryButton
                    name="applyChangesButton"
                    disabled={!state.isFormDirty}
                    onClick={onClick}
                  >
                    {t`__USER_DETAILS_SAVE_CHANGES`}
                  </PrimaryButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <TextField
                    label={t`__USER_DETAILS_FIRST_NAME`}
                    fullWidth
                    error={
                      state.validationDetails?.firstNameValidationMessage !== ""
                    }
                    helperText={
                      state.validationDetails?.firstNameValidationMessage
                    }
                    variant="outlined"
                    margin="dense"
                    onChange={e => {
                      dispatch({
                        type: UserDetailsFormActionType.CHANGE_FORM_STATE,
                        payload: {
                          key: "formDetails",
                          value: {
                            ...state.formDetails,
                            firstName: e.target.value
                          }
                        }
                      });
                    }}
                    value={state.formDetails?.firstName}
                  />
                </Grid>
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <TextField
                    label={t`__USER_DETAILS_LAST_NAME`}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    error={
                      state.validationDetails?.lastNameValidationMessage !== ""
                    }
                    helperText={
                      state.validationDetails?.lastNameValidationMessage
                    }
                    onChange={e => {
                      dispatch({
                        type: UserDetailsFormActionType.CHANGE_FORM_STATE,
                        payload: {
                          key: "formDetails",
                          value: {
                            ...state.formDetails,
                            lastName: e.target.value
                          }
                        }
                      });
                    }}
                    value={state.formDetails?.lastName}
                  />
                </Grid>
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <TextField
                    label={t`__USER_DETAILS_CURRENT_EMAIL`}
                    fullWidth
                    error={
                      state.validationDetails?.currentEmailValidationMessage !==
                      ""
                    }
                    helperText={
                      state.validationDetails?.currentEmailValidationMessage
                    }
                    variant="outlined"
                    margin="dense"
                    disabled={!props.creatingNew}
                    onChange={e => {
                      dispatch({
                        type: UserDetailsFormActionType.CHANGE_FORM_STATE,
                        payload: {
                          key: "formDetails",
                          value: {
                            ...state.formDetails,
                            currentEmail: e.target.value
                          }
                        }
                      });
                    }}
                    value={state.formDetails?.currentEmail}
                  />
                </Grid>
                {!props.creatingNew && (
                  <>
                    <Grid item xs={11} sm={10} md={8} lg={6}>
                      <div style={{ height: "40px" }} />
                    </Grid>
                    <Grid item xs={11} sm={10} md={8} lg={6}>
                      <TextField
                        label={t`__USER_DETAILS_UPDATED_EMAIL`}
                        fullWidth
                        error={
                          state.validationDetails
                            ?.updatedEmailValidationMessage !== ""
                        }
                        helperText={
                          state.validationDetails?.updatedEmailValidationMessage
                        }
                        variant="outlined"
                        margin="dense"
                        onChange={e => {
                          dispatch({
                            type: UserDetailsFormActionType.CHANGE_FORM_STATE,
                            payload: {
                              key: "formDetails",
                              value: {
                                ...state.formDetails,
                                updatedEmail: e.target.value
                              }
                            }
                          });
                        }}
                        value={state.formDetails?.updatedEmail}
                      />
                    </Grid>
                  </>
                )}
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <TextField
                    label={t`__USER_DETAILS_CONFIRM_EMAIL`}
                    fullWidth
                    error={
                      state.validationDetails?.confirmEmailValidationMessage !==
                      ""
                    }
                    helperText={
                      state.validationDetails?.confirmEmailValidationMessage
                    }
                    variant="outlined"
                    margin="dense"
                    onChange={e => {
                      dispatch({
                        type: UserDetailsFormActionType.CHANGE_FORM_STATE,
                        payload: {
                          key: "formDetails",
                          value: {
                            ...state.formDetails,
                            confirmEmail: e.target.value
                          }
                        }
                      });
                    }}
                    value={state.formDetails?.confirmEmail}
                  />
                </Grid>
                <Grid item xs={11} sm={10} md={8} lg={6}>
                  <FormControl variant="outlined" margin="dense" fullWidth>
                    <InputLabel>{t`__USER_DETAILS_USER_ROLES`}</InputLabel>
                    <Select
                      label={t`__USER_DETAILS_USER_ROLES`}
                      labelId="user-role-select-label"
                      id="user-role-select"
                      variant="outlined"
                      value={
                        state?.userRolesOptions?.find(
                          n => n.key === state.formDetails?.userRoles?.at(0)
                        )?.key ?? ""
                      }
                      error={
                        state.validationDetails
                          ?.selectedUserRolesValidationMessage !== ""
                      }
                      onChange={e => {
                        dispatch({
                          type: UserDetailsFormActionType.CHANGE_FORM_STATE,
                          payload: {
                            key: "formDetails",
                            value: {
                              ...state.formDetails,
                              userRoles: [
                                state?.userRolesOptions?.find(
                                  u => u.key === e.target.value
                                )?.key
                              ]
                            }
                          }
                        });
                      }}
                    >
                      {state?.userRolesOptions?.map(userRole => {
                        return (
                          <MenuItem key={userRole.key} value={userRole.key}>
                            {userRole.displayName}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    <FormHelperText
                      variant="outlined"
                      error={
                        state.validationDetails
                          ?.selectedUserRolesValidationMessage !== ""
                      }
                    >
                      {
                        state.validationDetails
                          ?.selectedUserRolesValidationMessage
                      }
                    </FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      )}
    </TranslationProvider>
  );
};

export default UserDetailForm;
