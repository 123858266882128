import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  itemContainer: {
    padding: "0 !important"
  },
  pageContent: {
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    padding: "16px",
    margin: "24px auto",
    height: "85%",
    width: "75%"
  },
  masterContainerCreate: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.primary.fsDarkBlueSub,
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },
  masterContainerEdit: {
    margin: "0",
    padding: "20px 40px",
    backgroundColor: theme.palette.grays.light,
    display: "flex",
    justifyContent: "center",
    height: "100%"
  },
  reminderText: {
    marginTop: "5px",
    paddingLeft: "10px",
    display: "block"
  },
  spaceChildrenApart: {
    display: "flex",
    justifyContent: "space-between"
  },
  label: {
    marginBottom: theme.scales.spacing.normal
  },
  rowOfInputs: {
    marginTop: "24px"
  },
  menuPaper: {
    maxHeight: 170
  },
  labelMultipleSelect: {
    zIndex: 1000,
    backgroundImage: "linear-gradient(white 80%, rgba(242,242,242, 0) 20%)",
    marginLeft: "1%",
    marginRight: "1%",
    transform: `translate(0px,-73.5%)`,
    fontSize: "0.8rem"
  },
  multiSelectInfoTool: {
    padding: 0
  },
  autocompleteLabel: {
    marginBottom: 0
  }
}));
