import { createThemedUseStylesWithParams } from "theme";
import { PrimaryButtonProps } from "./PrimaryButtonTypes";
import { NamedInputWidth } from "../inputScales";

export default createThemedUseStylesWithParams<PrimaryButtonProps>(
  (params, theme) => {
    const getMinWidth = (width?: number | NamedInputWidth) => {
      if (typeof width === "number") {
        return width;
      }
      switch (width) {
        case "xsmall":
          return theme.scales.input.widths.xsmall;
        case "small":
          return theme.scales.input.widths.small;
        case "medium":
          return theme.scales.input.widths.medium;
        case "large":
          return theme.scales.input.widths.large;
        case "full":
          return "100%";
        default:
          return "190px";
      }
    };

    return {
      button: {
        minHeight: "50px",
        minWidth: getMinWidth(params?.width),
        borderRadius: "2px",
        background: theme.palette.primary.fsBlue,
        fontSize: "14px",
        color: "#FFFFFF",
        lineHeight: "18px",
        cursor: "pointer",
        border: "none",
        padding: `16px ${theme.scales.spacing.medium}px`,
        "&:hover": {
          background: theme.palette.primary.fsBluePrimary
        },
        "&:disabled": {
          background: theme.palette.grays.light,
          color: theme.palette.grays.neutralCool,
          cursor: "default"
        },
        transition: `background ${theme.transitionTime}`,
        overflow: "auto"
      }
    };
  }
);
