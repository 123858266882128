import React from "react";
import { TranslationProvider } from "components/useTranslations";
import { Grid } from "@mui/material";
import {
  BorderBox,
  Header2,
  Layout,
  PageLoadStateProvider
} from "components/common";
import { resources } from "api/resources";
import { useQuery } from "api/useQuery";
import { IngredientsList } from "api/models";
import IngredientsTable from "./IngredientsTable";
import IngredientsAdditionButton from "./IngredientsAdditionButton";
import useIngredientsPageStyles from "./useIngredientsPageStyles";

const IngredientsPage: React.FC = () => {
  const { data: response, isLoading: ingredientsLoading } =
    useQuery<IngredientsList>(resources.ingredients);

  const styles = useIngredientsPageStyles();

  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <TranslationProvider translations={response?.translations}>
        {t => (
          <BorderBox width="full" height="full">
            <Layout layoutStyle="page">
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.headerStyling}>
                    <Header2>{t`__INGREDIENTS_INITIAL_VIEW_TITLE`}</Header2>
                    <IngredientsAdditionButton />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <IngredientsTable
                    ingredients={response?.ingredients ?? []}
                    ingredientsLoading={ingredientsLoading}
                  />
                </Grid>
              </Grid>
            </Layout>
          </BorderBox>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default IngredientsPage;
