import isEqual from "react-fast-compare";
import React, { useEffect, useState } from "react";
import {
  DataGridPro,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridCellParams,
  GridSortItem
} from "@mui/x-data-grid-pro";
import { LinkButton, AppTypography } from "components/common";
import { User, UsersList } from "api/models";
import { useTranslations } from "components/useTranslations";
import { resources } from "api/resources";
import { methods } from "api/methods";
import { useCall } from "api/useQuery";
import { useNavigate } from "react-router";

// Maybe will be updated in the future
type UsersTableProps = {
  users: User[];
  usersLoading: boolean;
};

const UsersTable: React.FC<UsersTableProps> = props => {
  const navigateTo = useNavigate();
  const t = useTranslations();
  const [users, setUsers] = useState<User[]>(props.users);

  const renderActivateButton = (params: GridCellParams) => {
    return (
      <>
        {params.row.isDeactivateVisible && (
          <LinkButton
            label={t`__USERS_INITIAL_VIEW_DEACTIVATE_BUTTON`}
            onClick={() => deactivateUser(params.row)}
          />
        )}
        {params.row.isReactivateVisible && (
          <LinkButton
            label={t`__USERS_INITIAL_VIEW_REACTIVATE_BUTTON`}
            onClick={() => reactivateUser(params.row)}
          />
        )}
      </>
    );
  };
  const renderEditButton = (userRow: GridRowModel) => {
    return (
      <LinkButton
        label={t`__USERS_INITIAL_VIEW_EDIT_BUTTON`}
        onClick={() => navigateTo(resources.user(userRow.id))}
      />
    );
  };

  const {
    call: updateUserStatus,
    result: { data: userData, isLoading: userUpdateLoading }
  } = useCall<UsersList>();

  const deactivateUser = (userRow: GridRowModel) => {
    updateUserStatus(resources.userDeactivate(userRow.id), methods.POST).catch(
      () => {}
    );
  };

  const reactivateUser = (userRow: GridRowModel) => {
    updateUserStatus(resources.userReactivate(userRow.id), methods.POST).catch(
      () => {}
    );
  };

  useEffect(() => {
    setUsers(userData?.users ?? props.users ?? []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userUpdateLoading, props.usersLoading]);

  // Set Up Columns
  const UsersColumns: GridColDef[] = [
    {
      field: "name",
      headerName: t`__USERS_INITIAL_VIEW_NAME_COLUMN`,
      flex: 1.5,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__USERS_INITIAL_VIEW_NAME_COLUMN`}</strong>
        </AppTypography>
      ),
      renderCell: (params: GridCellParams) => <strong>{params.value as string}</strong>
    },
    {
      field: "email",
      headerName: t`__USERS_INITIAL_VIEW_EMAIL_COLUMN`,
      flex: 1.2,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__USERS_INITIAL_VIEW_EMAIL_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "activate",
      headerName: " ",
      renderCell: renderActivateButton,
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      display: "flex",
      flex: 0.4
    },
    {
      field: "edit",
      headerName: " ",
      renderCell: renderEditButton,
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      display: "flex",
      flex: 0.4
    }
  ];

  const rows_from_api: GridRowsProp = users.map<GridRowModel>(user => ({
    id: user.key,
    name: user.fullName,
    email: user.currentEmail,
    isDeactivateVisible: user.isDeactivateVisible,
    isReactivateVisible: user.isReactivateVisible
  }));

  // default sorting model
  const [sortModel, setSortModel] = React.useState([
    {
      field: "name",
      sort: "asc"
    }
  ] as GridSortItem[]);

  return (
    <div style={{ height: "75vh", width: "100%" }}>
      <div style={{ display: "flex", height: "100%" }}>
        <div style={{ flexGrow: 1 }}>
          <DataGridPro
            rows={props.usersLoading ? [] : rows_from_api}
            rowHeight={50}
            columns={UsersColumns}
            pagination
            sortingOrder={["asc", "desc"]}
            sortModel={sortModel}
            onSortModelChange={(
              model: React.SetStateAction<GridSortItem[]>
            ) => {
              if (!isEqual(sortModel, model)) {
                setSortModel(model);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
