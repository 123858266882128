import React from "react";
import { TranslationProvider } from "components/useTranslations";
import { Grid } from "@mui/material";
import {
  BorderBox,
  Header2,
  Layout,
  PageLoadStateProvider
} from "components/common";
import { resources } from "api/resources";
import { useQuery } from "api/useQuery";
import { UsersList } from "api/models";
import UsersTable from "./UsersTable";
import UsersInviteButton from "./UsersInviteButton";
import useUsersPageStyles from "./useUsersPageStyles";

const UsersPage: React.FC = () => {
  const { data: response, isLoading: usersLoading } = useQuery<UsersList>(
    resources.users
  );
  const styles = useUsersPageStyles();
  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <TranslationProvider translations={response?.translations}>
        {t => (
          <BorderBox width="full" height="full">
            <Layout layoutStyle="page">
              <Grid container>
                <Grid item xs={12}>
                  <div className={styles.headerStyling}>
                    <Header2>{t`__USERS_INITIAL_VIEW_TITLE`}</Header2>
                    <UsersInviteButton />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <UsersTable
                    users={response?.users ?? []}
                    usersLoading={usersLoading}
                  />
                </Grid>
              </Grid>
            </Layout>
          </BorderBox>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default UsersPage;
