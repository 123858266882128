import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => {
  return {
    loading: {
      display: "block",
    },
    loadingCenter: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
    },
  };
});
