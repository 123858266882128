import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => ({
  paragraph: {
    marginBlockStart: 0,
    marginBlockEnd: 0
  },
  flexParagraph: {
    composes: "$paragraph",
    display: "flex"
  }
}));
