import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => ({
  siteContentRoot: {
    display: "flex",
    flex: "1"
  },

  siteContent: {
    width: "calc(100% - 91px)",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between"
  },

  divLoader: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));
