import React, { useState } from "react";
import {
  MenuItem,
  Select,
  Grid,
  FormControl,
  Box,
  Paper,
  Typography,
  Divider,
  OutlinedTextFieldProps,
  Snackbar,
  Tooltip,
  IconButton,
  SnackbarCloseReason
} from "@mui/material";
import InfoIcon from "components/common/Icon/InfoIcon";
import Alert from "@mui/material/Alert";
import {
  Header2,
  Layout,
  PrimaryButton,
  InputLabel,
  TextField,
  PageLoadStateProvider,
  AppTypography,
  FormHelperText
} from "components/common";
import { resources } from "api/resources";
import { TenantDetails } from "api/models";
import { methods } from "api/methods";
import { useApi } from "api/useApi";
import { TranslationProvider } from "components/useTranslations";
import NoFeedNotificationPreferences from "./NoFeedNotificationPreferences";
import {
  useTenantDetailsReducer,
  TenantDetailsState
} from "./useTenantDetailsReducer";
import { useTheme } from "../../../theme/index";
import useTenantDetailPageStyles from "./useTenantDetailsPageStyles";
import TenantDetailsHeader from "./TenantDetailsHeader";

export type TenantDetailPageProps = {
  tenantKey: string;
  creatingNew: boolean;
};

const inputProps: OutlinedTextFieldProps = {
  variant: "outlined",
  size: "small",
  fullWidth: true
};

// 1) Functions used to parse MUI.Select Event Handlers:
// -- returns the selected values of a multiline selection box.
const handleMultipleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  const { options } = event.target as HTMLSelectElement;
  const selected_values: string[] = [];
  for (let i = 0, l = options.length; i < l; i++) {
    if (options[i].selected) {
      selected_values.push(options[i].value);
    }
  }
  return selected_values;
};

// Methods for reducing lines of code:
// 1) Dispatch tenant edited
export const dispatchFormValues = (
  dispatchedState: TenantDetailsState,
  object_key: string,
  event: any,
  dispatch: any
) => {
  dispatch({
    type: "TENANT_DETAILS_EDITED",
    payload: {
      ...dispatchedState.details,
      [object_key]: event.target.value as string
    }
  });
};

// 2) Checking if form input has errors.
export const isError = (
  stateError: TenantDetailsState,
  object_key: keyof TenantDetails
) => {
  return (
    !!stateError?.details[object_key] &&
    stateError?.details[object_key] !== ""
  );
};

// 3) Populate validation message:
export const getValidation = (
  stateValidation: TenantDetailsState,
  object_key: keyof TenantDetails
): any => {
  return stateValidation?.details[object_key];
};

const TenantDetailPage: React.FC<TenantDetailPageProps> = props => {
  const [state, dispatch] = useTenantDetailsReducer();
  const execute = useApi();
  const styles = useTenantDetailPageStyles();

  const theme = useTheme();

  React.useEffect(() => {
    execute(resources.newTenant, methods.GET)
      .then((response: TenantDetails) => {
        if (!response.isInvalid) {
          dispatch({
            type: "TENANT_DETAILS_LOADED",
            payload: response
          });
        }
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // HANDLE BUTTON CLICK:
  const handleButtonClick = () => {
    // HTTP REQUEST:
    // Request Payload:
    const http_request_message: any = {
      ...state.details
    };
    dispatch({ type: "TENANT_SAVE_REQUEST_SENT" });
    // Updates state with any validation messages
    execute(`${resources.tenants}`, methods.POST, http_request_message)
      .then((response: any) => {
        if (!response.isInvalid) {
          setSuccess(true);
          dispatch({
            type: "TENANT_DETAILS_LOADED",
            payload: response
          });
          return;
        }
        setSuccess(false);
        dispatch({ type: "TENANT_SAVE_RESPONSE_RECEIVED", payload: response });
      })
      .catch(() => {});
  };

  // State whether form successful
  const [success, setSuccess] = useState(false);

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  return (
    <PageLoadStateProvider isLoaded={!!state?.details?.translations}>
      <TranslationProvider translations={state?.details?.translations}>
        {t => (
          <div>
            <TenantDetailsHeader
              tenantKey={props.tenantKey}
              tenantName={state?.details?.tenantOrgName}
              creatingNew={props.creatingNew}
            />
            <Box
              pt={2}
              className={
                props.creatingNew
                  ? styles.masterContainerCreate
                  : styles.masterContainerEdit
              }
            >
              <Paper className={styles.pageContent}>
                <Layout layoutStyle="form-uniform" minWidth="unset">
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                      {t`__TENANT_DETAILS_TENANT_SAVED`}
                    </Alert>
                  </Snackbar>

                  <form>
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p={1}
                        alignContent="center"
                      >
                        <Header2>{t`__TENANT_DETAILS_TITLE_DETAILS`}</Header2>
                        <PrimaryButton
                          name="saveButton"
                          onClick={() => handleButtonClick()}
                          disabled={!state?.isLoaded || !state?.isFormDirty}
                          width={80}
                        >
                          {t`__TENANT_DETAILS_SAVE_BUTTON`}
                        </PrimaryButton>
                      </Box>
                      <div>
                        <Divider className={styles.divider} />
                      </div>
                      <Grid container justifyContent="flex-start">
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            {...inputProps}
                            name="tenantOrgName"
                            label={t`__TENANT_DETAILS_NAME`}
                            value={state?.details?.tenantOrgName ?? ""}
                            onChange={event => {
                              dispatchFormValues(
                                state,
                                "tenantOrgName",
                                event,
                                dispatch
                              );
                            }}
                            error={isError(
                              state,
                              "tenantOrgNameValidationMessage"
                            )}
                            helperText={getValidation(
                              state,
                              "tenantOrgNameValidationMessage"
                            )}
                            disabled={!state.isLoaded}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            {...inputProps}
                            name="locationName"
                            label={t`__TENANT_DETAILS_INITIAL_LOCATION_NAME`}
                            value={state?.details?.locationName ?? ""}
                            onChange={event => {
                              dispatchFormValues(
                                state,
                                "locationName",
                                event,
                                dispatch
                              );
                            }}
                            error={isError(
                              state,
                              "locationNameValidationMessage"
                            )}
                            helperText={getValidation(
                              state,
                              "locationNameValidationMessage"
                            )}
                            disabled={!state.isLoaded}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_INITIAL_LOCATION_TYPE`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_INITIAL_LOCATION_TYPE`}
                              name="locationType"
                              value={state?.details?.locationType ?? ""}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "locationType",
                                  event,
                                  dispatch
                                );
                              }}
                              error={isError(
                                state,
                                "locationTypeValidationMessage"
                              )}
                              disabled={!state.isLoaded}
                            >
                              {state?.details?.locationTypeOptions?.map(
                                locType => (
                                  <MenuItem
                                    value={locType.key}
                                    key={locType.key}
                                  >
                                    {locType.displayName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "locationTypeValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "locationTypeValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            {...inputProps}
                            name="userFirstName"
                            label={t`__TENANT_DETAILS_ADMIN_NAME`}
                            value={state?.details?.userFirstName ?? ""}
                            error={isError(
                              state,
                              "userFirstNameValidationMessage"
                            )}
                            helperText={getValidation(
                              state,
                              "userFirstNameValidationMessage"
                            )}
                            disabled={!state.isLoaded}
                            onChange={event => {
                              dispatchFormValues(
                                state,
                                "userFirstName",
                                event,
                                dispatch
                              );
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            {...inputProps}
                            name="userLastName"
                            label={t`__TENANT_DETAILS_ADMIN_LAST_NAME`}
                            value={state?.details?.userLastName ?? ""}
                            error={isError(
                              state,
                              "userLastNameValidationMessage"
                            )}
                            helperText={getValidation(
                              state,
                              "userLastNameValidationMessage"
                            )}
                            onChange={event => {
                              dispatchFormValues(
                                state,
                                "userLastName",
                                event,
                                dispatch
                              );
                            }}
                            disabled={!state.isLoaded}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                          <TextField
                            {...inputProps}
                            name="userEmail"
                            label={t`__TENANT_DETAILS_ADMIN_EMAIL`}
                            type="email"
                            value={state?.details?.userEmail ?? ""}
                            error={isError(state, "userEmailValidationMessage")}
                            helperText={getValidation(
                              state,
                              "userEmailValidationMessage"
                            )}
                            onChange={event => {
                              dispatchFormValues(
                                state,
                                "userEmail",
                                event,
                                dispatch
                              );
                            }}
                            disabled={!state.isLoaded}
                          />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_DEFAULT_CURRENCY`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_DEFAULT_CURRENCY`}
                              name="currency"
                              value={state?.details?.currency ?? ""}
                              error={isError(
                                state,
                                "currencyValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "currency",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {(state?.details?.currencyOptions ?? []).map(
                                currency => (
                                  <MenuItem
                                    key={currency.keyValue}
                                    value={currency.keyValue}
                                  >
                                    {currency.displayValue}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "currencyValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "currencyValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_DEFAULT_MEASUREMENT_SYSTEM`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_DEFAULT_MEASUREMENT_SYSTEM`}
                              value={state?.details?.measurementSystem ?? ""}
                              error={isError(
                                state,
                                "measurementSystemValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "measurementSystem",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {state?.details?.measurementSystemOptions?.map(
                                measurementSystem => (
                                  <MenuItem
                                    value={measurementSystem.key}
                                    key={measurementSystem.key}
                                  >
                                    {measurementSystem.displayName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "measurementSystemValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "measurementSystemValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_FEED_LINE_DISPLAY_TYPE`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_FEED_LINE_DISPLAY_TYPE`}
                              name="feedLineDisplayType"
                              value={state?.details?.feedLineDisplayType ?? ""}
                              error={isError(
                                state,
                                "feedLineDisplayTypeValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "feedLineDisplayType",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {state?.details?.feedLineDisplayTypeOptions?.map(
                                feedLineDisplayType => (
                                  <MenuItem
                                    value={feedLineDisplayType.key}
                                    key={feedLineDisplayType.key}
                                  >
                                    {feedLineDisplayType.displayName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "feedLineDisplayTypeValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "feedLineDisplayTypeValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_NUTRIENT_BASIS`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_NUTRIENT_BASIS`}
                              name="nutrientBasis"
                              value={state?.details?.nutrientBasis ?? ""}
                              error={isError(
                                state,
                                "nutrientBasisValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "nutrientBasis",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {state?.details?.nutrientBasisOptions?.map(
                                basis => (
                                  <MenuItem value={basis.key} key={basis.key}>
                                    {basis.displayName}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "nutrientBasisValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "nutrientBasisValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl size="small" fullWidth>
                            <InputLabel
                              shrink
                              htmlFor="nutrientPackages"
                              className={styles.labelMultipleSelect}
                            >
                              &nbsp;&nbsp;
                              {t`__TENANT_DETAILS_NUTRIENT_PACKAGES`}
                              <Tooltip
                                title={t`__TENANT_DETAILS_SELECTOR_CTRL_HINT`}
                              >
                                <IconButton
                                  className={styles.multiSelectInfoTool}
                                >
                                  <InfoIcon
                                    iconSize={12}
                                    color={theme.palette.primary.fsBlue}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>
                            <Select<string[]>
                              label={t`__TENANT_DETAILS_NUTRIENT_PACKAGES`}
                              variant="outlined"
                              value={state?.details?.nutrientPackages ?? ""}
                              error={isError(
                                state,
                                "nutrientPackagesValidationMessage"
                              )}
                              onChange={event => {
                                dispatch({
                                  type: "TENANT_DETAILS_EDITED",
                                  payload: {
                                    ...state.details,
                                    nutrientPackages:
                                      // @ts-ignore Typings are not considering `native`
                                      handleMultipleSelect(event)
                                  }
                                });
                              }}
                              disabled={!state.isLoaded}
                              multiple
                              native
                            >
                              {(
                                state?.details?.nutrientPackageOptions ?? []
                              ).map(pkg => (
                                <option key={pkg.keyValue} value={pkg.keyValue}>
                                  {pkg.displayValue}
                                </option>
                              ))}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "nutrientPackagesValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "nutrientPackagesValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              shrink
                              className={styles.labelMultipleSelect}
                            >
                              &nbsp;&nbsp;
                              {t`__TENANT_DETAILS_CALCULATION_MODELS`}
                              <Tooltip
                                title={t`__TENANT_DETAILS_SELECTOR_CTRL_HINT`}
                              >
                                <IconButton
                                  className={styles.multiSelectInfoTool}
                                >
                                  <InfoIcon
                                    iconSize={12}
                                    color={theme.palette.primary.fsBlue}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>
                            <Select<string[]>
                              variant="outlined"
                              name="calcModels"
                              value={state?.details?.calcModels ?? ""}
                              error={isError(
                                state,
                                "calcModelsValidationMessage"
                              )}
                              onChange={event => {
                                dispatch({
                                  type: "TENANT_DETAILS_EDITED",
                                  payload: {
                                    ...state.details,
                                    // @ts-ignore Typings are not considering `native`
                                    calcModels: handleMultipleSelect(event)
                                  }
                                });
                              }}
                              disabled={!state.isLoaded}
                              multiple
                              native
                            >
                              {(state?.details?.calcModelOptions ?? []).map(
                                model => (
                                  <option
                                    key={model.keyValue}
                                    value={model.keyValue}
                                  >
                                    {model.displayValue}
                                  </option>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "calcModelsValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "calcModelsValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_DEFAULT_BINTRAC_ACCOUNT`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_DEFAULT_BINTRAC_ACCOUNT`}
                              name="binTracOrgId"
                              value={state?.details?.binTracOrgId ?? ""}
                              error={isError(
                                state,
                                "binTracOrgIdValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "binTracOrgId",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {(state?.details.binTracAccountOptions ?? []).map(
                                binTracAccount => (
                                  <MenuItem
                                    key={binTracAccount.keyValue}
                                    value={binTracAccount.keyValue}
                                  >
                                    {binTracAccount.displayValue}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "binTracOrgIdValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "binTracOrgIdValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_DEFAULT_BINSENTRY_ACCOUNT`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_DEFAULT_BINSENTRY_ACCOUNT`}
                              name="binSentryOrgId"
                              value={state?.details?.binSentryOrgId ?? ""}
                              error={isError(
                                state,
                                "binSentryOrgIdValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "binSentryOrgId",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {(
                                state?.details?.binSentryAccountOptions ?? []
                              ).map(binSentryAccount => (
                                <MenuItem
                                  key={binSentryAccount.keyValue}
                                  value={binSentryAccount.keyValue}
                                >
                                  {binSentryAccount.displayValue}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "binSentryOrgIdValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "binSentryOrgIdValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl size="small" fullWidth>
                            <InputLabel
                              shrink
                              className={styles.labelMultipleSelect}
                            >
                              &nbsp;&nbsp;{t`__TENANT_DETAILS_ENABLED_SEGMENTS`}
                              <Tooltip
                                title={t`__TENANT_DETAILS_SELECTOR_CTRL_HINT`}
                              >
                                <IconButton
                                  className={styles.multiSelectInfoTool}
                                >
                                  <InfoIcon
                                    iconSize={12}
                                    color={theme.palette.primary.fsBlue}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>
                            <Select<string[]>
                              name="segments"
                              variant="outlined"
                              value={state?.details?.segments ?? ""}
                              error={isError(
                                state,
                                "segmentsValidationMessage"
                              )}
                              onChange={event => {
                                dispatch({
                                  type: "TENANT_DETAILS_EDITED",
                                  payload: {
                                    ...state.details,
                                    // @ts-ignore Typings are not considering `native`
                                    segments: handleMultipleSelect(event)
                                  }
                                });
                              }}
                              disabled={!state.isLoaded}
                              multiple
                              native
                            >
                              {(state?.details?.segmentOptions ?? []).map(
                                segment => (
                                  <option
                                    key={segment.keyValue}
                                    value={segment.keyValue}
                                  >
                                    {segment.displayValue}
                                  </option>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "segmentsValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "segmentsValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        {state?.details?.displayCanonicalId && (
                          <Grid item xs={12} md={12} lg={6}>
                            <TextField
                              {...inputProps}
                              name="canonicalId"
                              label={t`__TENANT_DETAILS_CANONICAL_ID`}
                              value={state?.details?.canonicalId ?? ""}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "canonicalId",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={12}>
                          <AppTypography variant="h6">
                            {t`__TENANT_DETAILS_NOTIFICATION_PREFERENCES`}
                          </AppTypography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <Typography />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <NoFeedNotificationPreferences
                            state={state}
                            dispatch={dispatch}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </form>
                </Layout>
              </Paper>
            </Box>
          </div>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default TenantDetailPage;
