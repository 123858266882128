import React, { useEffect, useState } from "react";
import {
  Grid,
  Paper,
  Select,
  MenuItem,
  Snackbar,
  FormControl,
  SnackbarCloseReason
} from "@mui/material";
import {
  Header2,
  PrimaryButton,
  InputLabel,
  TextField
} from "components/common";
import { useForm, Controller } from "react-hook-form";
import { methods } from "api/methods";
import { resources } from "api/resources";
import { Ingredient, IngredientTypeKind } from "api/models";
import { useApi } from "api/useApi";
import Alert from "@mui/material/Alert";
import { TranslationProvider } from "components/useTranslations";
import ConfirmationModal from "components/common/ConfirmationModal";
import useIngredientsDetailsFormStyles from "./useIngredientsDetailsFormStyles";

export type IngredientsDetailsFormProps = {
  ingredient?: Ingredient;
};

enum IngredientFormFields {
  ingredientName = "name",
  ingredientCode = "code",
  ingredientRCode = "rCode",
  ingredientType = "ingredientTypeDisplayName"
}

const IngredientsDetailsForm: React.FC<IngredientsDetailsFormProps> = props => {
  const styles = useIngredientsDetailsFormStyles();
  const execute = useApi();
  const { ingredient } = props;
  const [editIngredient, setEditIngredient] = useState<Ingredient>(
    ingredient as Ingredient
  );
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  // State whether form successful
  const [success, setSuccess] = useState(false);
  // State whether form busy
  const [busy, setBusy] = useState(false);

  const getDefaultValues = () => {
    let defaultValue: Ingredient;
    if (editIngredient?.key && editIngredient) {
      defaultValue = {
        key: editIngredient?.key ? editIngredient?.key : "",
        name: editIngredient?.name ? editIngredient?.name : "",
        code: editIngredient?.code ? editIngredient?.code : "",
        rCode: editIngredient?.rCode ? editIngredient?.rCode : "",
        ingredientTypeDisplayName: editIngredient?.ingredientTypeDisplayName
          ? editIngredient?.ingredientTypeDisplayName
          : ""
      };
    } else {
      defaultValue = {
        key: "",
        name: "",
        code: "",
        rCode: "",
        ingredientTypeDisplayName:
          IngredientTypeKind[IngredientTypeKind.RawMaterial].toString()
      };
    }
    return defaultValue;
  };

  // React hook forms
  const {
    control,
    getValues,
    formState: { errors, isDirty },
    trigger,
    reset
  } = useForm<Ingredient>({
    defaultValues: getDefaultValues()
  });

  // Retrieval of edit ingredient may take time: reset default values upon change.
  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editIngredient]);

  useEffect(() => {
    setEditIngredient(ingredient as Ingredient);
  }, [ingredient]);

  // Save Button
  const onClick = () => {
    if (editIngredient?.key) {
      if (!confirmationModalIsOpen) setConfirmationModalIsOpen(true);
    } else {
      onSave();
    }
  };

  // Save Action
  const onSave = () => {
    setNameValidationErrorMessage("");
    setCodeValidationErrorMessage("");
    setRCodeValidationErrorMessage("");
    trigger()
      .then(isValid => {
        if (isValid) {
          const formValues: Ingredient = {
            key: editIngredient?.key ?? "",
            name: getValues("name"),
            code: getValues("code"),
            rCode: getValues("rCode"),
            ingredientTypeDisplayName: getValues("ingredientTypeDisplayName"),
            ingredientTypeOptions: editIngredient?.ingredientTypeOptions
          };
          const creating = !editIngredient?.key;
          setBusy(true);
          execute(
            editIngredient?.key
              ? `${resources.ingredient(editIngredient?.key)}`
              : `${resources.ingredients}/create`,
            editIngredient?.key ? methods.PUT : methods.POST,
            formValues
          )
            .then((response: Ingredient) => {
              setBusy(false);
              if (response?.isInvalid) {
                setSuccess(false);

                if (response.nameValidationMessage !== "") {
                  setNameValidationErrorMessage(response.nameValidationMessage);
                }
                if (response.codeValidationMessage !== "") {
                  setCodeValidationErrorMessage(response.codeValidationMessage);
                }
                if (response.rCodeValidationMessage !== "") {
                  setRCodeValidationErrorMessage(
                    response.rCodeValidationMessage
                  );
                }
                return;
              }
              setSuccess(true);
              if (creating) {
                reset(getDefaultValues());
              }
              setEditIngredient(formValues);
              setNameValidationErrorMessage("");
              setCodeValidationErrorMessage("");
              setRCodeValidationErrorMessage("");
            })
            .catch((err: any) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
        }
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setBusy(false);
      });
  };

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  // Validation Message State
  const [nameValidationErrorMessage, setNameValidationErrorMessage] =
    useState<string | undefined>("");

  const [codeValidationErrorMessage, setCodeValidationErrorMessage] =
    useState<string | undefined>("");

  const [rCodeValidationErrorMessage, setRCodeValidationErrorMessage] =
    useState<string | undefined>("");

  // 2) Changing State based on dependencies -- if fields are empty
  useEffect(() => {
    if (errors.name) {
      setNameValidationErrorMessage(errors.name.message);
    }
    if (errors.code) {
      setCodeValidationErrorMessage(errors.code.message);
    }
    if (errors.rCode) {
      setRCodeValidationErrorMessage(errors.rCode.message);
    }
  }, [errors.name, errors.code, errors.rCode]);

  return (
    <TranslationProvider translations={ingredient?.translations}>
      {t => (
        // 1) div = blue container
        // 2) Paper = container for form
        <div
          className={
            editIngredient?.key
              ? styles.masterContainerEdit
              : styles.masterContainerCreate
          }
        >
          <ConfirmationModal
            isOpen={confirmationModalIsOpen}
            setIsOpen={setConfirmationModalIsOpen}
            callback={onSave}
          />
          <Paper className={styles.pageContent}>
            <form className={styles.form}>
              {/* First Row: form heading */}
              <Grid container>
                <Grid item xs={12} className={styles.spaceChildrenApart}>
                  <Header2>{t`__INGREDIENT_DETAILS_SUBTITLE`}</Header2>
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                      {t`__INGREDIENT_DETAILS_SAVED`}
                    </Alert>
                  </Snackbar>
                  <PrimaryButton
                    onClick={onClick}
                    name="saveIngredient"
                    disabled={!isDirty || busy}
                  >
                    {t`__INGREDIENT_DETAILS_SAVE`}
                  </PrimaryButton>
                </Grid>
              </Grid>
              {/* Second Row: name, code */}
              <Grid className={styles.rowOfInputs} container>
                <Grid item xs={12} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <TextField
                          {...passProps}
                          className={styles.input}
                          size="small"
                          variant="outlined"
                          label={t`__INGREDIENT_DETAILS_NAME`}
                          error={nameValidationErrorMessage !== ""}
                          helperText={nameValidationErrorMessage}
                          onBlur={() => {
                            trigger(IngredientFormFields.ingredientName).catch(
                              (err: any) => {
                                // eslint-disable-next-line no-console
                                console.error(err);
                              }
                            );
                          }}
                        />
                      );
                    }}
                    name={IngredientFormFields.ingredientName}
                    control={control}
                    rules={{ required: t`__INGREDIENT_DETAILS_NAME_REQUIRED` }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <TextField
                          {...passProps}
                          className={styles.input}
                          size="small"
                          variant="outlined"
                          label={t`__INGREDIENT_DETAILS_CODE`}
                          error={codeValidationErrorMessage !== ""}
                          helperText={codeValidationErrorMessage}
                          onBlur={() => {
                            trigger(IngredientFormFields.ingredientCode).catch(
                              (err: any) => {
                                // eslint-disable-next-line no-console
                                console.error(err);
                              }
                            );
                          }}
                        />
                      );
                    }}
                    name={IngredientFormFields.ingredientCode}
                    control={control}
                    rules={{ required: t`__INGREDIENT_DETAILS_CODE_REQUIRED` }}
                  />
                </Grid>
              </Grid>
              {/* Third Row: type, Rcode */}
              <Grid className={styles.rowOfInputs} container>
                <Grid item xs={12} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel
                            id="ingredient-type-label"
                            className={styles.label}
                          >
                            {t`__INGREDIENT_DETAILS_TYPE`}
                          </InputLabel>
                          <Select
                            {...passProps}
                            variant="outlined"
                            label={t`__INGREDIENT_DETAILS_TYPE`}
                            labelId="ingredient-type-label"
                            id="ingredient-type-select"
                            className={styles.dropdown}
                          >
                            {editIngredient?.ingredientTypeOptions?.map(c => (
                              <MenuItem value={c.key} key={c.key}>
                                {c.displayName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      );
                    }}
                    name={IngredientFormFields.ingredientType}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <Controller
                    render={({ field }) => {
                      const { ref, ...passProps } = field;
                      return (
                        <TextField
                          {...passProps}
                          className={styles.input}
                          size="small"
                          variant="outlined"
                          label={t`__INGREDIENT_DETAILS_RCODE`}
                          error={rCodeValidationErrorMessage !== ""}
                          helperText={rCodeValidationErrorMessage}
                          onBlur={() => {
                            trigger(IngredientFormFields.ingredientRCode).catch(
                              (err: any) => {
                                // eslint-disable-next-line no-console
                                console.error(err);
                              }
                            );
                          }}
                        />
                      );
                    }}
                    name={IngredientFormFields.ingredientRCode}
                    control={control}
                    rules={{ required: t`__INGREDIENT_DETAILS_RCODE_REQUIRED` }}
                  />
                </Grid>
              </Grid>
            </form>
          </Paper>
        </div>
      )}
    </TranslationProvider>
  );
};

export default IngredientsDetailsForm;
