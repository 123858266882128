import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  page: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%"
  },
  loginCard: {
    marginBottom: theme.scales.spacing.medium
  },
  loginCardContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginTop: 35,
    marginBottom: 35
  },
  logoContainer: {
    height: 196,
    width: 281,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  loginCardRow: {
    marginTop: theme.scales.spacing.medium,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center"
  },
  loginErrorContainer: {
    composes: "$loginCardRow",
    paddingLeft: theme.scales.spacing.medium,
    paddingRight: theme.scales.spacing.medium
  },
  errorIconContainer: {
    marginRight: theme.scales.spacing.normal,
    color: "red"
  },
  footerContainer: {
    color: theme.palette.grays.neutralCool
  },
  footerItem: {
    width: 425,
    marginBottom: theme.scales.spacing.normal,
    textAlign: "center"
  }
}));
