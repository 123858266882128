import { Layout, BorderBox, Icon, InfoText } from "components/common";
import React from "react";
import { SiteError } from "api/models";
import { useTheme } from "theme";
import useErrorStyles from "../useErrorStyles";

const ErrorPage: React.FC<{ error: SiteError }> = ({ error }) => {
  const styles = useErrorStyles();
  const theme = useTheme();

  return (
    <Layout layoutStyle="page" height="full" backgroundColor="gray">
      <div className={styles.page}>
        <BorderBox width="content" height="content" direction="row">
          <div className={styles.allContent}>
            <div className={styles.landingPageContent}>
              <div className={styles.errorContainer}>
                <div className={styles.errorIconContainer}>
                  <Icon
                    icon="alert"
                    color={theme.palette.secondary.red}
                    iconSize={theme.scales.icon.xlarge}
                    customWidth={theme.scales.icon.xlarge}
                    customHeight={theme.scales.icon.xlarge}
                  />
                </div>
              </div>
              <div className={styles.errorContainer}>
                <InfoText textStyle="info-large-emphasis" truncate>
                  {error?.message}
                </InfoText>
              </div>
            </div>
          </div>
        </BorderBox>
      </div>
    </Layout>
  );
};

export default ErrorPage;
