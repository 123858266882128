export const resources = {
  features: "/features",
  feature: (featureName: string) => `/features/${featureName}`,
  segments: "/segments",
  segment: (segmentName: string) => `/segments/${segmentName}`,
  tenants: "/tenants",
  tenant: (tenantKey: string) => `/tenants/edit/${tenantKey}`,
  newTenant: "/tenants/create",
  suppliers: "/suppliers",
  supplier: (supplierKey: string) => `/suppliers/edit/${supplierKey}`,
  newSupplier: "/suppliers/create",
  ingredients: "/ingredients",
  ingredient: (ingredientKey: string | undefined) =>
    `/ingredients/edit/${ingredientKey}`,
  newIngredient: "/ingredients/create",
  nutrients: "/nutrients",
  nutrient: (nutrientKey: string | undefined) =>
    `/nutrients/edit/${nutrientKey}`,
  newNutrient: "/nutrients/create",
  listItem: (listItemName: string) => `/listItems/${listItemName}`,
  CNSVersions: "/CNSVersions",
  newCNSVersion: "/CNSVersions/create",
  CNSVersionsAssignTenants: "/CNSVersions/assignTenants",
  users: `/users`,
  user: (userKey: string | undefined) => `/users/edit/${userKey}`,
  userInvite: "/users/invite",
  userReactivate: (userKey: string) => `/users/reactivate/${userKey}`,
  userDeactivate: (userKey: string) => `/users/deactivate/${userKey}`,
  releaseNotes: "/release-notes",
  releaseNote: (releaseNotesKey: string | undefined) =>
    `/release-notes/edit/${releaseNotesKey}`,
  newReleaseNote: `/release-notes/create`,
  site: "/site",
  unathorizedPage: "/unathorized"
};
