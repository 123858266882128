import React, { useEffect, useReducer } from "react";
import { useTranslations } from "components/useTranslations";
import { Button, List, ListSubheader } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import AddIcon from "@mui/icons-material/Add";
import { TextField } from "components/common";
import { resources } from "api/resources";
import { useCall } from "api/useQuery";
import { Segment } from "api/models";
import { methods } from "api/methods";
import segmentManagementReducer from "./segmentManagementReducer";
import useSegmentItemsListStyles from "./useSegmentItemsListStyles";

export type SegmentItemsListProps = {
  segmentName: string;
  isNew: boolean;
};

const SegmentItemsList: React.FC<SegmentItemsListProps> = props => {
  const t = useTranslations();
  const { segmentName, isNew } = props;
  const {
    call: saveSegment,
    result: { error: segmentValidationError }
  } = useCall<Segment>();

  const [state, dispatch] = useReducer(segmentManagementReducer, {
    segment: { name: "", keys: [], features: [] },
    hasChanges: false
  });

  const {
    call: getCurrentSegment,
    result: { data: downloadedSegment }
  } = useCall<Segment>();

  useEffect(() => {
    if (segmentName && !isNew) {
      getCurrentSegment(resources.segment(segmentName), methods.GET).catch(
        () => {}
      );
    } else {
      dispatch({
        type: "init",
        segment: { name: "", keys: [], features: [] }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [segmentName, isNew]);

  useEffect(() => {
    if (downloadedSegment) {
      dispatch({
        type: "init",
        segment: downloadedSegment
      });
    }
  }, [downloadedSegment]);

  const classes = useSegmentItemsListStyles();

  const renderListItem = (key: string, index: number) => {
    return (
      <ListItem key={`list-item-${index}`}>
        <TextField
          value={key}
          onChange={event => {
            dispatch({ type: "setKey", newKeyName: event.target.value, index });
          }}
          variant="standard"
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            onClick={() => dispatch({ type: "removeKey", index })}
          >
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const renderAddItem = () => {
    return (
      <>
        <ListItem>
          <IconButton
            color="primary"
            onClick={() => dispatch({ type: "addKey" })}
          >
            <AddIcon />
          </IconButton>
        </ListItem>
      </>
    );
  };

  const onSave = () => {
    saveSegment(
      resources.segment(state.segment.name),
      methods.PUT,
      {
        name: state.segment.name,
        features: [],
        keys: state.segment.keys.filter(k => k !== "")
      },
      () => {
        dispatch({ type: "save" });
      }
    ).catch(() => {});
  };

  return (
    <>
      <div>
        <div hidden={segmentName === "" || !isNew}>
          <TextField
            id="txb-new-segment"
            label={t`__SEGMENT_DETAILS_SEGMENT_NAME`}
            className={classes.segmentName}
            value={state.segment.name}
            error={segmentValidationError !== ""}
            helperText={segmentValidationError}
            onChange={event =>
              dispatch({
                type: "setSegmentName",
                newSegmentName: event.target.value
              })
            }
          />
        </div>
        <div hidden={segmentName === ""}>
          <List
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {t`__SEGMENT_DETAILS_SEGMENT_KEYS`}
              </ListSubheader>
            }
            component="nav"
            className={classes.list}
            aria-label="segment-items-content"
          >
            {state.segment.keys.map((key, index) => {
              return renderListItem(key, index);
            })}
            {renderAddItem()}
          </List>
          <Button
            className={classes.button}
            onClick={onSave}
            disabled={!state.hasChanges || !state.segment.name}
            variant="contained"
            color="primary"
          >
            {t`__SEGMENT_DETAILS_SAVE`}
          </Button>
        </div>
        <div hidden={segmentValidationError === ""}>
          <span id="txt-segment-validation-error">
            {segmentValidationError}
          </span>
        </div>
      </div>
    </>
  );
};

export default SegmentItemsList;
