import React, { useContext } from "react";
import { PageLoadStateContext } from "../trackPageLoadState";
import { TextField as MuiTextField, TextFieldProps } from "@mui/material";

export const TextField: React.FunctionComponent<TextFieldProps> = props => {
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
  return (
    <MuiTextField
      {...props}
      label={pageLoadState.loaded ? props.label : ""}
      value={pageLoadState.loaded ? props.value : ""}
      placeholder={pageLoadState.loaded ? props.placeholder : ""}
    >
      {props.children}
    </MuiTextField>
  );
};
