import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type InternationalCurrencyIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const InternationalCurrencyIcon: React.FC<InternationalCurrencyIconProps> =
  props => {
    const { color, decorative, label, iconSize } = props;
    const labelledBy = useUniqueId();
    const title = label && <title id={labelledBy}>{label}</title>;
    const svgSize = `${iconSize ?? 22}px`;

    return (
      <IconBox>
        <svg
          aria-hidden={decorative}
          aria-labelledby={labelledBy}
          width={svgSize}
          height={svgSize}
          role="img"
          viewBox="0 0 18 18"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          {title}
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g
              transform="translate(-1100.000000, -3047.000000)"
              stroke={color ?? "currentColor"}
              strokeWidth="1.2"
            >
              <g transform="translate(0.000000, 2880.000000)">
                <g transform="translate(1101.000000, 168.000000)">
                  <circle fillOpacity="0" fill="#D8D8D8" cx="8" cy="8" r="8" />
                  <g
                    transform="translate(3.800000, 4.400000)"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g transform="translate(1.000000, 0.000000)">
                      <line x1="6.4" y1="2.28571429" x2="0" y2="2.28571429" />
                      <line
                        x1="0"
                        y1="2.28571429"
                        x2="1.97434785"
                        y2="0.311366441"
                      />
                    </g>
                    <g transform="translate(3.900000, 5.900000) rotate(-180.000000) translate(-3.900000, -5.900000) translate(0.400000, 4.400000)">
                      <line
                        x1="0"
                        y1="2.28571429"
                        x2="1.97434785"
                        y2="0.311366441"
                      />
                      <line
                        x1="6.4"
                        y1="2.28571429"
                        x2="7.27418126e-13"
                        y2="2.28571429"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </IconBox>
    );
  };

export default InternationalCurrencyIcon;
