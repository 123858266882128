import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(theme => ({
  popover: {
    // Need to disable pointer events on the popover,
    // otherwise as soon as it's loaded we were firing
    // the mouseLeave from the <span>
    pointerEvents: "none",
    zIndex: theme.zIndex.modal
  },
  tooltipContainer: {
    padding: theme.scales.spacing.small
  }
}));
