import { useTranslations } from "components/useTranslations";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Autocomplete,
  Grid,
  Paper,
  Select,
  MenuItem,
  Snackbar,
  FormControl,
  Tooltip,
  IconButton,
  TextField as MTextField,
  SnackbarCloseReason
} from "@mui/material";
import { TextField, InputLabel, FormHelperText } from "components/common/";
import { Header2, PageLoadStateContext } from "components/common";
import PrimaryButton from "components/common/PrimaryButton";
import { methods } from "api/methods";
import { resources } from "api/resources";
import { Nutrient } from "api/models";
import { useApi } from "api/useApi";
import Alert from "@mui/material/Alert";
import InfoIcon from "components/common/Icon/InfoIcon";
import ConfirmationModal from "components/common/ConfirmationModal";
import { useTheme } from "../../../../theme/index";
import useNutrientsDetailsFormStyles from "./useNutrientsDetailsFormStyles";
import useNutrientDetailsFormReducer, {
  NutrientDetailsFormActionPayloadType,
  NutrientDetailsFormActionType,
  ValidationDetails
} from "./useNutrientDetailsFormReducer";

export type NutrientsDetailsFormProps = {
  nutrient?: Nutrient;
};

const NutrientsDetailsForm: React.FC<NutrientsDetailsFormProps> = props => {
  const styles = useNutrientsDetailsFormStyles();
  const execute = useApi();
  const { nutrient } = props;
  const t = useTranslations();
  const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);

  const [state, dispatch] = useNutrientDetailsFormReducer();

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const theme = useTheme();

  // State whether form successful
  const [success, setSuccess] = useState(false);
  // State whether form busy
  const [busy, setBusy] = useState(false);

  const setDefaultPackageToNutrientPackages = useCallback((packageKey?: string) => {
    const originalValues: string[] = nutrient?.nutrientPackages
      ? nutrient?.nutrientPackages.slice()
      : [];
    const newValues = originalValues;
    if (packageKey && !originalValues?.includes(packageKey)) {
      newValues.push(packageKey);
    }

    return newValues;
  }, [nutrient]);

  // On page load logic
  useEffect(() => {
    const updatedNutrientPackages = setDefaultPackageToNutrientPackages(
      nutrient?.defaultNutrientPackageKey
    );
    dispatch({
      type: NutrientDetailsFormActionType.ON_PAGE_LOAD,
      payload: {
        key: nutrient?.key,
        formDetails: {
          nutrientName: nutrient?.name ?? "",
          nutrientCode: nutrient?.code ?? "",
          nutrientOvsName: nutrient?.ovsName ?? "",
          nutrientUnit: nutrient?.unit ?? "",
          nutrientUnitKey: nutrient?.unitKey ?? "",
          numeratorNutrientKey: nutrient?.numeratorNutrientKey ?? undefined,
          denominatorNutrientKey: nutrient?.denominatorNutrientKey ?? undefined,
          nutrientPackages: updatedNutrientPackages
        },
        nutrientUnits: nutrient?.unitOptions,
        nutrientPackageOptions: nutrient?.nutrientPackageOptions,
        nutrientOptions: nutrient?.nutrientOptions
      }
    });
  }, [nutrient, dispatch, setDefaultPackageToNutrientPackages]);

  const handleMultipleSelectAppendOnly = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options: lastSelection } = event.target as HTMLSelectElement;
    const originalValues: string[] = nutrient?.nutrientPackages
      ? nutrient?.nutrientPackages.slice()
      : [];
    const newValues = originalValues;
    for (let i = 0, l = lastSelection.length; i < l; i++) {
      if (lastSelection[i].selected) {
        if (!newValues.includes(lastSelection[i].value)) {
          newValues.push(lastSelection[i].value);
        }
      }
      if (newValues.length === 0 && nutrient?.defaultNutrientPackageKey) {
        newValues.push(nutrient?.defaultNutrientPackageKey);
      }
    }
    dispatch({
      type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
      payload: {
        key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
        value: { ...state.formDetails, nutrientPackages: newValues }
      }
    });
    return newValues;
  };

  const handleMultipleSelect = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { options } = event.target as HTMLSelectElement;
    const selected_values: string[] = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selected_values.push(options[i].value);
      }
      if (selected_values.length === 0 && nutrient?.defaultNutrientPackageKey) {
        selected_values.push(nutrient?.defaultNutrientPackageKey);
      }
    }
    dispatch({
      type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
      payload: {
        key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
        value: { ...state.formDetails, nutrientPackages: selected_values }
      }
    });
    return selected_values;
  };

  // Save Button
  const onClick = () => {
    if (nutrient?.key) {
      if (!confirmationModalIsOpen) setConfirmationModalIsOpen(true);
    } else {
      onSave();
    }
  };

  // Save Action
  const onSave = () => {
    const formValues: Nutrient = {
      key: state.key ?? "",
      name: state.formDetails?.nutrientName ?? "",
      code: state.formDetails?.nutrientCode ?? "",
      ovsName: state.formDetails?.nutrientOvsName ?? "",
      unit: state.formDetails?.nutrientUnit,
      unitKey: state.formDetails?.nutrientUnitKey,
      numeratorNutrientKey: state.formDetails?.numeratorNutrientKey ?? null,
      denominatorNutrientKey: state.formDetails?.denominatorNutrientKey ?? null,
      nutrientPackages: state.formDetails?.nutrientPackages ?? []
    };
    const creating = !nutrient?.key;
    setBusy(true);
    execute(
      nutrient?.key
        ? `${resources.nutrient(nutrient?.key)}`
        : `${resources.nutrients}/create`,
      nutrient?.key ? methods.PUT : methods.POST,
      formValues
    )
      .then((response: Nutrient) => {
        setBusy(false);
        dispatch({
          type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
          payload: {
            key: NutrientDetailsFormActionPayloadType.VALIDATION_DETAILS,
            value: {
              nutrientNameValidationMessage:
                response.nameValidationMessage ?? "",
              nutrientCodeValidationMessage:
                response.codeValidationMessage ?? "",
              nutrientUnitValidationMessage:
                response.unitValidationMessage ?? "",
              nutrientNumeratorValidationMessage:
                response.numeratorValidationMessage ?? "",
              nutrientDenominatorValidationMessage:
                response.denominatorValidationMessage ?? "",
              nutrientOvsNameValidationMessage:
                response.nutrientOvsNameValidationMessage ?? ""
            }
          }
        });
        if (response?.isInvalid) {
          setSuccess(false);
          return;
        }
        dispatch({
          type: NutrientDetailsFormActionType.ON_PAGE_LOAD,
          payload: creating
            ? {
                isFormDirty: false,
                formDetails: {
                  nutrientName: "",
                  nutrientCode: "",
                  nutrientUnit: "",
                  nutrientOvsName: "",
                  nutrientUnitKey: "",
                  numeratorNutrientKey: "",
                  denominatorNutrientKey: "",
                  nutrientPackages: []
                },
                validationDetails: {
                  nutrientNameValidationMessage: "",
                  nutrientCodeValidationMessage: "",
                  nutrientUnitValidationMessage: "",
                  nutrientNumeratorValidationMessage: "",
                  nutrientDenominatorValidationMessage: ""
                },
                key: "",
                nutrientUnits: response?.unitOptions,
                nutrientPackageOptions: response?.nutrientPackageOptions,
                nutrientOptions: response?.nutrientOptions
              }
            : {
                key: response?.key,
                formDetails: {
                  nutrientName: response?.name ?? "",
                  nutrientCode: response?.code ?? "",
                  nutrientOvsName: response?.ovsName ?? "",
                  nutrientUnit: response?.unit ?? "",
                  nutrientUnitKey: response?.unitKey ?? "",
                  numeratorNutrientKey:
                    response?.numeratorNutrientKey ?? undefined,
                  denominatorNutrientKey:
                    response?.denominatorNutrientKey ?? undefined,
                  nutrientPackages: response?.nutrientPackages
                    ? response?.nutrientPackages
                    : []
                },
                validationDetails: {
                  nutrientNameValidationMessage: "",
                  nutrientCodeValidationMessage: "",
                  nutrientUnitValidationMessage: "",
                  nutrientTypeValidationMessage: "",
                  nutrientNumeratorValidationMessage: "",
                  nutrientDenominatorValidationMessage: ""
                } as ValidationDetails,
                nutrientUnits: response?.unitOptions,
                nutrientPackageOptions: response?.nutrientPackageOptions,
                nutrientOptions: response?.nutrientOptions
              }
        });
        setSuccess(true);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setBusy(false);
      });
  };

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  const unitOptions = state?.nutrientUnits?.map(unitOption => (
    <MenuItem
      key={unitOption.keyValue}
      value={unitOption.keyValue}
      onClick={() => {
        setAnchorEl(null);
        dispatch({
          type: NutrientDetailsFormActionType.CHANGE_UNIT,
          payload: {
            key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
            value: {
              ...state.formDetails,
              nutrientUnitKey: unitOption.keyValue,
              nutrientUnit: unitOption.displayValue
            }
          }
        });
      }}
    >
      {unitOption.displayValue}
    </MenuItem>
  ));

  return (
    <div
      className={
        nutrient?.key
          ? styles.masterContainerEdit
          : styles.masterContainerCreate
      }
    >
      <ConfirmationModal
        isOpen={confirmationModalIsOpen}
        setIsOpen={setConfirmationModalIsOpen}
        callback={onSave}
      />
      <Paper className={styles.pageContent}>
        <form className={styles.form}>
          {/* First Row: form heading */}
          <Grid container>
            <Grid item xs={12} className={styles.spaceChildrenApart}>
              <Header2>{t`__NUTRIENT_DETAILS_SUBTITLE`}</Header2>
              <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={success}
                autoHideDuration={6000}
                onClose={handleClose}
              >
                <Alert elevation={6} variant="filled" onClose={handleClose} severity="success">
                  {t`__NUTRIENT_DETAILS_SAVED`}
                </Alert>
              </Snackbar>

              <PrimaryButton
                disabled={!state.isFormDirty || busy}
                onClick={onClick}
                name="saveNutrient"
              >
                {t`__NUTRIENT_DETAILS_SAVE`}
              </PrimaryButton>
            </Grid>
          </Grid>
          {/* Second Row: name, code */}
          <Grid className={styles.rowOfInputs} container>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={t`__NUTRIENT_DETAILS_NAME`}
                error={
                  state.validationDetails?.nutrientNameValidationMessage !== ""
                }
                helperText={
                  state.validationDetails?.nutrientNameValidationMessage
                }
                onChange={e => {
                  dispatch({
                    type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
                    payload: {
                      key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
                      value: {
                        ...state.formDetails,
                        nutrientName: e.target.value
                      }
                    }
                  });
                }}
                value={state.formDetails?.nutrientName || ""}
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <TextField
                fullWidth
                size="small"
                variant="outlined"
                label={t`__NUTRIENT_DETAILS_CODE`}
                error={
                  state.validationDetails?.nutrientCodeValidationMessage !== ""
                }
                helperText={
                  state.validationDetails?.nutrientCodeValidationMessage
                }
                onChange={e => {
                  dispatch({
                    type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
                    payload: {
                      key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
                      value: {
                        ...state.formDetails,
                        nutrientCode: e.target.value
                      }
                    }
                  });
                }}
                value={state.formDetails?.nutrientCode || ""}
              />
            </Grid>
          </Grid>
          {/* Third Row: units and packages */}
          <Grid
            className={styles.rowOfInputs}
            container
            direction="row"
          >
            <Grid container item direction="column" md={4} xs={12} className={styles.itemContainer}>
              <Grid item>
                <InputLabel className={styles.label} id="nutrient-unit-label">
                  {t`__NUTRIENT_DETAILS_UNIT`}
                </InputLabel>
                <Select
                  labelId="nutrient-unit-label"
                  id="nutrient-unit-select"
                  error={
                    state.validationDetails?.nutrientUnitValidationMessage !==
                    ""
                  }
                  size="small"
                  MenuProps={{ classes: { paper: styles.menuPaper } }}
                  onClose={() => {
                    setAnchorEl(null);
                  }}
                  onOpen={() => {
                    setAnchorEl(anchorEl);
                  }}
                  value={state?.formDetails?.nutrientUnitKey}
                  fullWidth
                >
                  {unitOptions}
                </Select>
                <FormHelperText
                  variant="outlined"
                  error={
                    state.validationDetails?.nutrientUnitValidationMessage !==
                    ""
                  }
                >
                  {state.validationDetails?.nutrientUnitValidationMessage}
                </FormHelperText>
              </Grid>
              <Grid item>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  label={t`__NUTRIENT_DETAILS_OVS_NAME`}
                  error={
                    state.validationDetails
                      ?.nutrientOvsNameValidationMessage !== undefined &&
                    state.validationDetails
                      ?.nutrientOvsNameValidationMessage !== ""
                  }
                  helperText={
                    state.validationDetails?.nutrientOvsNameValidationMessage
                  }
                  onChange={e => {
                    dispatch({
                      type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
                      payload: {
                        key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
                        value: {
                          ...state.formDetails,
                          nutrientOvsName: e.target.value
                        }
                      }
                    });
                  }}
                  value={state.formDetails?.nutrientOvsName || ""}
                />
              </Grid>
              {state.formDetails?.nutrientUnit === "ratio" && (
                <>
                  <Grid item>
                    <InputLabel
                      className={styles.autocompleteLabel}
                      id="numerator-nutrients-label"
                    >
                      {t`__NUTRIENT_DETAILS_NUMERATOR`}
                    </InputLabel>
                    <Autocomplete
                      id="numerator-nutrients-select"
                      options={state?.nutrientOptions ?? []}
                      getOptionLabel={option => option.displayValue ?? ""}
                      value={
                        state?.nutrientOptions?.find(
                          n =>
                            n.keyValue ===
                            state.formDetails?.numeratorNutrientKey
                        ) ?? null
                      }
                      onChange={(e, value) => {
                        dispatch({
                          type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
                          payload: {
                            key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
                            value: {
                              ...state.formDetails,
                              numeratorNutrientKey: value?.keyValue
                            }
                          }
                        });
                      }}
                      renderInput={params => (
                        <MTextField
                          {...params}
                          variant="outlined"
                          placeholder={t`__NUTRIENT_DETAILS_NUMERATOR_PLACEHOLDER`}
                          size="small"
                          error={
                            state.validationDetails
                              ?.nutrientNumeratorValidationMessage !== ""
                          }
                        />
                      )}
                    />
                    <FormHelperText
                      variant="outlined"
                      error={
                        state.validationDetails
                          ?.nutrientNumeratorValidationMessage !== ""
                      }
                    >
                      {
                        state.validationDetails
                          ?.nutrientNumeratorValidationMessage
                      }
                    </FormHelperText>
                  </Grid>
                  <Grid item>
                    <InputLabel
                      className={styles.autocompleteLabel}
                      id="denominator-nutrients-label"
                    >
                      {t`__NUTRIENT_DETAILS_DENOMINATOR`}
                    </InputLabel>
                    <Autocomplete
                      id="denominator-nutrients-select"
                      options={state?.nutrientOptions ?? []}
                      getOptionLabel={option => option.displayValue ?? ""}
                      value={
                        state?.nutrientOptions?.find(
                          n =>
                            n.keyValue ===
                            state.formDetails?.denominatorNutrientKey
                        ) ?? null
                      }
                      onChange={(e, value) => {
                        dispatch({
                          type: NutrientDetailsFormActionType.CHANGE_FORM_STATE,
                          payload: {
                            key: NutrientDetailsFormActionPayloadType.FORM_DETAILS,
                            value: {
                              ...state.formDetails,
                              denominatorNutrientKey: value?.keyValue
                            }
                          }
                        });
                      }}
                      renderInput={params => (
                        <MTextField
                          {...params}
                          variant="outlined"
                          style={{ marginLeft: 0 }}
                          placeholder={t`__NUTRIENT_DETAILS__DENOMINATOR_PLACEHOLDER`}
                          size="small"
                          error={
                            state.validationDetails
                              ?.nutrientDenominatorValidationMessage !== ""
                          }
                        />
                      )}
                    />
                    <FormHelperText
                      variant="outlined"
                      error={
                        state.validationDetails
                          ?.nutrientDenominatorValidationMessage !== ""
                      }
                    >
                      {
                        state.validationDetails
                          ?.nutrientDenominatorValidationMessage
                      }
                    </FormHelperText>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item md={4} xs={12} mt={1}>
              <InputLabel shrink htmlFor="nutrientPackages">
                &nbsp;&nbsp;
              </InputLabel>
              <FormControl fullWidth>
                <InputLabel
                  shrink
                  htmlFor="nutrientPackages"
                  className={styles.labelMultipleSelect}
                >
                  &nbsp;&nbsp;{t`__NUTRIENT_DETAILS_NUTRIENT_PACKAGES`}
                  <Tooltip title={t`__NUTRIENT_DETAILS_SELECTOR_CTRL_HINT`}>
                    <IconButton className={styles.multiSelectInfoTool}>
                      <InfoIcon
                        iconSize={12}
                        color={theme.palette.primary.fsBlue}
                      />
                    </IconButton>
                  </Tooltip>
                </InputLabel>
                <Select<string[]>
                  multiple
                  native
                  label="nutrientPackages"
                  variant="outlined"
                  value={state.formDetails?.nutrientPackages ?? [""]}
                  onChange={event => {
                    if (!nutrient?.key) {
                      // @ts-ignore Typings are not considering `native`
                      handleMultipleSelect(event);
                    } else {
                      // @ts-ignore Typings are not considering `native`
                      handleMultipleSelectAppendOnly(event);
                    }
                  }}
                >
                  {state?.nutrientPackageOptions?.map(pkg => (
                    <option key={pkg.keyValue} value={pkg.keyValue}>
                      {pkg.displayValue}
                    </option>
                  ))}
                </Select>
              </FormControl>
              <small className={styles.reminderText}>
                {pageLoadState.loaded
                  ? t`__NUTRIENT_DETAILS_NUTRIENT_PACKAGES_HELPER`
                  : ""}
              </small>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default NutrientsDetailsForm;
