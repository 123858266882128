import React, { useContext, useEffect, useState } from "react";
import { TranslationProvider } from "components/useTranslations";
import { getAuthProvider } from "identity/AuthProvider";
import AzureAD, {
  AuthenticationState,
  IAzureADFunctionProps
} from "react-aad-msal";
import { ConfigContext } from "config";
import { PageLoadStateProvider } from "components/common";
import LoginPage from "../components/pages/LoginPage";

export type Identity = {
  getAccessToken: () => Promise<string | null>;
  logout: () => void;
};

export const IdentityContext = React.createContext<Identity>({
  getAccessToken: async () => Promise.resolve(null),
  logout: () => null
});

export type IdentityState = {
  helpUrl: string;
  eulaUrl: string;
  privacyPolicyUrl: string;
  translations?: { [key: string]: string } | undefined;
};

const initState: IdentityState = {
  helpUrl: "https://www.formatsolutions.com/en/contact-us",
  eulaUrl: "https://www.formatsolutions.com/en/cargill-legal-terms-conditions",
  privacyPolicyUrl: "https://www.cargill.com/page/privacy-center",
  translations: undefined
};

const IdentityProvider: React.FC<{ children: React.ReactNode }> = props => {
  const config = useContext(ConfigContext);
  const [isAuthProviderReady, setIsAuthProviderReady] =
    useState<boolean>(false);
  const [state, setState] = useState<IdentityState>(initState);
  useEffect(() => {
    // normally all http calls go through useApi but it needs a token to succeed
    // this call is expected to always get a 401 and is just to load the translations and help link

    fetch(config?.reactAppApiEndpoint ?? "").then(
      res => {
        res.json().then(
          (data: IdentityState) => {
            setState(data);
          },
          () => {}
        );
      },
      () => {}
    );
    window.configPromise
      .then(() => {
        setIsAuthProviderReady(true);
      })
      .catch(() => {});
  }, [config]);

  const getViewBasedOnAuthenticationState = ({
    login,
    logout,
    authenticationState,
    error
  }: IAzureADFunctionProps) => {
    const authProvider = getAuthProvider();
    if (authenticationState === AuthenticationState.Authenticated) {
      return (
        <IdentityContext.Provider
          value={{
            getAccessToken: async () => {
              return (await authProvider?.getAccessToken())?.accessToken;
            },
            logout
          }}
        >
          {props.children}
        </IdentityContext.Provider>
      );
    }
    const isAuthenticating =
      authenticationState === AuthenticationState.InProgress;
    return (
      <PageLoadStateProvider name="login" isLoaded={!!state.translations}>
        <TranslationProvider translations={state.translations}>
          {() => (
            <LoginPage
              isAuthenticating={isAuthenticating}
              login={login}
              error={error}
            />
          )}
        </TranslationProvider>
      </PageLoadStateProvider>
    );
  };

  return (
    <>
      {isAuthProviderReady && (
        <AzureAD provider={getAuthProvider()}>
          {getViewBasedOnAuthenticationState}
        </AzureAD>
      )}
    </>
  );
};

export default IdentityProvider;
