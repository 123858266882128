import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type RefreshIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const RefreshIcon: React.FC<RefreshIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = props.iconSize ?? "16px";

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 18 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1142.000000, -2803.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(1143.000000, 134.000000)">
                <g>
                  <polyline points="16 0.727272727 16 5.09090909 11.6363636 5.09090909" />
                  <polyline points="0 12.3636364 0 8 4.36363636 8" />
                  <path d="M1.82545455,4.36363636 C2.57518664,2.24495393 4.35946703,0.659067814 6.55148125,0.16309692 C8.74349547,-0.332873974 11.0366842,0.33043326 12.6254545,1.92 L16,5.09090909 M0,8 L3.37454545,11.1709091 C4.96331575,12.7604758 7.25650453,13.4237831 9.44851875,12.9278122 C11.640533,12.4318413 13.4248134,10.8459552 14.1745455,8.72727273" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default RefreshIcon;
