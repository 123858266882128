import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type FlagIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const LockIcon: React.FC<FlagIconProps> = props => {
  const { color, decorative, label, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = `${iconSize ?? 22}px`;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 8 9"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1233.000000, -3123.000000)"
            stroke={color ?? "currentColor"}
          >
            <g transform="translate(0.000000, 2880.000000)">
              <g transform="translate(1234.000000, 244.000000)">
                <rect
                  strokeWidth="0.777777778"
                  fill={color ?? "currentColor"}
                  x="0"
                  y="3.5"
                  width="6"
                  height="4"
                  rx="0.777777778"
                />
                <path
                  d="M1.3,4 L1.3,1.66666667 C1.3,0.746192084 2.04619208,0 2.96666667,0 C3.88714125,0 4.63333333,0.746192084 4.63333333,1.66666667 L4.63333333,4"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default LockIcon;
