import { AppTheme } from "theme";
import { createTheme, ThemeOptions } from "@mui/material";

const theme = new AppTheme();

const options = {
  components: {
    MuiSvgIcon: {
      /* Calendar Icon color next/prev motn icon */
      styleOverrides: {
        root: {
          fill: `${theme.palette.primary.fsBlue}`,
        },
      }
    },
    MuiPickersDay: {
      styleOverrides: {
        /* Current Day */
        today: {
          color: `${theme.palette.primary.fsBlue}`,
          fontWeight: `${theme.font.bold.fontWeight}`,
          border: "none !important"
        },

        /* Selected Day */
        root: {
          "&.Mui-selected": {
            backgroundColor: `${theme.palette.primary.fsBlue} !important`,
            color: `${theme.palette.white}`,
            fontWeight: `${theme.font.bold.fontWeight}`
          }
        }
      }
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          /* Text Border Color on Focus  */
          "&:after": {
            borderBottomColor: `${theme.palette.primary.fsBlue} !important`
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        /* Validation Message */
        root: {
          color: `${theme.palette.secondary.red} !important`
        }
      }
    }
  },
} as ThemeOptions;

const dateInputTheme = createTheme(options);

export default dateInputTheme;
