import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => ({
  table: {
    "& .MuiTableCell-root": {
      padding: "5px 20px"
    },
    minWidth: 650
  },
  descriptionContent: {
    display: "block",
    width: "100%"
  },
  tandemNotificationGridItem: {
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    display: "inline-flex"
  },
  smallTextField: {
    maxWidth: "30px"
  },
  smallBold: {
    fontSize: "10px"
  },
  smallInputField: {
    maxWidth: "100px"
  },
  flexCenter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  descLiteral: {
    marginLeft: "1vh"
  }
}));
