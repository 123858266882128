import React from "react";
import { routes } from "routes";
import { useTheme } from "theme";
import { Divider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { AppTypography, Match, PageLoadStateProvider } from "components/common";
import { useTranslations } from "components/useTranslations";
import useSideNavStyles from "./useSideNavStyles";
import useSpecificSideNavStyles from "./useSpecificSideNavStyles";
import SideNavButton from "./SideNavButton";
import SideNavSubMenuLink from "./SideNavSubMenuLink";
import useSideNavReducer from "./useSideNavReducer";

export type SideNavProps = {
  translations?: { [key: string]: string } | undefined;
  canViewAllAdminOptions?: boolean | undefined;
  canViewOnlyReleaseNotes?: boolean | undefined;
};

const SideNav: React.FC<SideNavProps> = props => {
  const styles = useSideNavStyles();
  const specificStyles = useSpecificSideNavStyles();
  const [state, dispatch] = useSideNavReducer({
    openSection: undefined
  });

  const theme = useTheme();
  const t = useTranslations(props.translations);

  const subMenuStyles = state.openSection
    ? styles.sideNavSubMenu
    : styles.sideNavSubMenuClosed;

  

  return (
    <PageLoadStateProvider isLoaded={!!props.translations}>
      <div className={styles.sideNavRoot}>
        <>
          <div className={styles.sideNavMainMenu}>
            <div className={styles.sideNavButtonContainer}>
              <Match path={routes.home}>
                {match => (
                  <SideNavButton
                    action={{ type: "SUBMENU_CLOSE" }}
                    dispatch={dispatch}
                    link={routes.home}
                    icon="home"
                    selected={match !== null}
                    name="Dashboard"
                  >
                    {t`__NAVIGATION_DASHBOARD`}
                  </SideNavButton>
                )}
              </Match>
            </div>
            <div className={styles.sideNavButtonContainer}>
              <Match path={routes.data}>
                {match => (
                  <SideNavButton
                    action={{ type: "SUBMENU_TOGGLE_DATA" }}
                    dispatch={dispatch}
                    subMenuOpen={state.openSection === "data"}
                    icon="list"
                    selected={match !== null}
                    name="Data"
                  >
                    {t`__NAVIGATION_DATA_TILE`}
                  </SideNavButton>
                )}
              </Match>
            </div>
          </div>
          <div
            className={subMenuStyles}
            aria-expanded={state.openSection !== undefined}
          >
            {state.openSection === "data" && (
              <>
                <div className={styles.subNavSubMenuHeader}>
                  <AppTypography>
                    <strong>{t`__NAVIGATION_DATA_TITLE`}</strong>
                  </AppTypography>
                  <CloseIcon
                    onClick={() => {
                      dispatch({ type: "SUBMENU_TOGGLE_DATA" });
                    }}
                    className={specificStyles.closeButton}
                  />
                </div>
                <Divider
                  variant="middle"
                  style={{
                    // ClassName does not work for some reason.
                    backgroundColor: theme.palette.grays.neutralCoolTransparent
                  }}
                />
                <div className={styles.sideNavSubMenuLinks}>
                  <div className={styles.sideNavSubMenuLinkHeader}>
                    <AppTypography variant="subtitle2">
                      <strong className={specificStyles.sideNavLinksHeader}>
                        {t`__NAVIGATION_DATA_SUBTITLE`}
                      </strong>
                    </AppTypography>
                  </div>

                  <div className={specificStyles.sideNavLinkText}>
                    {props?.canViewAllAdminOptions && (
                      <>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.CNSVersions}
                            content={t`__NAVIGATION_CNS_VERSIONS`}
                            dispatch={dispatch}
                          />
                        </div>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.users}
                            content={t`__NAVIGATION_EDIT_USER`}
                            dispatch={dispatch}
                          />
                        </div>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.features}
                            content={t`__NAVIGATION_FEATURES`}
                            dispatch={dispatch}
                          />
                        </div>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.ingredients}
                            content={t`__NAVIGATION_INGREDIENTS`}
                            dispatch={dispatch}
                          />
                        </div>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.nutrients}
                            content={t`__NAVIGATION_NUTRIENTS`}
                            dispatch={dispatch}
                          />
                        </div>
                      </>
                    )}
                    {(props?.canViewAllAdminOptions ||
                      props?.canViewOnlyReleaseNotes) && (
                      <>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.releaseNotes}
                            content={t`__NAVIGATION_RELEASE_NOTES`}
                            dispatch={dispatch}
                          />
                        </div>
                      </>
                    )}
                    {props?.canViewAllAdminOptions && (
                      <>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.segments}
                            content={t`__NAVIGATION_SEGMENTS`}
                            dispatch={dispatch}
                          />
                        </div>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.suppliers}
                            content={t`__NAVIGATION_SUPPLIERS`}
                            dispatch={dispatch}
                          />
                        </div>
                        <div className={styles.sideNavSubMenuLinkWrap}>
                          <SideNavSubMenuLink
                            link={routes.tenants}
                            content={t`__NAVIGATION_TENANTS`}
                            dispatch={dispatch}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </>
      </div>
    </PageLoadStateProvider>
  );
};

export default SideNav;
