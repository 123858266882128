import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(t => ({
  root: {
    position: "absolute",
    top: 0,
    left: 0,
    border: "solid 2px",
    borderColor: t.palette.secondary.red,
    height: "100%",
    width: "100%",
    color: "none",
    pointerEvents: "none",
    boxSizing: "border-box"
  },
  messageHost: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: t.palette.secondary.red,
    alignItems: "center",
    padding: [t.scales.spacing.small, t.scales.spacing.normal],
    pointerEvents: "auto"
  },
  message: {
    flexGrow: 1,
    color: t.palette.white
  },
  close: {
    flexShrink: 1,
    height: 24,
    width: 24,
    padding: 0,
    margin: 0,
    borderWidth: 0,
    backgroundColor: t.palette.transparent,
    color: t.palette.white
  },
  open: {
    backgroundColor: t.palette.secondary.red,
    borderWidth: 0,
    height: 24,
    width: 24,
    pointerEvents: "auto",
    float: "right",
    borderRadius: 0,
    color: t.palette.white,
    padding: 0,
    margin: 0
  }
}));
