import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type CaretUpIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
};

const CaretUpIcon: React.FC<CaretUpIconProps> = props => {
  const { label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="12px"
        height="6px"
        viewBox="0 0 12 6"
        version="1.1"
        className={props.className}
      >
        <title>{title}</title>
        <g
          id="Phase-2-–-Data-Mgmt-4"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Users-1.2.0"
            transform="translate(-1187.000000, -582.000000)"
            fill={color ?? "currentColor"}
            fillRule="nonzero"
          >
            <g transform="translate(120.000000, 396.000000)">
              <g transform="translate(0.000000, 162.000000)">
                <g>
                  <g transform="translate(1001.000000, 15.000000)">
                    <g transform="translate(72.000000, 12.000000) rotate(-180.000000) translate(-72.000000, -12.000000) translate(66.000000, 9.000000)">
                      <path
                        d="M11.4554652,0 L0.54653976,0 C0.0605990287,0 -0.182760986,0.53857096 0.160851001,0.853553391 L5.61531374,5.85355339 C5.82832376,6.04881554 6.17368124,6.04881554 6.38669126,5.85355339 L11.841154,0.853553391 C12.184766,0.53857096 11.941406,0 11.4554652,0 Z"
                        id="Path"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default CaretUpIcon;
