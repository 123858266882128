import { HttpStatusCode, SiteError } from "api/models";
import React from "react";
import ErrorIndicator from "./ErrorIndicator";
import ErrorPage from "./ErrorPage";

const Errors: React.FC<{ error: SiteError }> = ({ error }) => {
  switch (error.httpStatus) {
    case HttpStatusCode.Gone:
    case HttpStatusCode.Forbidden:
      return <ErrorIndicator error={error} />;
    case HttpStatusCode.NotFound:
    case HttpStatusCode.Locked:
      return <ErrorPage error={error} />;
    case HttpStatusCode.InternalServerError:
    case undefined:
      return <ErrorIndicator error={error} />;
    default:
  }
  return null;
};

export default Errors;
