import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ToolIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const DocumentIcon: React.FC<ToolIconProps> = props => {
  const { color, decorative, label, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = iconSize ?? 17;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        role="img"
        width={svgSize}
        height={svgSize}
        viewBox="0 0 14 17"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-1017.000000, -2803.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.5"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(1018.000000, 134.000000)">
                <g>
                  <path
                    d="M7.5,0 L1.5,0 C0.671572875,0 0,0.671572875 0,1.5 L0,13.5 C0,14.3284271 0.671572875,15 1.5,15 L10.5,15 C11.3284271,15 12,14.3284271 12,13.5 L12,4.5 L7.5,0 Z"
                    id="Path"
                  />
                  <polyline id="Path" points="7.5 0 7.5 4.5 12 4.5" />
                  <line x1="9" y1="8.25" x2="3" y2="8.25" id="Path" />
                  <line x1="9" y1="11.25" x2="3" y2="11.25" id="Path" />
                  <polyline id="Path" points="4.5 5.25 3.75 5.25 3 5.25" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default DocumentIcon;
