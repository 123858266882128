import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type HomeIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const HomeIcon: React.FC<HomeIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        height="22px"
        role="img"
        version="1.1"
        viewBox="0 0 20 22"
        width="20px"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-19.000000, -83.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.5"
          >
            <g transform="translate(0.000000, 70.000000)">
              <g>
                <g>
                  <g transform="translate(20.000000, 14.000000)">
                    <path
                      d="M0,7 L9,0 L18,7 L18,18 C18,19.1045695 17.1045695,20 16,20 L2,20 C0.8954305,20 0,19.1045695 0,18 L0,7 Z"
                      id="Path"
                    />
                    <polyline id="Path" points="6 20 6 10 12 10 12 20" />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default HomeIcon;
