import React from "react";
import useLayoutStyles from "./useLayoutStyles";
import { LayoutProps } from "./LayoutTypes";

const Layout: React.FC<LayoutProps> = props => {
  const styledProps: LayoutProps = {};
  switch (props.layoutStyle) {
    case "page":
      styledProps.padding = {
        top: "medium",
        left: "large",
        right: "large",
        bottom: "large",
        ...props.padding
      };
      styledProps.layoutOrientation = "vertical";
      break;
    case "page-form":
      styledProps.padding = {
        top: 0,
        left: 0,
        right: 0,
        bottom: "medium",
        ...props.padding
      };
      styledProps.layoutOrientation = "vertical";
      break;
    case "form-top-left":
      styledProps.padding = {
        top: "medium",
        left: "medium",
        bottom: 0,
        right: 0,
        ...props.padding
      };
      styledProps.layoutOrientation = "vertical";
      break;
    case "form":
      styledProps.padding = {
        top: "medium",
        left: "medium",
        bottom: "medium",
        right: 0,
        ...props.padding
      };
      styledProps.layoutOrientation = "vertical";
      break;
    case "form-uniform":
      styledProps.padding = {
        top: "medium",
        left: "medium",
        bottom: "medium",
        right: "medium",
        ...props.padding
      };
      styledProps.layoutOrientation = "vertical";
      break;
    case "form-content":
      styledProps.padding = {
        top: 0,
        left: 0,
        bottom: 0,
        right: 0
      };
      styledProps.layoutOrientation = "vertical";
      break;
    case "modal":
      styledProps.padding = {
        top: "medium",
        left: "medium",
        bottom: "medium",
        right: "medium"
      };
      styledProps.minHeight = 0; // Constraining flex content within it's parent requires setting a min-height
      break;
    case undefined:
    case null:
    default:
      break;
  }

  const styles = useLayoutStyles({ ...styledProps, ...props });
  return <div className={styles.layout}>{props.children}</div>;
};

export default Layout;
