import React from "react";
import { resources } from "api/resources";
import { useQuery } from "api/useQuery";
import { CNSVersionDetails } from "api/models";
import { TranslationProvider } from "components/useTranslations";
import { PageLoadStateProvider } from "components/common";
import CNSVersionDetailsForm from "./CNSVersionDetailsForm";
import CNSVersionDetailsHeader from "./CNSVersionDetailsHeader";

const CNSVersionDetailsPage: React.FC = () => {
  const { data: response } = useQuery<CNSVersionDetails>(resources.newCNSVersion);

  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <TranslationProvider translations={response?.translations}>
        {() => (
          <>
            <CNSVersionDetailsHeader />
            <CNSVersionDetailsForm pageData={response} />
          </>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default CNSVersionDetailsPage;
