import React from "react";
import useIconBoxStyles from "./useIconBoxStyles";
import { IconBoxProps } from "./IconBoxTypes";

const IconBox: React.FC<IconBoxProps> = props => {
  const classes = useIconBoxStyles(props);

  return <div className={classes.icon}>{props.children}</div>;
};

export default IconBox;
