import React, { Dispatch, SetStateAction } from "react";
import { useTranslations } from "components/useTranslations";
import ActionModal from "components/common/ActionModal";
import { PrimaryButton, SecondaryButton } from "components/common";

export type ConfirmationModalProps = {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  callback: () => void;
};

const ConfirmationModal: React.FC<ConfirmationModalProps> = (props) => {
  const t = useTranslations();
  const { isOpen, setIsOpen, callback } = props;

  return (
    <ActionModal
      name="confirmation_modal"
      isOpen={isOpen}
      closeAction={() => setIsOpen(false)}
      closeDispatch={setIsOpen}
      title={t`__WARNING_SAVE_CHANGES_TITLE`}
      maxWidth="sm"
      buttonBayRender={() => (
        <>
          <SecondaryButton
            name="confirmation_modal_cancel_button"
            isBorderless
            title={t`__WARNING_SAVE_CHANGES_CANCEL`}
            onClick={() => setIsOpen(false)}
          />
          <PrimaryButton
            name="confirmation_modal_yes_button"
            title={t`__WARNING_SAVE_CHANGES_SAVE`}
            onClick={() => {
              callback();
              setIsOpen(false);
            }}
          />
        </>
      )}
    >
      {t`__WARNING_SAVE_CHANGES_TEXT`}
    </ActionModal>
  );
};

ConfirmationModal.defaultProps = {
  isOpen: false,
};

export default ConfirmationModal;
