import React, { useEffect, useState } from "react";
import { ReleaseNote } from "api/models";
import { methods } from "api/methods";
import { resources } from "api/resources";
import { useApi } from "api/useApi";
import { PageLoadStateProvider } from "components/common";
import { TranslationProvider } from "components/useTranslations";
import ReleaseNotesDetailsForm from "./ReleaseNotesDetailsForm";
import ReleaseNotesDetailsHeader from "./ReleaseNotesDetailsHeader";
import { useParams } from "react-router";

const ReleaseNotesDetailsPage: React.FC = () => {
  // To Access URL
  const params = useParams();
  const execute = useApi();

  const [releaseNote, setReleaseNote] = useState<ReleaseNote>();
  // Below is a reference to a release notes which will be edited.

  useEffect(() => {
    getReleaseNoteState();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.releaseNotesKey]);

  const getReleaseNoteState = () => {
    if (params.releaseNotesKey) {
      execute(resources.releaseNote(params.releaseNotesKey), methods.GET)
        .then((response: ReleaseNote) => {
          setReleaseNote(response);
        })
        .catch(() => {});
    } else {
      execute(resources.newReleaseNote, methods.GET)
        .then((response: ReleaseNote) => {
          setReleaseNote(response);
        })
        .catch(() => {});
    }
  };

  return (
    <PageLoadStateProvider isLoaded={!!releaseNote?.translations}>
      <TranslationProvider translations={releaseNote?.translations}>
        {() => (
          <>
            {/* Assuming information will be pulled from URL */}
            <ReleaseNotesDetailsHeader releaseNote={releaseNote} />
            <ReleaseNotesDetailsForm
              releaseNote={releaseNote}
              getReleaseNotesStateHandler={getReleaseNoteState}
            />
          </>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default ReleaseNotesDetailsPage;
