import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  sideNavLinksHeader: {
    fontSize: "12px",
    color: theme.palette.grays.neutralCool,
  },
  sideNavLinkText: {
    color: "white",
    fontWeight: 600,
  },
  closeButton: {
    color: theme.palette.primary.fsBlue,
    cursor: "pointer",
  },
}));
