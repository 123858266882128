import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type AlertIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
  /**
   * maxWidth applied to IconBox component
   */
  customWidth?: number;
  /**
   * maxHeight applied to IconBox component
   */
  customHeight?: number;
};

const AlertIcon: React.FC<AlertIconProps> = props => {
  const { color, decorative, label, iconSize, customWidth, customHeight } =
    props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = `${iconSize ?? 22}px`;

  return (
    <IconBox customWidth={customWidth} customHeight={customHeight}>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width={svgSize}
        height={svgSize}
        viewBox="0 0 22 22"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1098.000000, -3010.000000)">
            <g transform="translate(0.000000, 2880.000000)">
              <g
                id="alert-circle"
                transform="translate(1099.000000, 131.000000)"
              >
                <circle
                  stroke={color ?? "currentColor"}
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  cx="10"
                  cy="10"
                  r="10"
                />
                <path
                  d="M10,4.8 C9.33874705,4.8 8.8,5.32078881 8.8,5.96666667 L8.8,9.83333333 L8.80708293,9.9579815 C8.87857778,10.5560246 9.39087626,11 10,11 C10.661253,11 11.2,10.4792112 11.2,9.83333333 L11.2,5.96666667 L11.1929171,5.8420185 C11.1214222,5.24397536 10.6091237,4.8 10,4.8 Z"
                  fill={color ?? "currentColor"}
                  fillRule="nonzero"
                />
                <path
                  d="M9.15147186,13.1514719 C8.68284271,13.620101 8.68284271,14.379899 9.15147186,14.8485281 C9.62010101,15.3171573 10.379899,15.3171573 10.8485281,14.8485281 C11.3171573,14.379899 11.3171573,13.620101 10.8485281,13.1514719 C10.379899,12.6828427 9.62010101,12.6828427 9.15147186,13.1514719 Z"
                  fill={color ?? "currentColor"}
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default AlertIcon;
