import { createThemedUseStyles } from "theme";

export default createThemedUseStyles((theme) => ({
  dialogTitle: {
    display: "flex",
    flexDirection: "row-reverse",
    justifyContent: "space-between",
  },
  closeIcon: {
    marginLeft: theme.scales.spacing.normal,
  },
  helpText: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: theme.scales.spacing.medium,
    marginTop: theme.scales.spacing.normal,
    marginBottom: theme.scales.spacing.normal,
  },
  errorMessage: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.scales.spacing.medium,
    marginLeft: theme.scales.spacing.medium,
    color: "red",
  },
}));
