export type Feature = {
  name: string;
  enabledByDefault: boolean;
  type: string;
  segment: string;
};

export type FeatureList = {
  features: Feature[];
  translations?: { [key: string]: string } | undefined;
};

export type FeaturesManagementModel = {
  features: FeatureModel[];
  availableSegments: string[];
};

export type FeatureModel = {
  name: string;
  enabled: boolean;
  type: string;
  segment: string;
  status: string;
};

export type Segment = {
  name: string;
  features: string[];
  keys: string[];
};

export type SegmentList = {
  segments: Segment[];
  translations?: { [key: string]: string } | undefined;
};

export enum SupplierTypeKind {
  "Supplier" = 1,
  "OwnedMill" = 2,
  "ContractedMill" = 3
}

export enum NotificationSeverityKind {
  Info = 1,
  Medium = 2,
  High = 3
}

export type Supplier = {
  key: string;
  supplierName: string;
  code: string;
  type: SupplierTypeKind;
  city: string;
  stateProvinceKey: string;
  countryKey: string;
  translations?: { [key: string]: string } | undefined;
  supplierTypeOptions?: SupplierType[] | undefined;
};

export type SuppliersList = {
  suppliers: Supplier[];
  translations?: { [key: string]: string } | undefined;
};

export interface CountryOptionListItem {
  countryName?: string;
  countryKey: string;
  countryCode?: string;
  stateProvinceOptions: StateProvinceOption[];
}

export interface StateProvinceOption {
  stateProvinceKey: string;
  stateProvinceCode?: string;
}

export type StateListItem = {
  key: string;
  name: string;
};

export interface DropdownValue {
  displayValue?: string;
  keyValue?: string;
}

export type NoFeedNotificationPreference = {
  disappearanceRate?: string | undefined;
  hours?: string | undefined;
  severity?: NotificationSeverityKind | undefined;
  severityDisplayName?: string | undefined;
  isInvalid?: boolean | undefined;
  invalidMessage?: string | undefined;
  errorMessages?: string[] | undefined;
};

export type TenantsList = {
  tenants: Tenant[];
  translations?: { [key: string]: string } | undefined;
};

export type Tenant = {
  organizationKey?: string | undefined;
  tenantOrgName?: string | undefined;
  userFirstName?: string | undefined;
  userLastName?: string | undefined;
  userEmail?: string | undefined;
  calculationModels?: CalculationModel[];
  cannotUnassign: boolean | undefined;
};

export type CalculationModel = {
  key?: string | undefined;
  versionName?: string | undefined;
  effectiveDate?: string | undefined;
};

export type TenantDetails = {
  organizationKey?: string | undefined;
  tenantOrgName?: string | undefined;
  locationName?: string | undefined;
  locationType?: string | undefined;
  userFirstName?: string | undefined;
  userLastName?: string | undefined;
  userEmail?: string | undefined;
  currency?: string | undefined;
  measurementSystem?: string | undefined;
  calcModels?: string[] | undefined;
  binSentryOrgId?: string | undefined;
  binTracOrgId?: string | undefined;
  nutrientPackages?: string[] | undefined;
  segments?: string[] | undefined;
  feedLineDisplayType?: string | undefined;
  nutrientBasis?: string | undefined;
  noFeedNotificationPreferences: NoFeedNotificationPreference[];
  canonicalId?: string | undefined;
  displayCanonicalId: boolean;

  // form component related fields
  calcModelOptions?: DropdownValue[] | undefined;
  currencyOptions?: DropdownValue[] | undefined;
  locationTypeOptions?: DropdownObjectType[] | undefined;
  nutrientPackageOptions?: DropdownValue[] | undefined;
  segmentOptions?: DropdownValue[] | undefined;
  measurementSystemOptions?: DropdownObjectType[] | undefined;
  binSentryAccountOptions?: DropdownValue[] | undefined;
  binTracAccountOptions?: DropdownValue[] | undefined;
  feedLineDisplayTypeOptions?: DropdownObjectType[] | undefined;
  nutrientBasisOptions?: DropdownObjectType[] | undefined;

  // validation related fields
  tenantOrgNameValidationMessage?: string | undefined;
  locationNameValidationMessage?: string | undefined;
  locationTypeValidationMessage?: string | undefined;
  userFirstNameValidationMessage?: string | undefined;
  userLastNameValidationMessage?: string | undefined;
  userEmailValidationMessage?: string | undefined;
  nutrientPackagesValidationMessage?: string | undefined;
  segmentsValidationMessage?: string | undefined;
  binSentryOrgIdValidationMessage?: string | undefined;
  binTracOrgIdValidationMessage?: string | undefined;
  currencyValidationMessage?: string | undefined;
  measurementSystemValidationMessage?: string | undefined;
  calcModelsValidationMessage?: string | undefined;
  nutrientBasisValidationMessage?: string | undefined;
  noFeedNotificationPreferencesValidationMessage?: string | undefined;
  feedLineDisplayTypeValidationMessage?: string | undefined;

  isInvalid?: boolean;
  invalidMessage?: string | undefined;
  translations?: { [key: string]: string } | undefined;
};

export interface DropdownObjectType {
  key?: string | undefined;
  displayName?: string | undefined;
}

export type IngredientsList = {
  ingredients: Ingredient[];
  translations?: { [key: string]: string } | undefined;
};

export type Ingredient = {
  key: string;
  name: string;
  code: string;
  rCode: string;
  ingredientTypeDisplayName: string;
  nameValidationMessage?: string;
  rCodeValidationMessage?: string;
  codeValidationMessage?: string;
  isInvalid?: boolean;
  translations?: { [key: string]: string } | undefined;
  ingredientTypeOptions?: IngredientType[] | undefined;
};

export interface NutrientFocus {
  key?: string | undefined;
  displayName?: string | undefined;
}

export type User = {
  key?: string | undefined;
  fullName?: string | undefined;
  firstName?: string | undefined;
  firstNameValidationMessage?: string | undefined;
  lastName?: string | undefined;
  lastNameValidationMessage?: string | undefined;
  currentEmail?: string | undefined;
  currentEmailValidationMessage?: string | undefined;
  updatedEmail?: string | undefined;
  updatedEmailValidationMessage?: string | undefined;
  internalCargillEmailValidationMessage?: string | undefined;
  confirmEmail?: string | undefined;
  confirmEmailValidationMessage?: string | undefined;
  isInvalid?: boolean;
  invalidMessage?: string | undefined;
  validationError?: string | undefined;
  translations?: { [key: string]: string } | undefined;
  isDeactivateVisible?: boolean;
  isReactivateVisible?: boolean;
  selectedUserRoles?: UserRoleKind[] | null | undefined;
  userRolesOptions?: DropdownValue[] | undefined;
  selectedUserRolesValidationMessage?: string | undefined;
};

export type UsersList = {
  users: User[];
  translations?: { [key: string]: string } | undefined;
};

export type CNSVersionDetails = {
  key?: string | undefined;
  name?: string | undefined;
  releaseDate?: string | undefined;
  version?: string | undefined;
  versionValidationMessage?: string;
  nameValidationMessage?: string;
  availableCnsVersions?: string[] | undefined;
  translations?: { [key: string]: string } | undefined;
};

export type CNSVersion = {
  key?: string | undefined;
  name?: string | undefined;
  releaseDate?: string | undefined;
  version?: string | undefined;
  translations?: { [key: string]: string } | undefined;
  locations?: number | undefined;
};

export type CNSVersionList = {
  cnsVersions: CNSVersion[];
  tenantsList: Tenant[];
  dateFormatString?: string | undefined;
  translations?: { [key: string]: string } | undefined;
};

export type Nutrient = {
  key?: string;
  name?: string;
  code?: string;
  ovsName?: string;
  unit?: string;
  unitKey?: string;
  numeratorNutrientKey?: string | null;
  denominatorNutrientKey?: string | null;
  unitOptions?: DropdownValue[] | undefined;
  isInvalid?: boolean;
  nameValidationMessage?: string;
  codeValidationMessage?: string;
  unitValidationMessage?: string;
  nutrientOvsNameValidationMessage?: string;
  numeratorValidationMessage?: string;
  denominatorValidationMessage?: string;
  translations?: { [key: string]: string } | undefined;
  nutrientPackages?: string[];
  defaultNutrientPackageKey?: string;
  nutrientPackageOptions?: DropdownValue[] | undefined;
  nutrientOptions?: DropdownValue[] | undefined;
};

export type NutrientsList = {
  nutrients: Nutrient[];
  translations?: { [key: string]: string } | undefined;
};

export type ReleaseNote = {
  key: string;
  version?: string;
  releaseDate?: string | undefined;
  improvements?: string | undefined;
  fixes?: string | undefined;
  versionValidationMessage?: string;
  dateValidationMessage?: string;
  isInvalid?: boolean;
  dateFormatString?: string | undefined;
  translations?: { [key: string]: string } | undefined;
};

export type ReleaseNotesList = {
  releaseNotes: ReleaseNote[];
  translations?: { [key: string]: string } | undefined;
};

export interface IngredientType {
  key?: IngredientTypeKind;
  displayName?: string | undefined;
}

export interface SupplierType {
  key?: string | undefined;
  displayName?: string | undefined;
}

export enum IngredientTypeKind {
  RawMaterial = 1,
  Forage = 2,
  Medication = 3,
  FinishedProduct = 4
}

export interface UserRole {
  key?: UserRoleKind;
  displayName?: string | undefined;
}

export enum UserRoleKind {
  RestrictedAccess = 1,
  Admin = 2
}

export type SiteDto = {
  translations?: { [key: string]: string } | undefined;
  canViewAllAdminOptions?: boolean | undefined;
  canViewOnlyReleaseNotes?: boolean | undefined;
  error?: SiteError | undefined;
};

export interface SiteError {
  httpStatus?: HttpStatusCode;
  message?: string | undefined;
}

/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  Processing = 102,
  EarlyHints = 103,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultiStatus = 207,
  AlreadyReported = 208,
  IMUsed = 226,
  MultipleChoices = 300,
  Ambiguous = 300,
  MovedPermanently = 301,
  Moved = 301,
  Found = 302,
  Redirect = 302,
  SeeOther = 303,
  RedirectMethod = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  TemporaryRedirect = 307,
  RedirectKeepVerb = 307,
  PermanentRedirect = 308,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  MisdirectedRequest = 421,
  UnprocessableEntity = 422,
  Locked = 423,
  FailedDependency = 424,
  UpgradeRequired = 426,
  PreconditionRequired = 428,
  TooManyRequests = 429,
  RequestHeaderFieldsTooLarge = 431,
  UnavailableForLegalReasons = 451,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
  VariantAlsoNegotiates = 506,
  InsufficientStorage = 507,
  LoopDetected = 508,
  NotExtended = 510,
  NetworkAuthenticationRequired = 511
}
