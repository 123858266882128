import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type FlagIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const FlagIcon: React.FC<FlagIconProps> = props => {
  const { color, decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width="13"
        height="16"
        viewBox="0 0 13 16"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <title>{title}</title>
        <desc>Created with sketchtool.</desc>
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-854.000000, -2766.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="1.41176471"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(854.000000, 97.000000)">
                <path
                  d="M0.705882353,9.17647059 C0.705882353,9.17647059 1.41176471,8.47058824 3.52941176,8.47058824 C5.64705882,8.47058824 7.05882353,9.88235294 9.17647059,9.88235294 C11.2941176,9.88235294 12,9.17647059 12,9.17647059 L12,0.705882353 C12,0.705882353 11.2941176,1.41176471 9.17647059,1.41176471 C7.05882353,1.41176471 5.64705882,0 3.52941176,0 C1.41176471,0 0.705882353,0.705882353 0.705882353,0.705882353 L0.705882353,9.17647059 Z"
                  id="Path"
                />
                <line
                  x1="0.705882353"
                  y1="14.1176471"
                  x2="0.705882353"
                  y2="9.17647059"
                  id="Path"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default FlagIcon;
