import { useMemo } from "react";

/**
 * A hook that returns a unique id for referencing elements on a page
 * @description
 * Returns a unique id for a component on a page.
 * Note: This uses useMemo, which means the value of this id may change
 * at any time.
 */
let currentId = 0;

function useUniqueId(): string {
  return useMemo(() => {
    currentId++;
    return `uid_${currentId}`;
  }, []);
}
export default useUniqueId;
