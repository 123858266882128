import {
  MsalAuthProvider,
  LoginType,
  IMsalAuthProviderConfig
} from "react-aad-msal";
import { Configuration, AuthenticationParameters } from "msal";

const options: IMsalAuthProviderConfig = {
  loginType: LoginType.Redirect
};

let authProvider: MsalAuthProvider | undefined;

export function getAuthProvider(): MsalAuthProvider {
  if (!window.config) {
    throw new Error(
      "Config has not been loaded; Please wait until window.configPromise has been resolved"
    );
  }
  if (authProvider !== undefined) {
    return authProvider;
  }
  const authConfig: Configuration = {
    auth: {
      authority: window.config?.reactAppAuthProviderAuthority,
      clientId: window.config?.reactAppAuthProviderClientId ?? "",
      redirectUri: window.config?.reactAppAuthProviderRedirectUrl
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: false
    }
  };
  const authenticationParameters: AuthenticationParameters = {
    scopes: [window.config?.reactAppAuthProviderScope || ""]
  };
  authProvider = new MsalAuthProvider(
    authConfig,
    authenticationParameters,
    options
  );
  return authProvider;
}
