import isEqual from "react-fast-compare";
import React from "react";
import { useTranslations } from "components/useTranslations";
import {
  DataGridPro,
  GridCellParams,
  GridColDef,
  GridRowModel,
  GridRowsProp,
  GridSortItem
} from "@mui/x-data-grid-pro";
import {
  BorderBox,
  Header2,
  Layout,
  PageLoadStateProvider,
  AppTypography
} from "components/common";
import { TenantsList } from "api/models";
import { Grid } from "@mui/material";
import { resources } from "api/resources";
import { routes } from "routes";
import { useQuery } from "api/useQuery";
import LinkButton from "components/common/LinkButton";
import SecondaryButton from "components/common/SecondaryButton";
import useTenantsPageStyles from "./useTenantsPageStyles";
import { useNavigate } from "react-router-dom";

const TenantsPage: React.FC = () => {
  // retrieve data
  const { data: response, isLoading: ingredientsLoading } =
    useQuery<TenantsList>(resources.tenants);
  const styles = useTenantsPageStyles();
  const tenants = response?.tenants ?? [];
  const t = useTranslations(response?.translations);
  // const [page, setPage] = React.useState(0);

  const navigateTo = useNavigate();

  const renderEditButton = (params: GridCellParams) => {
    return (
      <LinkButton
        label={t`__TENANTS_INITIAL_VIEW_VIEW_BUTTON`}
        onClick={() => navigateTo(`${routes.tenantsEdit}${params.id}`)}
      />
    );
  };

  // setup data grid
  const columns: GridColDef[] = [
    {
      field: "tenantOrgName",
      headerName: t`__TENANTS_INITIAL_VIEW_NAME_COLUMN`,
      flex: 1.5,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__TENANTS_INITIAL_VIEW_NAME_COLUMN`}</strong>
        </AppTypography>
      ),
      renderCell: (params: GridCellParams) => <strong>{params.value as string}</strong>
    },
    {
      field: "userFirstName",
      headerName: t`__TENANTS_INITIAL_VIEW_ADMIN_NAME_COLUMN`,
      flex: 1.5,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__TENANTS_INITIAL_VIEW_ADMIN_NAME_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "userLastName",
      headerName: t`__TENANTS_INITIAL_VIEW_ADMIN_LAST_NAME_COLUMN`,
      flex: 1.5,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__TENANTS_INITIAL_VIEW_ADMIN_LAST_NAME_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "userEmail",
      headerName: t`__TENANTS_INITIAL_VIEW_ADMIN_EMAIL_COLUMN`,
      flex: 1.5,
      renderHeader: () => (
        <AppTypography variant="overline" color="textSecondary">
          <strong>{t`__TENANTS_INITIAL_VIEW_ADMIN_EMAIL_COLUMN`}</strong>
        </AppTypography>
      )
    },
    {
      field: "edit",
      headerName: " ",
      renderCell: (params: GridCellParams) => renderEditButton(params),
      align: "center",
      filterable: false,
      sortable: false,
      editable: false,
      disableColumnMenu: true,
      width: 75,
      display: "flex"
    }
  ];

  const rows: GridRowsProp = tenants.map<GridRowModel>(tenant => ({
    id: tenant.organizationKey,
    tenantOrgName: tenant.tenantOrgName,
    userFirstName: tenant.userFirstName,
    userLastName: tenant.userLastName,
    userEmail: tenant.userEmail
  }));

  // default sorting model
  const [sortModel, setSortModel] = React.useState([
    {
      field: "tenantOrgName",
      sort: "asc"
    }
  ] as GridSortItem[]);

  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <BorderBox width="full" height="full">
        <Layout layoutStyle="page">
          <Grid container>
            <Grid item xs={12}>
              <div className={styles.headerStyling}>
                <Header2>{t`__TENANTS_INITIAL_VIEW_TITLE`}</Header2>
                <SecondaryButton
                  name="createTenant"
                  onClick={() => navigateTo(routes.tenantsCreate)}
                >
                  {t`__TENANTS_INITIAL_VIEW_CREATE_BUTTON`}
                </SecondaryButton>
              </div>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: "calc(100vh - 250px)", width: "100%" }}>
                <div style={{ display: "flex", height: "100%" }}>
                  <div style={{ flexGrow: 1 }}>
                    <DataGridPro
                      rows={ingredientsLoading ? [] : rows}
                      columns={columns}
                      pagination
                      sortingOrder={["asc", "desc"]}
                      sortModel={sortModel}
                      onSortModelChange={model => {
                        if (!isEqual(sortModel, model)) {
                          setSortModel(model);
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Layout>
      </BorderBox>
    </PageLoadStateProvider>
  );
};

export default TenantsPage;
