import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type HelpIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Color of the icon
   */
  color?: string;
};

const HelpIcon: React.FC<HelpIconProps> = props => {
  const { decorative, label, color } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-811.000000, -2764.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(812.000000, 95.000000)">
                <circle cx="9" cy="9" r="9" />
                <path
                  d="M6.381,6.3 C6.81789319,5.05803999 8.08684526,4.31226569 9.38445868,4.53484089 C10.6820721,4.75741609 11.6299614,5.88343763 11.628003,7.2 C11.628003,9 8.928,9.9 8.928,9.9"
                  id="Path"
                />
                <line x1="8.1" y1="13.5" x2="9" y2="13.5" id="Path" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default HelpIcon;
