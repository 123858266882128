import React, { useEffect, useState } from "react";
import { useApi } from "api/useApi";
import { TenantDetails } from "api/models";
import { resources } from "api/resources";
import { methods } from "api/methods";
import {
  Header2,
  Layout,
  PrimaryButton,
  InputLabel,
  PageLoadStateProvider,
  FormHelperText,
  TextField
} from "components/common";
import {
  Grid,
  Paper,
  Snackbar,
  FormControl,
  Select,
  MenuItem,
  Divider,
  Box,
  Tooltip,
  IconButton,
  SnackbarCloseReason
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { TranslationProvider } from "components/useTranslations";
import InfoIcon from "components/common/Icon/InfoIcon";
import { useTheme } from "../../../theme/index";
import useTenantDetailsPageStyles from "../TenantDetailsPage/useTenantDetailsPageStyles";
import TenantDetailsHeader from "../TenantDetailsPage/TenantDetailsHeader";
import { useTenantDetailsReducer } from "../TenantDetailsPage/useTenantDetailsReducer";
import {
  dispatchFormValues,
  isError,
  getValidation
} from "../TenantDetailsPage";
import { useParams } from "react-router-dom";

// 1) Functions used to parse MUI.Select Event Handlers:
// -- returns the selected values of a multiline selection box.
const handleMultipleSelectAppendOnly = (
  event: React.ChangeEvent<HTMLSelectElement>,
  previous?: string[]
) => {
  const { options } = event.target as HTMLSelectElement;
  const selected_values: string[] = previous ? previous.slice() : [];
  for (let i = 0, l = options.length; i < l; i++) {
    if (options[i].selected) {
      if (!previous?.includes(options[i].value)) {
        selected_values.push(options[i].value);
      }
    }
  }
  return selected_values;
};

const TenantEditPage: React.FC = () => {
  // important variables
  const [state, dispatch] = useTenantDetailsReducer();
  const [loaded, setLoaded] = useState(false);
  const execute = useApi();
  const params = useParams();
  const styles = useTenantDetailsPageStyles();
  const { tenantKey } = useParams();

  // on component mount, retrieve values:
  const [preSelectedCalcModels, setPreSelectedCalcModels] = useState([""]);
  const [preSelectedNutrientPackages, setPreSelectedNutrientPackages] =
    useState([""]);

  // State whether form successful
  const [success, setSuccess] = useState(false);

  // useTheme
  const theme = useTheme();

  const handleClose = (event: React.SyntheticEvent<any> | Event, reason?: SnackbarCloseReason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccess(false);
  };

  // HANDLE BUTTON CLICK:
  const handleButtonClick = () => {
    // HTTP REQUEST:
    // Request Payload:
    const http_request_message: any = {
      ...state?.details,
      organizationKey: tenantKey
    };
    setLoaded(false);
    // Updates state with any validation messages
    execute(
      `${resources.tenant(tenantKey!)}`,
      methods.PUT,
      http_request_message
    )
      .then((response: TenantDetails) => {
        if (
          !response.calcModelsValidationMessage &&
          !response.binSentryOrgIdValidationMessage
        ) {
          dispatch({
            type: "TENANT_DETAILS_LOADED",
            payload: response
          });
          if (response.calcModels) {
            setPreSelectedCalcModels(response.calcModels);
          }
          if (response.nutrientPackages) {
            setPreSelectedNutrientPackages(response.nutrientPackages);
          }
          setSuccess(true);
          setLoaded(true);
          return;
        }
        setSuccess(false);
        dispatch({
          type: "TENANT_DETAILS_EDITED",
          payload: {
            ...state?.details,
            nutrientPackagesValidationMessage:
              response.nutrientPackagesValidationMessage,
            binSentryOrgIdValidationMessage:
              response.binSentryOrgIdValidationMessage,
            calcModelsValidationMessage: response.calcModelsValidationMessage
          }
        });
        setLoaded(true);
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  };

  // functionality when edit page loads
  useEffect(() => {
    setLoaded(false);
    execute(resources.tenant(params.tenantKey!), methods.GET)
      .then((response: TenantDetails) => {
        if (!response.isInvalid) {
          dispatch({
            type: "TENANT_DETAILS_LOADED",
            payload: response
          });
          // null checks
          if (response.calcModels) {
            setPreSelectedCalcModels(response.calcModels);
          }
          if (response.nutrientPackages) {
            setPreSelectedNutrientPackages(response.nutrientPackages);
          }
          setLoaded(true);
        }
      })
      .catch((err: any) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageLoadStateProvider isLoaded={!!state?.details?.translations}>
      <TranslationProvider translations={state?.details?.translations}>
        {t => (
          <div>
            <TenantDetailsHeader
              tenantKey={tenantKey}
              tenantName={state?.details?.tenantOrgName}
              creatingNew={false}
            />

            <Box pt={2} className={styles.masterContainerEdit}>
              <Paper className={styles.pageContent}>
                <Layout
                  padding={{
                    top: 16,
                    bottom: 16,
                    left: 16,
                    right: 16
                  }}
                  layoutStyle="form-uniform"
                >
                  <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={success}
                    autoHideDuration={6000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="success" elevation={6} variant="filled">
                      {t`__TENANT_DETAILS_TENANT_SAVED`}
                    </Alert>
                  </Snackbar>

                  <form>
                    <Box>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        p={1}
                        alignContent="center"
                      >
                        <Header2>{t`__TENANT_DETAILS_TITLE_DETAILS`}</Header2>
                        <PrimaryButton
                          name="saveButton"
                          onClick={handleButtonClick}
                          disabled={!state?.isFormDirty || !loaded}
                        >
                          {t`__TENANT_DETAILS_SAVE_BUTTON`}
                        </PrimaryButton>
                      </Box>
                      <div>
                        <Divider className={styles.divider}/>
                      </div>
                      <Grid container justifyContent="flex-start">
                        <Grid item xs={12} md={12} lg={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_DEFAULT_BINTRAC_ACCOUNT`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_DEFAULT_BINTRAC_ACCOUNT`}
                              name="binTracOrgId"
                              value={state?.details?.binTracOrgId ?? ""}
                              error={isError(
                                state,
                                "binTracOrgIdValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "binTracOrgId",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            >
                              {(state?.details.binTracAccountOptions ?? []).map(
                                binTracAccount => (
                                  <MenuItem
                                    key={binTracAccount.keyValue}
                                    value={binTracAccount.keyValue}
                                  >
                                    {binTracAccount.displayValue}
                                  </MenuItem>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "binTracOrgIdValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "binTracOrgIdValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl
                            size="small"
                            variant="outlined"
                            fullWidth
                          >
                            <InputLabel>{t`__TENANT_DETAILS_DEFAULT_BINSENTRY_ACCOUNT`}</InputLabel>
                            <Select
                              label={t`__TENANT_DETAILS_DEFAULT_BINSENTRY_ACCOUNT`}
                              name="binSentryOrgId"
                              value={state?.details?.binSentryOrgId ?? ""}
                              error={isError(
                                state,
                                "binSentryOrgIdValidationMessage"
                              )}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "binSentryOrgId",
                                  event,
                                  dispatch
                                );
                              }}
                            >
                              {(
                                state?.details?.binSentryAccountOptions ?? []
                              ).map(binSentryAccount => (
                                <MenuItem
                                  key={binSentryAccount.keyValue}
                                  value={binSentryAccount.keyValue}
                                >
                                  {binSentryAccount.displayValue}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "binSentryOrgIdValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "binSentryOrgIdValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              shrink
                              htmlFor="nutrientPackages"
                              className={styles.labelMultipleSelect}
                            >
                              &nbsp;&nbsp;
                              {t`__TENANT_DETAILS_NUTRIENT_PACKAGES`}
                              <Tooltip
                                title={t`__TENANT_DETAILS_SELECTOR_CTRL_HINT`}
                              >
                                <IconButton
                                  className={styles.multiSelectInfoTool}
                                >
                                  <InfoIcon
                                    iconSize={12}
                                    color={theme.palette.primary.fsBlue}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>
                            <Select<string[]>
                              multiple
                              native
                              label="nutrientPackages"
                              variant="outlined"
                              value={state?.details?.nutrientPackages ?? ""}
                              error={isError(
                                state,
                                "nutrientPackagesValidationMessage"
                              )}
                              onChange={event => {
                                dispatch({
                                  type: "TENANT_DETAILS_EDITED",
                                  payload: {
                                    ...state.details,
                                    nutrientPackages:
                                    // @ts-ignore Typings are not considering `native`
                                      handleMultipleSelectAppendOnly(event, preSelectedNutrientPackages)
                                  }
                                });
                              }}
                            >
                              {(
                                state?.details?.nutrientPackageOptions ?? []
                              ).map(pkg => (
                                <option key={pkg.keyValue} value={pkg.keyValue}>
                                  {pkg.displayValue}
                                </option>
                              ))}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "nutrientPackagesValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "nutrientPackagesValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        <Grid item xs={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              shrink
                              className={styles.labelMultipleSelect}
                            >
                              &nbsp;&nbsp;
                              {t`__TENANT_DETAILS_CALCULATION_MODELS`}
                              <Tooltip
                                title={t`__TENANT_DETAILS_SELECTOR_CTRL_HINT`}
                              >
                                <IconButton
                                  className={styles.multiSelectInfoTool}
                                >
                                  <InfoIcon
                                    iconSize={12}
                                    color={theme.palette.primary.fsBlue}
                                  />
                                </IconButton>
                              </Tooltip>
                            </InputLabel>
                            <Select<string[]>
                              multiple
                              native
                              variant="outlined"
                              name="calcModels"
                              value={state?.details?.calcModels ?? ""}
                              error={isError(
                                state,
                                "calcModelsValidationMessage"
                              )}
                              onChange={event => {
                                dispatch({
                                  type: "TENANT_DETAILS_EDITED",
                                  payload: {
                                    ...state.details,
                                    // @ts-ignore Typings are not considering `native`
                                    calcModels: handleMultipleSelectAppendOnly(event, preSelectedCalcModels)
                                  }
                                });
                              }}
                            >
                              {(state?.details?.calcModelOptions ?? []).map(
                                model => (
                                  <option
                                    key={model.keyValue}
                                    value={model.keyValue}
                                  >
                                    {model.displayValue}
                                  </option>
                                )
                              )}
                            </Select>
                            <FormHelperText
                              error={isError(
                                state,
                                "calcModelsValidationMessage"
                              )}
                            >
                              {getValidation(
                                state,
                                "calcModelsValidationMessage"
                              )}
                            </FormHelperText>
                          </FormControl>
                        </Grid>

                        {state?.details?.displayCanonicalId && (
                          <Grid item xs={12} md={12} lg={6}>
                            <TextField
                              variant="outlined"
                              size="small"
                              fullWidth
                              name="canonicalId"
                              label={t`__TENANT_DETAILS_CANONICAL_ID`}
                              value={state?.details?.canonicalId ?? ""}
                              onChange={event => {
                                dispatchFormValues(
                                  state,
                                  "canonicalId",
                                  event,
                                  dispatch
                                );
                              }}
                              disabled={!state.isLoaded}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  </form>
                </Layout>
              </Paper>
            </Box>
          </div>
        )}
      </TranslationProvider>
    </PageLoadStateProvider>
  );
};

export default TenantEditPage;
