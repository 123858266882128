import { FeatureModel, FeaturesManagementModel as FeaturesManagementDto } from "api/models";
import { statuses } from "components/common/statuses";

export default function featureManagementReducer(
  featureManagement: FeaturesManagementDto,
  action: {
    type: string;
    pageState?: FeaturesManagementDto;
    name?: string | undefined;
    newName?: string;
    featureType?: string;
    segmentName?: string | undefined;
    newValues?: FeatureModel[];
  }
) {
  switch (action.type) {
    case "add": {
      const newFeature = {
        name: "",
        enabled: false,
        type: "DEV_GATE",
        status: statuses.ADDED,
        segment: "",
      };
      return {
        ...featureManagement,
        features: [...featureManagement.features, newFeature],
      };
    }
    case "undo": {
      return {
        ...featureManagement,
        features: featureManagement.features.filter(
          (f) => f.status !== statuses.ADDED.toString()
        ),
      };
    }
    case "saveAll": {
      const { name } = action;
      return {
        ...featureManagement,
        features: featureManagement.features.map((f) => {
          return f.name === name ? { ...f, status: statuses.INITIAL } : f;
        }),
      };
    }
    case "toggleEnabledByDefault": {
      const { name } = action;
      return {
        ...featureManagement,
        features: featureManagement.features.map((f) => {
          if (f.name === name) {
            return {
              ...f,
              status:
                f.status === statuses.INITIAL.toString() ? statuses.MODIFIED : f.status,
              enabled: !f.enabled,
            };
          }
          return f;
        }),
      };
    }
    case "setWhitelist": {
      const { name, segmentName } = action;
      return {
        ...featureManagement,
        features: featureManagement.features.map((f) => {
          if (f.name === name && segmentName !== undefined) {
            return {
              ...f,
              status:
                f.status === statuses.INITIAL.toString() ? statuses.MODIFIED : f.status,
              segment: segmentName,
            };
          }
          return f;
        }),
      };
    }
    case "setFeatureType": {
      const { name, featureType } = action;
      return {
        ...featureManagement,
        features: featureManagement.features.map((f) => {
          if (f.name === name && featureType) {
            return {
              ...f,
              status:
                f.status === statuses.INITIAL.toString() ? statuses.MODIFIED : f.status,
              type: featureType,
            };
          }
          return f;
        }),
      };
    }
    case "updateName": {
      const { name: currentName, newName } = action;
      return {
        ...featureManagement,
        features: featureManagement.features.map((f) =>
          f.name === currentName && f.status === statuses.ADDED.toString()
            ? { ...f, name: newName || "" }
            : f
        ),
      };
    }
    case "init": {
      const { pageState } = action;
      if (pageState) {
        return pageState;
      }
      throw new Error("PageState must be specified on Init");
    }
    default:
      throw new Error(`Unknown action ${action.type}`);
  }
}
