import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../../IconBox";

export type BinFillLowIconProps = {
  className?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
};

const BinFillLowIcon: React.FC<BinFillLowIconProps> = props => {
  const { decorative, label } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;

  return (
    <IconBox justifyContent="flex-start">
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        className={props.className}
        width="20px"
        height="30px"
        viewBox="0 0 20 30"
        version="1.1"
      >
        {title}
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g transform="translate(-1055.000000, -3078.000000)">
            <g transform="translate(0.000000, 2880.000000)">
              <g transform="translate(1065.000000, 213.000000) rotate(-360.000000) translate(-1065.000000, -213.000000) translate(1056.000000, 199.000000)">
                <rect
                  stroke="#B7C2C6"
                  fillOpacity="0"
                  fill="#F6F7FA"
                  x="0"
                  y="-1.13686838e-13"
                  width="18"
                  height="28"
                  rx="2"
                />
                <path
                  d="M3,19 L15,19 L15,24 C15,24.5522847 14.5522847,25 14,25 L4,25 C3.44771525,25 3,24.5522847 3,24 L3,19 L3,19 Z"
                  id="Rectangle-Copy-10"
                  fill="#B53D3D"
                />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default BinFillLowIcon;
