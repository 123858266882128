import { Grid } from "@mui/material";
import { SiteDto } from "api/models";
import { resources } from "api/resources";
import { useQuery } from "api/useQuery";
import {
  BorderBox,
  Header2,
  Layout,
  PageLoadStateProvider
} from "components/common";
import { useTranslations } from "components/useTranslations";
import React from "react";

const HomePage: React.FC = () => {
  const { data: response } = useQuery<SiteDto>(resources.site);
  const t = useTranslations(response?.translations);
  return (
    <PageLoadStateProvider isLoaded={!!response?.translations}>
      <BorderBox width="full" height="full">
        <Layout layoutStyle="page">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header2>{t`__HOME_TITLE`}</Header2>
            </Grid>
          </Grid>
        </Layout>
      </BorderBox>
    </PageLoadStateProvider>
  );
};

export default HomePage;
