import React from "react";
import useUniqueId from "components/useUniqueId";
import IconBox from "../IconBox";

export type ExternalLinkIconProps = {
  color?: string;
  /**
   * True if the icon is purely decorative (doesn't add information about content on the page)
   */
  decorative?: boolean;
  /**
   * Label for the icon. Only set if there is no existing element that labels it
   */
  label?: string;
  /**
   * Id of the element that labels this icon
   */
  labelledById?: string;
  /**
   * Width and height of the icon in pixels
   */
  iconSize?: number;
};

const ExternalLinkIcon: React.FC<ExternalLinkIconProps> = props => {
  const { color, decorative, label, iconSize } = props;
  const labelledBy = useUniqueId();
  const title = label && <title id={labelledBy}>{label}</title>;
  const svgSize = iconSize ?? 24;

  return (
    <IconBox>
      <svg
        aria-hidden={decorative}
        aria-labelledby={labelledBy}
        width={svgSize}
        height={svgSize}
        role="img"
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        {title}
        <g
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <g
            transform="translate(-433.000000, -2764.000000)"
            stroke={color ?? "currentColor"}
            strokeWidth="2"
          >
            <g transform="translate(0.000000, 2670.000000)">
              <g transform="translate(434.000000, 95.000000)">
                <g>
                  <path
                    d="M15,10 L15,16 C15,17.1045695 14.1045695,18 13,18 L2,18 C0.8954305,18 0,17.1045695 0,16 L0,5 C0,3.8954305 0.8954305,3 2,3 L8,3"
                    id="Path"
                  />
                  <polyline id="Path" points="12 0 18 0 18 6" />
                  <line x1="7" y1="11" x2="18" y2="0" id="Path" />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </IconBox>
  );
};

export default ExternalLinkIcon;
