import React, { useEffect, useState } from "react";

type PageLoadState = {
  loaded?: boolean;
  name?: string;
  setLoaded: (value?: boolean) => any;
};

export const PageLoadStateContext =
  React.createContext<PageLoadState | null>(null);

const PageLoadStateProvider: React.FC<{
  name?: string;
  isLoaded?: boolean;
  children: React.ReactNode;
}> = props => {
  const { name = "pageContent", isLoaded = false } = props;

  const [pageLoadState, setPageLoadState] = useState({
    name,
    loaded: false
  });

  useEffect(() => {
    setLoaded(isLoaded);
  }, [isLoaded]);

  function setLoaded(value = true) {
    setPageLoadState(previous => {
      return { ...previous, loaded: value };
    });
  }

  const context = {
    loaded: pageLoadState.loaded,
    name,
    setLoaded
  } as PageLoadState;

  return (
    <PageLoadStateContext.Provider value={context}>
      {props.children}
    </PageLoadStateContext.Provider>
  );
};

export default PageLoadStateProvider;
