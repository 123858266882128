import React from "react";
import { useTranslations } from "components/useTranslations";
import { AuthError } from "msal";
import { Layout, BorderBox, PrimaryButton } from "components/common";
import CargillNutritionCloudLogo from "components/common/CargillNutritionCloudLogo";
import { FormLabel } from "@mui/material";
import useLoginPageStyles from "./useLoginPageStyles";

export type LoginPageProps = {
  isAuthenticating: boolean;
  error: AuthError | null;
  login: () => void;
};

const LoginPage: React.FC<LoginPageProps> = props => {
  const { isAuthenticating, error, login } = props;
  const styles = useLoginPageStyles();
  const t = useTranslations();
  return (
    <Layout layoutStyle="page" height="full" backgroundColor="darkBlue">
      <div className={styles.page}>
        <div className={styles.loginCard}>
          <BorderBox width={425} height="content" direction="row">
            <div className={styles.loginCardContainer}>
              <div className={styles.logoContainer}>
                <CargillNutritionCloudLogo labelledById="logo_main" />
              </div>
              <div className={styles.loginCardRow}>
                {!isAuthenticating && (
                  <PrimaryButton
                    name="login_page_login_button"
                    onClick={login}
                    title={t`__LOGIN`}
                  >
                    {t`__LOGIN`}
                  </PrimaryButton>
                )}
              </div>
              <div className={styles.loginCardRow} />
              {error && error.errorCode !== "user_login_error" && (
                <FormLabel error>{t`__ERROR`}</FormLabel>
              )}
            </div>
          </BorderBox>
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerItem} />
          <div className={styles.footerItem} />
        </div>
      </div>
    </Layout>
  );
};

export default LoginPage;
