import { createThemedUseStyles } from "theme";

export default createThemedUseStyles(() => ({
    button: {
        margin: "16px"
    },
    addButton: {
        margin: "8px"
    }
}));
