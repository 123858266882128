import {
  LayoutProps,
  PaddingValue,
  LayoutBackGroundColor,
  LayoutHeights
} from "./LayoutTypes";
import { createThemedUseStylesWithParams } from "../../../theme";

export default createThemedUseStylesWithParams<LayoutProps>((params, theme) => {
  const getPadding = (
    value: PaddingValue | undefined,
    debug: boolean | string | number | undefined
  ) => {
    if (debug) return 0;
    switch (value) {
      case "small":
        return theme.scales.spacing.small;
      case "medium":
        return theme.scales.spacing.medium;
      case "large":
        return theme.scales.spacing.large;
      case undefined:
        return 0;
      default:
        return value;
    }
  };

  const getBorder = (
    value: PaddingValue | undefined,
    debug: boolean | string | number | undefined
  ) => {
    if (debug === undefined) return 0;
    switch (value) {
      case "small":
        return theme.scales.spacing.small;
      case "medium":
        return theme.scales.spacing.medium;
      case "large":
        return theme.scales.spacing.large;
      case undefined:
      case null:
        return "0px";
      default:
        return value ?? "0px";
    }
  };

  const getBackgroundColor = (value: LayoutBackGroundColor | undefined) => {
    switch (value) {
      case "gray":
        return theme.palette.grays.light;
      case "darkBlue":
        return theme.palette.primary.fsDarkBlue;
      case "darkBlueSub":
        return theme.palette.primary.fsDarkBlueSub;
      case "lightBlue":
        return theme.palette.primary.fsBlueSecondary;
      case "white":
        return theme.palette.white;
      default:
        return "none";
    }
  };

  const getHeight = (value: LayoutHeights) => {
    if (typeof value === "number") return value;
    switch (value) {
      case "content":
        return "fit-content";
      case "full":
        return "100%";
      default:
        return "fit-content";
    }
  };

  const getBorderColor = (value?: boolean | string | number) => {
    if (value === true) {
      return "tomato";
    }
    if (typeof value === "string") {
      return value;
    }
    if (typeof value === "number") {
      return value % 2 === 1 ? "tomato" : "orange";
    }
    return "";
  };

  return {
    layout: {
      boxSizing: "border-box",
      display: "flex",
      minWidth:  params.minWidth ?? "fit-content",
      height:  getHeight(params.height),
      minHeight:  params.minHeight,
      width: "100%",
      flexDirection: params.layoutOrientation === "horizontal" ? "row" : "column",
      flex: 1,
      overflowX:  params.overflowX ?? "visible",
      paddingTop: getPadding(params.padding?.top, params.debug),
      paddingLeft: getPadding(params.padding?.left, params.debug),
      paddingBottom: getPadding(params.padding?.bottom, params.debug),
      paddingRight: getPadding(params.padding?.right, params.debug),
      borderTopStyle: params.debug && params.padding?.top ? "solid" : "none",
      borderLeftStyle: params.debug && params.padding?.left ? "solid" : "none",
      borderBottomStyle: params.debug && params.padding?.left ? "solid" : "none",
      borderRightStyle: params.debug && params.padding?.right ? "solid" : "none",
      borderColor: getBorderColor(params?.debug),
      borderTopWidth: getBorder(params.padding?.top, params.debug),
      borderLeftWidth: getBorder(params.padding?.left, params.debug),
      borderBottomWidth: getBorder(params.padding?.bottom, params.debug),
      borderRightWidth: getBorder(params.padding?.right, params.debug),
      background: getBackgroundColor(params.backgroundColor)
    }
  };
});
