import React, { useContext } from 'react';
import { Typography as MuiTypography, TypographyProps } from '@mui/material';
import { PageLoadStateContext } from '../trackPageLoadState';

export const AppTypography: React.FunctionComponent<TypographyProps> = (props) => {
    const pageLoadState = useContext(PageLoadStateContext) ?? { loaded: true };

    return (

        <MuiTypography {...props}>
            {pageLoadState.loaded && props.children}
        </MuiTypography>
    );
}